import React from "react";
import { Badge, Card, Col, ListGroup, Row } from "react-bootstrap";
import { SiteCreateOrUpdateDTO } from "src/services/site/site.model";

export const SiteSummary = ({ site }: { site: SiteCreateOrUpdateDTO }) => {
  return (
    <Card className="mb-3" style={{ backgroundColor: "#edf1f5" }}>
      {site.featuredMedia && (
        <>
          {site.featuredMedia.url && (
            <Card.Img variant="top" src={site.featuredMedia.url} />
          )}
        </>
      )}
      <Card.Body>
        {!site.id && <Card.Title>Summary</Card.Title>}
        <Row>
          <Col>
            <h4 className="mb-0">{site.name}</h4>
            <p className="mb-3 text-muted">{site.summary}</p>
          </Col>
          {site.id && (
            <Col>
              <Badge bg={site.isActive ? "success" : "secondary"}>
                {site.isActive ? "Active" : "Disabled"}
              </Badge>
            </Col>
          )}
        </Row>
        <p>{site.description}</p>
      </Card.Body>

      {(site.addressLine0 || site.addressLine1) && (
        <ListGroup className="list-group-flush">
          {site.addressLine0 && (
            <ListGroup.Item style={{ backgroundColor: "#edf1f5" }}>
              {site.addressLine0}
            </ListGroup.Item>
          )}
          {site.addressLine1 && (
            <ListGroup.Item style={{ backgroundColor: "#edf1f5" }}>
              {site.addressLine1}
            </ListGroup.Item>
          )}
        </ListGroup>
      )}
    </Card>
  );
};
