import { BaseService } from "src/services/baseService";
import {
  BookingModel,
  PaginationBookingResponse,
} from "src/services/booking/booking.model";

import {
  decodeSearch,
  SearchableService,
  SearchProps,
} from "../searchableService";

export class BookingService
  extends BaseService<BookingModel>
  implements SearchableService<BookingModel>
{
  constructor() {
    super(BookingModel, "/booking");
  }

  async findAll() {
    return this._getArray();
  }

  async findById(id: string) {
    return this._getParsed(
      `/${id}?relations=order[account],createdByAccount,hub[site]`
    );
  }

  async search(
    search: SearchProps,
    hubId?: number
  ): Promise<PaginationBookingResponse | undefined> {
    if (hubId) {
      search.filter = [search.filter?.split(","), `hubId=${hubId}`].join(",");
    }

    const params = decodeSearch(search);

    const response = await this._getParsed(
      `/search?${params}`,
      {},
      PaginationBookingResponse
    );

    return response.data;
  }

  async getLockCode(id: number) {
    return (await this._getParsed(`/${id}/lockCode`)).data;
  }

  async generateLockCode(id: number) {
    return (await this._getParsed(`/${id}/lockCode?generate=true`)).data;
  }

  async removeLockCode(id: number) {
    await this._deleteUnparsed(`/${id}/lockCode`);
  }

  searchFilters(): { [p: string]: string } | undefined {
    return {
      past: "Past bookings",
      current: "Current bookings",
      future: "Future bookings",
    };
  }
}

const bookingService = new BookingService();

export default bookingService;
