import { Formik } from "formik";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { FormFieldControl } from "src/components/formFieldControl";
import Page from "src/components/page";
import * as Yup from "yup";
import tryAsyncToast from "../../hooks/tryAsyncToast";
import useAsyncRetry from "../../hooks/useAsyncRetry";
import { HubModel } from "../../services/hub/hub.model";
import hubService from "../../services/hub/hub.service";
import siteService from "../../services/site/site.service";
import { HubImages } from "./hubImages";
import { HubLocation } from "./hubLocation";
import { withAuthAndAuthRequired } from "../../hooks/withAuthorisationRequired";

const HubFormSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  siteId: Yup.number().required("A location is required"),
  latitude: Yup.number(),
  longitude: Yup.number(),
  overview: Yup.string().required("An overview is required"),
  description: Yup.string().required("A description is required"),
  //media: Yup.object(),
  //featuredMedia: Yup.object(),
});

const initialHubData: Omit<HubModel, "id"> = {
  name: "",
  description: "",
  hubAvailabilities: [],
  overview: "",
  longitude: 0,
  latitude: 0,
  isActive: false,
  media: [],
};

const OnboardHubPage = () => {
  const navigate = useNavigate();

  const { value: allSites } = useAsyncRetry(
    async () => await siteService.findAll(),
    []
  );

  const createHub = React.useCallback(
    async (hub: Omit<HubModel, "id">, { setSubmitting }) => {
      setSubmitting(true);
      const newHub = await tryAsyncToast(hubService.createFull(hub), {
        success: "Hub created",
      });

      setSubmitting(false);
      navigate(`/hub/${newHub.id}`);
    },
    [navigate]
  );

  return (
    <Page title={`Create new hub`}>
      <>
        <Helmet title="Create hub" />
        <Formik<Omit<HubModel, "id">>
          initialValues={initialHubData}
          onSubmit={createHub}
          validationSchema={HubFormSchema}
        >
          {({
            values: hub,
            setFieldValue,
            submitForm,
            handleSubmit,
            isSubmitting,
            isValid,
          }) => (
            <form onSubmit={handleSubmit}>
              <Row className="my-5">
                <Col>
                  <FormFieldControl
                    showLabel
                    fieldName="name"
                    label="Name"
                    placeholder="Name of Hub"
                    className="mb-4"
                  />

                  <FormFieldControl
                    fieldName="siteId"
                    label="Site location"
                    showLabel
                    type="select"
                    className="mb-4"
                    onChange={(valueStr) => {
                      const value = parseInt(valueStr, 10);

                      const foundSite = allSites?.find(
                        ({ id }) => id === value
                      );

                      setFieldValue("site", foundSite);

                      if (foundSite) {
                        setFieldValue("latitude", foundSite.latitude);
                        setFieldValue("longitude", foundSite.longitude);
                      }
                    }}
                  >
                    <option value="">Select a location</option>
                    {allSites?.map((site) => (
                      <option key={site.id} value={site.id}>
                        {site.name}
                      </option>
                    ))}
                  </FormFieldControl>

                  <FormFieldControl
                    showLabel
                    label="Overview"
                    fieldName="overview"
                    placeholder="Short, quick, overview of Hub"
                    as="textarea"
                    className="mb-4"
                  />

                  <FormFieldControl
                    showLabel
                    label="Description"
                    fieldName="description"
                    placeholder="Detailed description for Hub"
                    as="textarea"
                    className="mb-4"
                  />

                  <FormFieldControl
                    showLabel
                    label="Amenities"
                    fieldName="amenities"
                    placeholder="List of amentities available at this hub"
                    as="textarea"
                    className="mb-4"
                  />

                  <HubImages
                    hub={hub}
                    onSave={async (media) => {
                      const newValues = {
                        ...hub,
                        ...media,
                      };

                      setFieldValue("media", newValues.media);
                      setFieldValue("featuredMedia", newValues.featuredMedia);
                    }}
                  />
                </Col>
                <Col>
                  <HubLocation
                    hub={hub}
                    onSubmit={async ({ latitude, longitude }) => {
                      setFieldValue("latitude", latitude);
                      setFieldValue("longitude", longitude);
                    }}
                  />

                  <FormFieldControl
                    showLabel
                    label="Location directions"
                    fieldName="locationDirections"
                    placeholder="Details on how to locate hub"
                    as="textarea"
                    className="mb-4"
                  />
                </Col>
              </Row>
              <hr />
              <div className="d-flex justify-content-start">
                <Button
                  variant="success"
                  size="lg"
                  disabled={isSubmitting || !isValid}
                  onClick={() => submitForm()}
                >
                  Create hub
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </>
    </Page>
  );
};

export default withAuthAndAuthRequired(OnboardHubPage);
