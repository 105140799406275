import { Formik } from "formik";
import { parseInt } from "lodash";
import React from "react";
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Card,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import { FormFieldControl } from "src/components/formFieldControl";
import useAsyncRetry from "src/hooks/useAsyncRetry";
import siteService from "src/services/site/site.service";
import * as Yup from "yup";

export interface InitialHubFormValues {
  id?: number;
  siteId?: number;
  overview: string;
  description: string;
  isActive: boolean;
  latitude: number;
  longitude: number;
  name: string;
}

export interface HubFormValues extends InitialHubFormValues {
  siteId: number;
}

const hubFormValidationSchema = Yup.object().shape({
  name: Yup.string().min(2).required("Name is required"),
  siteId: Yup.number().typeError("Select a location for the hub"),
  overview: Yup.string(),
  description: Yup.string(),
  addressLine0: Yup.string(),
  addressLine1: Yup.string(),
  latitude: Yup.number(),
  longitude: Yup.number(),
});

const HubForm = ({
  hub,
  onSubmit,
}: {
  hub: InitialHubFormValues;
  onSubmit: (values: HubFormValues) => Promise<void>;
}) => {
  const { value: sites } = useAsyncRetry(() => {
    return siteService.findAll();
  }, []);

  const {
    id,
    siteId,
    overview,
    description,
    isActive,
    latitude,
    longitude,
    name,
  } = hub;

  return (
    <Formik<InitialHubFormValues>
      validationSchema={hubFormValidationSchema}
      initialValues={{
        id,
        siteId,
        overview,
        description,
        isActive,
        latitude,
        longitude,
        name,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        values.siteId = parseInt(`${values.siteId}`, 10);
        await onSubmit(values as HubFormValues);
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
        >
          <Container fluid className="p-0">
            <Row>
              <Col>
                <Card className="h-100">
                  <Card.Body>
                    <Card.Title>Details</Card.Title>

                    {hub.id && (
                      <Form.Group as={Row}>
                        <Form.Label column sm={2}>
                          Id
                        </Form.Label>
                        <Col sm={10}>{hub.id}</Col>
                      </Form.Group>
                    )}

                    <FormFieldControl
                      fieldName="name"
                      label="Hub name"
                      placeholder="Name of hub"
                      className="mt-3"
                    />

                    <FormFieldControl
                      fieldName="overview"
                      label="Overview"
                      placeholder="Simple overview of hub"
                      as="textarea"
                      className="mt-3"
                    />

                    <FormFieldControl
                      fieldName="description"
                      label="Description"
                      placeholder="Description of hub"
                      as="textarea"
                      className="mt-3"
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card className="h-100">
                  <Card.Body>
                    <Card.Title>Location information</Card.Title>

                    <FormFieldControl type="select" fieldName="siteId">
                      <option>Choose a site</option>
                      {sites &&
                        sites.map((site) => (
                          <option key={site.id} value={site.id}>
                            {site.name}
                          </option>
                        ))}
                    </FormFieldControl>

                    <FormFieldControl
                      fieldName="addressLine0"
                      label="Address line 1"
                      placeholder="First Line"
                      className="my-3"
                    />
                    <FormFieldControl
                      fieldName="addressLine1"
                      placeholder="Second Line"
                      label="Address line 2"
                      className="mt-3"
                    />

                    <FormFieldControl
                      fieldName="latitude"
                      label="Latitude"
                      placeholder="Latitude"
                      className="mt-3"
                    />

                    <FormFieldControl
                      fieldName="longitude"
                      label="Longitude"
                      placeholder="Longitude"
                      className="mt-3"
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col>
                <ButtonToolbar>
                  <ButtonGroup>
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
              </Col>
            </Row>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

export default HubForm;
