import React from "react";

const MoneyFormats = {
  default: Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }),
};

const MoneyFormat = ({
  value,
  format = MoneyFormats["default"],
}: {
  value: number;
  format?: Intl.NumberFormat;
}) => {
  return <>{format.format(value)}</>;
};

export default MoneyFormat;
