import { AccountDto } from "../account/account.dto";
import { BookingDto } from "../booking/booking.dto";
import { VoucherDto } from "../voucher/voucher.dto";
import { DiscountUsage } from "../discount/discountUsage.model";

export enum PaymentTypes {
  INTENT = "payment_intent",
  CHECKOUT = "checkout.session",
}

export interface OrderDto {
  id: number;

  accountId: number;

  account: AccountDto;

  invoiceId: string;

  bookings: BookingDto[];

  totalListCost: number;
  totalCost: number;

  paymentId: string;

  paymentType: string;

  voucher: VoucherDto;

  voucherId: number;

  paymentDate: Date;

  status: number;

  refundDate: Date;

  createdAt: Date;

  confirmationSentAt?: Date;

  discountUsage?: DiscountUsage;
}
