import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CustomerIndexPage from "src/pages/customer";
import HomePage from "src/pages/home/home.page";
import HubAvailabilityPage from "src/pages/hubAvailability";
import HubIndexPage from "src/pages/hubs/hub.index";
import EditHubAvailabilityPage from "src/pages/hubs/availability";
import ViewHubPage from "src/pages/hubs/hub.view";
import PricingPlansPage from "src/pages/pricingPlans";
import CreatePricingPlanPage from "src/pages/pricingPlans/create";
import EditPricingPlanPage from "src/pages/pricingPlans/edit";
import SiteIndexPage from "src/pages/site/site.index";
import CreateSitePage from "src/pages/site/site.create";
import EditSitePage from "src/pages/site/edit";
import ViewSitePage from "src/pages/site/site.view";
import CreateAdminPage from "./pages/account/createAdmin";
import AccountIndexPage from "./pages/account/index";
import AccountPage from "./pages/account/view";

import CustomerPage from "./pages/customer/view";
import HubAllocationsPage from "./pages/hubAllocations";
import EditHubPage from "./pages/hubs/edit";
import OnboardHubPage from "./pages/hubs/onboard";
import OrdersPage from "./pages/orders";
import ViewOrderPage from "./pages/orders/view";
import ProfilePage from "./pages/profile/profile.page";
import CheckoutPage from "./pages/checkout";
import ViewBookingsPage from "./pages/bookings/index";
import ViewBookingPage from "./pages/bookings/view";
import { Helmet, HelmetProvider } from "react-helmet-async";
import DiscountIndex from "./pages/discount/discount.index";
import DiscountCreate from "./pages/discount/discount.create";
import DiscountEdit from "./pages/discount/discount.edit";
import MembershipIndex from "./pages/membership/membership.index";
import MembershipCreate from "./pages/membership/membership.create";
import MembershipEditPage from "src/pages/membership/membership.edit";
import MembershipAccountIndex from "./pages/membershipAccount/membershipAccount.index";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <HelmetProvider>
        <Helmet titleTemplate="%s | WorkFromHub" />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/account" element={<AccountIndexPage />} />
          <Route path="/account/createAdmin" element={<CreateAdminPage />} />
          <Route path="/account/:id" element={<AccountPage />} />
          <Route
            path="/account/:accountId/makeBooking"
            element={<CheckoutPage />}
          />
          <Route path="/site" element={<SiteIndexPage />} />
          <Route path="/site/create" element={<CreateSitePage />} />
          <Route path="/site/edit/:id" element={<EditSitePage />} />
          <Route path="/site/:id" element={<ViewSitePage />} />
          <Route path="/profile/" element={<ProfilePage />} />
          <Route path="/hub" element={<HubIndexPage />} />
          <Route path="/hub/create" element={<OnboardHubPage />} />

          <Route path="/hub/edit/:id" element={<EditHubPage />} />
          <Route
            path="/hub/availability/:id"
            element={<EditHubAvailabilityPage />}
          />
          <Route path="/hub/:id" element={<ViewHubPage />} />
          <Route path="/hub/:hubId/checkout" element={<CheckoutPage />} />
          <Route path="/hub/:id/:tab" element={<ViewHubPage />} />

          <Route path="/pricing" element={<PricingPlansPage />} />
          <Route path="/pricing/create" element={<CreatePricingPlanPage />} />
          <Route path="/pricing/:id" element={<EditPricingPlanPage />} />

          <Route path="/hubAllocations" element={<HubAllocationsPage />} />

          <Route path="/hubAvailabilities" element={<HubAvailabilityPage />} />

          <Route path="/order" element={<OrdersPage />} />
          <Route path="/order/:id" element={<ViewOrderPage />} />

          <Route path="/booking/" element={<ViewBookingsPage />} />
          <Route path="/booking/:id" element={<ViewBookingPage />} />

          <Route path="/customer/" element={<CustomerIndexPage />} />
          <Route path="/customer/:id" element={<CustomerPage />} />

          <Route path="/checkout" element={<CheckoutPage />} />

          <Route path="/discount/" element={<DiscountIndex />} />
          <Route path="/discount/create" element={<DiscountCreate />} />
          <Route path="/discount/:id" element={<DiscountEdit />} />

          <Route path="/membership/" element={<MembershipIndex />} />
          <Route path="/membership/create" element={<MembershipCreate />} />
          <Route path="/membership/:id" element={<MembershipEditPage />} />

          <Route path="/subscriptions/" element={<MembershipAccountIndex />} />
        </Routes>
      </HelmetProvider>
    </BrowserRouter>
  );
};

export default AppRoutes;
