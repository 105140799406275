import { useAuth0 } from "@auth0/auth0-react";
import { cx } from "@emotion/css";
import React from "react";
import { Badge, Dropdown, Image, Nav, Navbar } from "react-bootstrap";
import {
  MdEvent,
  MdOutlineAssignmentInd,
  MdOutlineAttachMoney,
  MdOutlineCabin,
  MdOutlineCreditCard,
  MdOutlineHome,
  MdOutlineLocationCity,
  MdOutlineManageAccounts,
  MdOutlinePeople,
  MdOutlinePin,
  MdShoppingCart,
} from "react-icons/md";
import { Link, NavLink } from "react-router-dom";
import Logo from "src/images/square-button-logotype--colour--cropped.png";

const EnviromentBadge = () => {
  switch (process.env.REACT_APP_API_BASE) {
    case "https://api.dev.workfromhub.co.uk":
      return (
        <Badge bg="danger" className="mb-3">
          Development environment
        </Badge>
      );

    case "https://api.test.workfromhub.co.uk":
      return (
        <Badge bg="warning" className="mb-3">
          Test environment
        </Badge>
      );
    case "https://api.workfromhub.co.uk":
      return <></>;
    default:
      return (
        <Badge bg="info" className="mb-3">
          Local environment
        </Badge>
      );
  }
};

export const SideNav = () => {
  const { user, logout } = useAuth0();

  return (
    <Navbar
      className={cx(
        "flex-column align-items-center h-100 text-white p-1 bg-dark"
      )}
    >
      <Navbar.Brand href="/" className="mt-0 mb-2 pt-1">
        <Image
          src={Logo}
          width="160px"
          className="ms-3 p-2"
          data-testid="logo"
        />
      </Navbar.Brand>
      <EnviromentBadge />

      <Nav variant="pills" className="flex-column mb-auto">
        <Nav.Item>
          <Nav.Link as={NavLink} end to="/" className="text-white">
            <MdOutlineHome size="1.5em" className="bi me-2" />
            Home
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={NavLink} end to="/customer" className="text-white">
            <MdOutlinePeople size="1.5em" className="bi me-2" />
            Customers
          </Nav.Link>
        </Nav.Item>
        <Nav.Link as={NavLink} end to="/membership" className="text-white">
          <MdOutlineAssignmentInd size="1.5em" className="bi me-2" />
          Membership
        </Nav.Link>
        <Nav.Link as={NavLink} end to="/subscriptions" className="text-white">
          <MdOutlineCreditCard size="1.5em" className="bi me-2" />
          Subscriptions <Badge bg="success">New</Badge>
        </Nav.Link>
        <Nav.Item>
          <Nav.Link as={NavLink} to="/order" className="text-white">
            <MdShoppingCart size="1.5em" className="bi me-2" />
            Orders
          </Nav.Link>
        </Nav.Item>
        <Nav.Link as={NavLink} end to="/discount" className="text-white">
          <MdOutlinePin size="1.5em" className="bi me-2" />
          Discounts
        </Nav.Link>
        <Nav.Item>
          <Nav.Link as={NavLink} end to="/site" className="text-white">
            <MdOutlineLocationCity size="1.5em" className="bi me-2" />
            Locations
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={NavLink} end to="/hub" className="text-white">
            <MdOutlineCabin size="1.5em" className="bi me-2" />
            Hubs
          </Nav.Link>
        </Nav.Item>
        <Nav.Link as={NavLink} end to="/pricing" className="text-white">
          <MdOutlineAttachMoney size="1.5em" className="bi me-2" />
          Pricing Plans
        </Nav.Link>

        <Nav.Link
          as={NavLink}
          end
          to="/hubAvailabilities"
          className="text-white"
        >
          <MdEvent size="1.5em" className="bi me-2" />
          Availability Plans
        </Nav.Link>

        <Nav.Item>
          <Nav.Link as={NavLink} end to="/account" className="text-white">
            <MdOutlineManageAccounts size="1.5em" className="bi me-2" />
            Administrators
          </Nav.Link>
        </Nav.Item>
      </Nav>

      <hr />

      {user && (
        <div className="d-grid gap-2">
          <Dropdown drop="up">
            <Dropdown.Toggle id="profile-dropdown">
              <img
                src={user.picture}
                alt={user.name}
                width="32"
                height="32"
                className="rounded-circle me-2"
                referrerPolicy="no-referrer"
              />
              <strong>{user.name}</strong>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item as={Link} to="/profile">
                Profile
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                onClick={() => logout({ returnTo: window.location.origin })}
              >
                Sign out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
    </Navbar>
  );
};
