import React from "react";
import { TabProps } from "./index";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import DateFormat, { DateFormats } from "../../components/DateFormat";
import PluralFormat, { PluralFormats } from "../../components/PluralFormat";
import checkoutService from "../../services/checkout/checkout.service";
import { CheckoutModel } from "../../services/checkout/checkout.model";
import { toast } from "react-toastify";
import { CreateBookingData } from "../../services/checkout/checkout.dto";

export interface CheckoutConfirmationForm {
  checkoutResponse?: CheckoutModel;
}

const CheckoutConfirmation = ({ context, updateContext }: TabProps) => {
  const createBooking = React.useCallback(async () => {
    const accountId = context.accountTabValues?.account?.id;
    const guestAccountEmailAddress =
      context.accountTabValues?.account === undefined
        ? context.accountTabValues?.guestAccountEmailAddress
        : undefined;

    const data = {
      accountId,
      guestAccountEmailAddress,

      hubId: context.hubTabValues!.hub.id,

      startDateTime: context.selectTimeTabValues!.startDateTime,
      endDateTime: context.selectTimeTabValues!.endDateTime,
      outsideScheduledHoursConfirmed:
        context.selectTimeTabValues!.outsideScheduledHoursConfirmed,

      paymentType: context.paymentFormTabValues?.paymentType,

      pricingPlanId: context.paymentFormTabValues!.pricingPlanId,
      totalCost: context.paymentFormTabValues!.totalCost,
      zeroCostConfirmed: context.paymentFormTabValues!.zeroCostConfirmed,
    } as CreateBookingData;

    try {
      const checkoutResponse = await checkoutService.createBooking(data);

      updateContext({
        confirmationForm: {
          checkoutResponse,
        },
      });
    } catch (error: any) {
      toast(
        error.response?.data?.message || error.message || "Something went wrong"
      );
    }
  }, [context, updateContext]);

  // because we reset the entire context when the checkout is successfully processed, we need to make sure we don't break this component
  if (
    context.selectTimeTabValues === undefined ||
    context.hubTabValues === undefined ||
    context.paymentFormTabValues === undefined ||
    context.accountTabValues === undefined
  ) {
    return <></>;
  }

  return (
    <>
      <Container fluid className="m-0 p-0">
        <Row className="bg-white p-4 pb-0">
          <h3>Confirm the Booking details</h3>
        </Row>

        <Row className="bg-white p-4">
          <Card as={Col} sm="3" className="p-4" bg="primary" text="white">
            <Row>
              <Col>
                <h2 className="text-center">
                  <DateFormat
                    date={context.selectTimeTabValues!.startDateTime}
                    format={DateFormats.dayOfWeekString}
                  />{" "}
                  <DateFormat
                    date={context.selectTimeTabValues!.startDateTime}
                    format={DateFormats.day}
                  />
                  <PluralFormat
                    format={PluralFormats.date}
                    value={context.selectTimeTabValues!.startDateTime!.getDate()}
                  />
                </h2>
              </Col>
            </Row>

            <Row>
              <Col>
                <h3 className="text-center">
                  <DateFormat
                    date={context.selectTimeTabValues!.startDateTime}
                    format={DateFormats.monthText}
                  />
                  {` `}
                  <DateFormat
                    date={context.selectTimeTabValues!.startDateTime}
                    format={DateFormats.yearFull}
                  />
                </h3>
              </Col>
            </Row>

            <Row>
              <Col className="text-center fw-bold mt-4">
                <DateFormat
                  date={context.selectTimeTabValues!.startDateTime}
                  format={DateFormats.time}
                />
                -
                <DateFormat
                  date={context.selectTimeTabValues!.endDateTime}
                  format={DateFormats.time}
                />
              </Col>
            </Row>

            {context.selectTimeTabValues!.outsideScheduledHoursConfirmed && (
              <Row>
                <Col className="text-center">
                  <em>(Outside standard hours)</em>
                </Col>
              </Row>
            )}
          </Card>

          <Card as={Col} sm="3" className="p-4 ms-3" bg="light">
            <Row>
              <Col>
                <h4>{context.hubTabValues!.hub?.name}</h4>
              </Col>
            </Row>
            <Row>
              <Col>
                {context.accountTabValues!.account === undefined
                  ? "Guest Account"
                  : context.accountTabValues!.account.displayName}{" "}
                ({" "}
                {context.accountTabValues!.account === undefined
                  ? context.accountTabValues!.guestAccountEmailAddress
                  : context.accountTabValues!.account.email}{" "}
                )
              </Col>
            </Row>
            <Row>
              <Col>{context.hubTabValues!.hub.site?.name}</Col>
            </Row>

            <Row>
              <Col className="fw-bold h4 mt-3">
                {context.paymentFormTabValues?.totalCost === 0 ? (
                  <>
                    FREE{" "}
                    {context.paymentFormTabValues!.zeroCostConfirmed
                      ? "(confirmed)"
                      : " ERROR ZERO COST NOT CONFIRMED "}
                  </>
                ) : (
                  <>£{context.paymentFormTabValues.totalCost}</>
                )}
              </Col>
            </Row>
          </Card>
        </Row>

        <Row className="bg-white p-4">
          <Col>
            <Button variant="success" size="lg" onClick={createBooking}>
              Confirm and create booking
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CheckoutConfirmation;
