import { cx } from "@emotion/css";
import { Formik } from "formik";
import React, { ReactNode } from "react";
import { Col, Row } from "react-bootstrap";
import { MdOutlineCancel, MdOutlineEdit, MdOutlineSave } from "react-icons/md";
import * as Yup from "yup";
import { FormFieldControl } from "./formFieldControl";

interface InlineTextFields {
  text: string;
}

const InlineTextFieldRequiredSchema = Yup.object().shape({
  text: Yup.string().required("Required"),
});

const InlineTextEdit = ({
  fieldTitle,
  onSubmit,
  value,
  displayValue = undefined,
  placeHolder,
  required = false,
  inputType = undefined,
  inputAs = undefined,
  DisplayAs = "p",
  children = undefined,
}: {
  fieldTitle: string;
  onSubmit: (value: string) => Promise<void>;
  value?: string;
  displayValue?: string;
  placeHolder?: string;
  required?: boolean;
  inputType?: string;
  inputAs?: React.ElementType;
  DisplayAs?: React.ElementType;
  children?: ReactNode;
}) => {
  const [isEditing, setIsEditing] = React.useState(false);

  return (
    <>
      {isEditing ? (
        <>
          <Formik<InlineTextFields>
            validationSchema={
              required ? InlineTextFieldRequiredSchema : undefined
            }
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              await onSubmit(values.text);
              setSubmitting(false);

              setIsEditing(false);
            }}
            initialValues={{
              text: value || "",
            }}
          >
            {({ submitForm, isSubmitting }) => (
              <>
                <Row className="mt-5">
                  <Col sm="auto">
                    <h2 className="h5">{fieldTitle}</h2>
                  </Col>
                  <Col>
                    <MdOutlineSave
                      onClick={() => {
                        if (!isSubmitting) submitForm();
                      }}
                      title="Save"
                      role="button"
                      className={cx("h5", isSubmitting ? "text-muted" : "")}
                    />
                    <MdOutlineCancel
                      onClick={() => {
                        if (!isSubmitting) setIsEditing(false);
                      }}
                      title="Cancel"
                      role="button"
                      className={cx("h5", isSubmitting ? "text-muted" : "")}
                    />
                  </Col>
                </Row>

                <FormFieldControl
                  label={`Edit ${fieldTitle.toLowerCase()}`}
                  fieldName="text"
                  placeholder={placeHolder}
                  showLabel
                  type={inputType}
                  as={inputAs}
                >
                  {children}
                </FormFieldControl>
              </>
            )}
          </Formik>
        </>
      ) : (
        <>
          <Row className="mt-5">
            <Col sm="auto">
              <h2 className="h5">{fieldTitle}</h2>
            </Col>
            <Col>
              <MdOutlineEdit
                role="button"
                onClick={() => setIsEditing(true)}
                className="h5"
              />
            </Col>
          </Row>
          <DisplayAs>{displayValue || value}</DisplayAs>
        </>
      )}
    </>
  );
};

export default InlineTextEdit;
