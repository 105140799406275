import React from "react";
import { Breadcrumb, Button, Card, Placeholder, Stack } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { MdOutlineWarning } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";
import ModalButton from "src/components/modalButton";
import Page from "src/components/page";
import tryAsyncToast from "src/hooks/tryAsyncToast";
import useAsyncRetry from "src/hooks/useAsyncRetry";
import discountService from "src/services/discount/discount.service";
import { withAuthAndAuthRequired } from "../../hooks/withAuthorisationRequired";
import { DiscountSummary } from "src/pages/discount/discountSummary";
import {
  discountDtoToFormValues,
  DiscountForm,
  DiscountFormValues,
  formValuesToDiscountDto,
} from "src/pages/discount/discountForm";

const DiscountEditPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { value: discount, isLoading } = useAsyncRetry(async () => {
    if (!id) return undefined;

    return await discountService.findById(id);
  }, []);

  const updateDiscount = async (discountFormValues: DiscountFormValues) => {
    if (id) {
      const update = formValuesToDiscountDto(discountFormValues);
      update.id = parseInt(id);

      try {
        await tryAsyncToast(discountService.update(update.id, update), {
          success: `Discount ${discountFormValues.discountCode} updated`,
        });

        // TODO: reload / refresh page
      } catch (error: any) {}
    }
  };

  const deactivateDiscount = React.useCallback(async (id, discountCode) => {
    try {
      await tryAsyncToast(discountService.deactivate(id), {
        success: `Discount ${discountCode} deactivated`,
      });

      // TODO: reload / refresh page
    } catch (error: any) {}
  }, []);

  const deleteDiscount = React.useCallback(
    async (id, discountCode) => {
      try {
        await tryAsyncToast(discountService.delete(id), {
          success: `Discount ${discountCode} deleted`,
        });

        navigate(`/discount/`);
      } catch (error: any) {}
    },
    [navigate]
  );

  return (
    <Page>
      {isLoading ? (
        <>
          <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/discount" }}>
              Discounts
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{id}</Breadcrumb.Item>
          </Breadcrumb>
          <Stack direction="horizontal" gap={3} className={"mb-3"}>
            <div>
              <h2>
                <Placeholder xs={6} />
              </h2>
            </div>
            <div className="ms-auto" />
          </Stack>
        </>
      ) : (
        <>
          {discount === undefined ? (
            <Card className="text-center mb-3">
              <Card.Body className="py-5">
                <MdOutlineWarning size="5em" className="mb-3" />
                <h3>There’s no discount at this address</h3>
                <p>
                  Check the URL and try again, or use the discounts page to find
                  what you need.
                </p>
              </Card.Body>
            </Card>
          ) : (
            <>
              <Helmet title={discount.code} />
              <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/discount" }}>
                  Discounts
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{id}</Breadcrumb.Item>
              </Breadcrumb>
              <Stack direction="horizontal" gap={3} className={"mb-3"}>
                <div>
                  <h2>{discount.code}</h2>
                </div>
                <div className="ms-auto">
                  {discount.isActive && (
                    <ModalButton
                      variant="outline-danger"
                      title={`Deactivate ${discount.code}`}
                      text="Deactivate"
                      footer={({ hideModal }) => (
                        <>
                          <Button
                            onClick={hideModal}
                            variant="outline-secondary"
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="success"
                            onClick={async () =>
                              await deactivateDiscount(
                                discount.id,
                                discount.code
                              )
                            }
                          >
                            Deactivate
                          </Button>
                        </>
                      )}
                    >
                      The discount will expire and can no longer be used
                    </ModalButton>
                  )}
                </div>
              </Stack>

              <DiscountForm
                onSubmit={updateDiscount}
                initialValues={discountDtoToFormValues(discount)}
                discount={discount}
                deleteDiscount={() =>
                  deleteDiscount(discount.id, discount.code)
                }
              >
                {(values) => (
                  <>
                    <DiscountSummary {...values} discount={discount} />
                  </>
                )}
              </DiscountForm>
            </>
          )}
        </>
      )}
    </Page>
  );
};
export default withAuthAndAuthRequired(DiscountEditPage);
