import { Formik } from "formik";
import React from "react";
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Card,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import { FormFieldControl } from "src/components/formFieldControl";
import * as Yup from "yup";

export interface SiteFormValues {
  id?: number;
  name: string;
  summary: string;
  description: string;
  notes: string;
  addressLine0: string;
  addressLine1: string;
  latitude: number;
  longitude: number;
}

const sitePageFormValidationSchema = Yup.object().shape({
  name: Yup.string().min(2).required("Name is required"),
  summary: Yup.string(),
  description: Yup.string(),
  addressLine0: Yup.string(),
  addressLine1: Yup.string(),
  latitude: Yup.number(),
  longitude: Yup.number(),
});

interface Props {
  site: SiteFormValues;
  onSubmit: (values: SiteFormValues) => Promise<void>;
}

function SiteForm({ site, onSubmit }: Props) {
  return (
    <Formik<SiteFormValues>
      initialValues={{
        id: site.id,
        summary: site.summary,
        name: site.name,
        description: site.description,
        notes: site.notes,
        addressLine0: site.addressLine0,
        addressLine1: site.addressLine1,
        latitude: site.latitude,
        longitude: site.longitude,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        await onSubmit(values);
        setSubmitting(false);
      }}
      validationSchema={sitePageFormValidationSchema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
        >
          <Container fluid className="mt-3 ps-0">
            <Row>
              <Col>
                <Card className="h-100">
                  <Card.Body>
                    <Card.Title>Details</Card.Title>

                    {site.id && (
                      <Form.Group as={Row}>
                        <Form.Label column sm={2}>
                          Id
                        </Form.Label>
                        <Col sm={10}>{site.id}</Col>
                      </Form.Group>
                    )}

                    <FormFieldControl
                      fieldName="name"
                      label="Site name"
                      placeholder="Name of site"
                      className="my-3"
                    />

                    <FormFieldControl
                      fieldName="summary"
                      label="Summary"
                      placeholder="Simple summary of site"
                      as="textarea"
                      className="mb-3"
                    />

                    <FormFieldControl
                      fieldName="description"
                      label="Description"
                      placeholder="Description of site"
                      as="textarea"
                      className="mb-3"
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card className="h-100">
                  <Card.Body>
                    <Card.Title>Location information</Card.Title>

                    <FormFieldControl
                      fieldName="addressLine0"
                      label="Address Line 1"
                      placeholder="First Line"
                      className="my-3"
                    />

                    <FormFieldControl
                      fieldName="addressLine1"
                      label="Address Line 2"
                      placeholder="Second Line"
                      className="mb-3"
                    />

                    <FormFieldControl
                      fieldName="latitude"
                      label="Latitude"
                      placeholder="Latitude"
                      className="mb-3"
                    />

                    <FormFieldControl
                      fieldName="longitude"
                      label="Longitude"
                      placeholder="Longitude"
                      className="mb-3"
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col>
                <ButtonToolbar>
                  <ButtonGroup>
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
              </Col>
            </Row>
          </Container>
        </Form>
      )}
    </Formik>
  );
}

export default SiteForm;
