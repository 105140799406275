export interface SearchProps {
  text?: string;
  filter?: string;
  page?: number;
  numberPerPage?: number;
  sortColumn?: string;
  sortAscending?: boolean;
}

export interface PaginationResultDto<T> {
  rows: T[];
  count: number;
}

export interface SearchableService<T> {
  search(search: SearchProps): Promise<PaginationResultDto<T> | undefined>;

  searchFilters(): { [key: string]: string } | undefined;
}

interface SearchServiceOverride<T> {
  search?: (
    service: SearchableService<T>,
    search: SearchProps
  ) => Promise<PaginationResultDto<T> | undefined>;

  searchFilters?: (
    service: SearchableService<T>
  ) => { [key: string]: string } | undefined;
}

export const overrideSearchService = function <T>(
  originalService: SearchableService<T>,
  { search, searchFilters }: SearchServiceOverride<T>
) {
  return {
    search: (values: SearchProps) =>
      search ? search(originalService, values) : originalService.search(values),
    searchFilters: () =>
      searchFilters
        ? searchFilters(originalService)
        : originalService.searchFilters(),
  };
};

export const decodeSearch = (search: SearchProps) => {
  const defaultSearch: SearchProps = {
    page: 1,
    numberPerPage: 10,
    ...search,
  };

  return Object.entries(defaultSearch)
    .map(([key, value]) => {
      return encodeURIComponent(key) + `=` + encodeURIComponent(value);
    })
    .join("&");
};
