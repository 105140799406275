import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { MediaFieldControl } from "src/components/mediaFieldControl";
import MediaFieldGallery from "../../components/mediaFieldGallery";
import { MediaModel } from "../../services/media/media.model";
import {
  SiteCreateOrUpdateDTO,
  SiteModel,
} from "../../services/site/site.model";
import siteService from "../../services/site/site.service";

export const SiteImages = ({
  site,
  onSave,
}: {
  site: SiteCreateOrUpdateDTO;
  onSave: (
    partialMedia: Partial<Pick<SiteModel, "featuredMedia" | "media">>
  ) => Promise<void>;
}) => {
  const saveFeaturedMedia = React.useCallback(
    async (media: MediaModel) => {
      if (site.id !== undefined) {
        await siteService.updateFeaturedMedia(site.id, media);
      }

      await onSave({ featuredMedia: media });
    },
    [onSave, site.id]
  );

  const removeFeaturedMedia = React.useCallback(async () => {
    if (site.id !== undefined) {
      await siteService.updateFeaturedMedia(site.id, null);
    }
    await onSave({ featuredMedia: undefined });
  }, [onSave, site.id]);

  const updateMediaGalleryItem = React.useCallback(
    async (medium: MediaModel, index: number) => {
      if (site.media === undefined || index < 0 || index >= site.media.length)
        throw new Error("Invalid media index updated");

      if (site.id !== undefined) {
        await siteService.updateMedia(site.id, site.media[index].id, medium);
      }

      const media = [...site.media];
      media[index] = medium;

      await onSave({ media });
    },
    [onSave, site.id, site.media]
  );

  const removeMediaGalleryItem = React.useCallback(
    async (index: number) => {
      if (site.media === undefined || index < 0 || index >= site.media.length)
        throw new Error("Invalid media index removed");

      if (site.id !== undefined) {
        await siteService.deleteMedia(site.id, site.media[index].id);
      }

      const media = [...site.media];
      media.splice(index, 1);

      await onSave({ media });
    },
    [onSave, site.id, site.media]
  );

  const createMediaGalleryItem = React.useCallback(
    async (medium: MediaModel) => {
      if (site.id !== undefined) {
        await siteService.createMedia(site.id, medium);
      }
      const media = [...(site.media || []), medium];
      await onSave({ media });
    },
    [onSave, site.id, site.media]
  );

  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Images</Card.Title>
        <Row sm={2} md={3} lg={3} xl={3} className="gx-3 px-2">
          <Col>
            <MediaFieldControl
              label="Featured Image"
              initialMediaField={site.featuredMedia}
              onSave={saveFeaturedMedia}
              onRemove={removeFeaturedMedia}
              autoUpdateMedia={site.id !== undefined}
            />
          </Col>
        </Row>
        <Row sm={2} md={3} lg={3} xl={3} className="my-3 px-2">
          <MediaFieldGallery
            onSave={updateMediaGalleryItem}
            onRemove={removeMediaGalleryItem}
            onCreate={createMediaGalleryItem}
            values={site.media || []}
            autoUpdateMedia={site.id !== undefined}
          />
        </Row>
      </Card.Body>
    </Card>
  );
};
