import { BaseService } from "src/services/baseService";
import {
  CreateFullHubDto,
  CreateHubDto,
  HubModel,
  HubModelSearchResults,
} from "src/services/hub/hub.model";
import { HubAvailabilityModel } from "src/services/hubAvailability/hubAvailability.model";
import { MediaModel } from "src/services/media/media.model";
import {
  decodeSearch,
  SearchableService,
  SearchProps,
} from "src/services/searchableService";
import { array, model, property } from "decoverto";
import { HubAllocationModel } from "../hubAllocation/hubAllocation.model";
import { HubAllocationDto } from "../hubAllocation/hubAllocation.dto";
import { makeFieldsUtc } from "../../utils/dateFnsExtra";

@model()
class ValidationResult {
  @property(array(() => HubAllocationModel))
  overlappingTimes?: HubAllocationDto[];

  @property(array(() => HubAvailabilityModel))
  availability?: HubAvailabilityModel[];
}

class HubService
  extends BaseService<HubModel>
  implements SearchableService<HubModel>
{
  constructor() {
    super(HubModel, "/hub");
  }

  async findById(
    id: number,
    relations = "site,featuredMedia,media,pastAllocations,currentAllocations,futureAllocations,hubAvailabilities[pricingPlans]"
  ) {
    const response = await this._getParsed(`/${id}?relations=${relations}`);

    return response.data;
  }

  async create(hub: CreateHubDto) {
    const response = await this._post("", hub);

    return response.data;
  }

  async createFull(hub: CreateFullHubDto) {
    const response = await this._post("?relations=featuredMedia,media", hub);

    return response.data;
  }

  async update(id: number, hub: Partial<HubModel>) {
    const response = await this._patch(`/${id}`, hub);
    return response.data;
  }

  async search(search: SearchProps) {
    const params = decodeSearch(search);

    const result = (
      await this._getParsed(
        `/search?relations=site,featuredMedia&${params}`,
        {},
        HubModelSearchResults
      )
    ).data;

    return result;
  }

  searchFilters(): { [p: string]: string } | undefined {
    return {};
  }

  async updateFeaturedMedia(id: number, featuredMedia: MediaModel | null) {
    return (
      await this._patch(`/${id}`, {
        featuredMediaId: featuredMedia === null ? null : featuredMedia.id,
      })
    ).data;
  }

  async createMedia(id: number, media: MediaModel) {
    await this._post(`/${id}/media`, media);
  }

  async updateMedia(id: number, mediaId: number, media: MediaModel) {
    await this._patch(`/${id}/media/${mediaId}`, media);
  }

  async deleteMedia(id: number, mediaId: number) {
    await this._delete(`/${id}/media/${mediaId}`);
  }

  async createAvailability(id: number, hubAvailability: HubAvailabilityModel) {
    return this._post(`/${id}/hubAvailability`, hubAvailability);
  }

  async updateAvailability(
    id: number,
    availabilityId: number,
    hubAvailability: HubAvailabilityModel
  ) {
    await this._patch(
      `/${id}/hubAvailability/${availabilityId}`,
      hubAvailability
    );
  }

  async addAvailability(id: number, availabilityId: number) {
    await this._post(`/${id}/hubAvailability/${availabilityId}`);
  }

  async deleteAvailability(id: number, availabilityId: number) {
    await this._delete(`/${id}/hubAvailability/${availabilityId}`);
  }

  async validateAllocationDates(fields: {
    hubId: number;
    startDateTime: Date;
    endDateTime: Date;
  }) {
    const result = await this._postUnParsed(
      "/validateDates",
      makeFieldsUtc(fields, "startDateTime", "endDateTime")
    );
    return result.data as ValidationResult;
  }

  async createAllocation(fields: {
    hubId: number;
    startDateTime: Date;
    endDateTime: Date;
    type: number;
    _force?: boolean;
  }) {
    const result = await this._postUnParsed(
      "/hubAllocation",
      makeFieldsUtc(fields, "startDateTime", "endDateTime")
    );
    return result.data as ValidationResult;
  }

  async updateLockId(
    id: number,
    data: { lockId: number | null; replaceLock?: boolean }
  ) {
    return (await this._post(`/${id}/updateLock`, data)).data;
  }

  async unlock(id: number) {
    return (await this._getUnparsed(`/${id}/unlock`)).data;
  }
}

const hubService = new HubService();

export default hubService;
