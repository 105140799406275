import React from "react";

const DAY_OF_WEEK = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const DayOfWeek = ({ dayOfWeek }: { dayOfWeek: number }) => {
  return <>{DAY_OF_WEEK[dayOfWeek]}</>;
};
