import { BaseService } from "src/services/baseService";
import { MediaModel } from "src/services/media/media.model";
import {
  decodeSearch,
  SearchableService,
  SearchProps,
} from "src/services/searchableService";
import {
  SiteCreateDTO,
  SiteModel,
  SiteModelSearchResults,
} from "src/services/site/site.model";

class SiteService
  extends BaseService<SiteModel>
  implements SearchableService<SiteModel>
{
  constructor() {
    super(SiteModel, "/site");
  }

  public async find(id: number) {
    const response = await this._getParsed(
      `/${id}?relations=hubs,media,featuredMedia`
    );
    return response.data;
  }

  public async findAll() {
    const response = await this._getArray("?relations=hubs,featuredMedia");
    return response.data;
  }

  public async search(search: SearchProps) {
    const params = decodeSearch(search);

    return (
      await this._getParsed(
        `/search?relations=hubs,featuredMedia&${params}`,
        {},
        SiteModelSearchResults
      )
    ).data;
  }

  public searchFilters() {
    return {
      isActive: "Is Active",
    };
  }

  public async create(site: SiteCreateDTO) {
    const response = await this._post("?relations=featuredMedia,media", site);
    return response.data;
  }

  async update(id: number, site: Partial<SiteModel>) {
    return (await this._patch(`/${id}`, site)).data;
  }

  async updateFeaturedMedia(id: number, featuredMedia: MediaModel | null) {
    return (
      await this._patch(`/${id}?relations=featuredMedia`, {
        featuredMediaId: featuredMedia === null ? null : featuredMedia.id,
      })
    ).data;
  }

  async createMedia(id: number, media: MediaModel) {
    await this._post(`/${id}/media`, media);
  }

  async updateMedia(id: number, mediaId: number, media: MediaModel) {
    await this._patch(`/${id}/media/${mediaId}`, media);
  }

  async deleteMedia(id: number, mediaId: number) {
    await this._delete(`/${id}/media/${mediaId}`);
  }
}

const siteService = new SiteService();

export default siteService;
