import React, { ReactNode, useState } from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  InputGroup,
  Row,
  Stack,
} from "react-bootstrap";
import moment from "src/types/momentWithLocale";
import ModalButton from "src/components/modalButton";
import ButtonLink from "src/components/ButtonLink";
import { DiscountModel } from "src/services/discount/discount.model";

export type DiscountFormValues = {
  discountCode: string;
  discountType: string;
  discountValue?: number;
  hasMinimumAmount: boolean;
  minimumAmount?: number;
  hasMaximumNumberOfUses: boolean;
  maximumNumberOfUses?: number;
  appliesOncePerCustomer: boolean;
  startDate: Date;
  hasEndDate: boolean;
  endDate?: Date;
  notes?: string;
};

export const formValuesToDiscountDto = (formValues: DiscountFormValues) => {
  let discount = new DiscountModel();
  discount.code = formValues.discountCode;
  discount.type = formValues.discountType;
  discount.value =
    formValues.discountValue !== undefined ? formValues.discountValue : 0;

  discount.minimumAmount = formValues.hasMinimumAmount
    ? formValues.minimumAmount
    : null;

  discount.maximumNumberOfUses = formValues.hasMaximumNumberOfUses
    ? formValues.maximumNumberOfUses
    : null;

  discount.appliesOncePerCustomer = formValues.appliesOncePerCustomer;
  discount.startDate = formValues.startDate;
  discount.endDate = formValues.hasEndDate ? formValues.endDate : null;
  discount.notes = formValues.notes || "";

  return discount;
};

export const discountDtoToFormValues = (discount: DiscountModel) => {
  const discountForm: DiscountFormValues = {
    discountCode: discount.code,
    discountType: discount.type,
    hasEndDate: !!discount.endDate,
    hasMinimumAmount: !!discount.minimumAmount,
    hasMaximumNumberOfUses: !!discount.maximumNumberOfUses,
    minimumAmount: discount.minimumAmount || 0,
    maximumNumberOfUses: !discount.maximumNumberOfUses
      ? undefined
      : discount.maximumNumberOfUses,
    endDate: discount.endDate || undefined,
    startDate: discount.startDate,
    discountValue: discount.value,
    appliesOncePerCustomer: discount.appliesOncePerCustomer,
    notes: discount.notes,
  };
  return discountForm;
};

export const DiscountForm = ({
  onSubmit,
  children,
  initialValues,
  discount,
  deleteDiscount,
}: {
  onSubmit: (discount: DiscountFormValues) => PromiseLike<void>;
  children: (values: DiscountFormValues) => ReactNode;
  initialValues?: DiscountFormValues;
  discount?: DiscountModel;
  deleteDiscount?: () => PromiseLike<void>;
}) => {
  const [validated, setValidated] = useState(false);
  const [validationErrors, setValidationErrors] = useState(false);

  const [discountCode, setDiscountCode] = useState(
    initialValues?.discountCode || ""
  );
  const [discountValue, setDiscountValue] = useState<undefined | number>(
    initialValues?.discountValue
  );
  const [discountType, setDiscountType] = useState<string>(
    initialValues?.discountType || "percent"
  );

  const [hasMinimumAmount, setHasMinimumAmount] = useState(
    !!initialValues?.hasMinimumAmount
  );
  const [minimumAmount, setMinimumAmount] = useState<undefined | number>(
    initialValues?.minimumAmount
  );

  const [hasMaximumNumberOfUses, setHasMaximumNumberOfUses] = useState(
    !!initialValues?.hasMaximumNumberOfUses
  );
  const [maximumNumberOfUses, setMaximumNumberOfUses] = useState(
    initialValues?.maximumNumberOfUses
  );

  const [appliesOncePerCustomer, setAppliesOncePerCustomer] = useState(
    !!initialValues?.appliesOncePerCustomer
  );

  const [startDate, setStartDate] = useState(
    initialValues?.startDate || new Date()
  );
  const [hasEndDate, setHadEndDate] = useState(!!initialValues?.hasEndDate);
  const [endDate, setEndDate] = useState(initialValues?.endDate || new Date());

  const [notes, setNotes] = useState(initialValues?.notes || "");

  function generateRandomCode(len: number, an?: string) {
    an = an && an.toLowerCase();
    let str = "",
      i = 0,
      min = an === "a" ? 10 : 0,
      max = an === "n" ? 10 : 62;
    for (; i++ < len; ) {
      let r = (Math.random() * (max - min) + min) << 0;
      str += String.fromCharCode((r += r > 9 ? (r < 36 ? 55 : 61) : 48));
    }
    return str;
  }

  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidationErrors(true);
    } else {
      setValidationErrors(false);

      event.preventDefault();
      event.stopPropagation();
    }

    onSubmit?.({
      discountCode,
      discountType,
      discountValue,
      hasMinimumAmount,
      minimumAmount,
      hasMaximumNumberOfUses,
      maximumNumberOfUses,
      appliesOncePerCustomer,
      startDate,
      hasEndDate,
      endDate,
      notes,
    });

    setValidated(true);
  };

  return (
    <>
      {validationErrors && (
        <Alert variant="danger">
          <Alert.Heading>Discount errors</Alert.Heading>
          Please resolve the errors with this discount
        </Alert>
      )}
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col md={8}>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Discount code</Card.Title>
                <InputGroup className="mb-3">
                  <Form.Control
                    disabled={initialValues !== undefined}
                    value={discountCode}
                    onChange={(e) =>
                      setDiscountCode(e.target.value.toUpperCase())
                    }
                    required
                    minLength={6}
                    maxLength={32}
                    placeholder="e.g. SUMMERSALE"
                    aria-label="discount code"
                  />
                  {initialValues === undefined && (
                    <Button
                      onClick={() =>
                        setDiscountCode(generateRandomCode(10).toUpperCase())
                      }
                      variant="outline-primary"
                      id="button-generate-code"
                    >
                      Generate code
                    </Button>
                  )}
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Please choose or generate a discount code.
                  </Form.Control.Feedback>
                </InputGroup>
                <Card.Text>
                  customers will enter this code at checkout
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Value</Card.Title>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Discount type</Form.Label>
                    </Form.Group>
                    <div className="d-grid gap-2">
                      <ButtonGroup className="mb-2">
                        <Button
                          disabled={initialValues !== undefined}
                          variant={
                            discountType === "percent"
                              ? "primary"
                              : "outline-primary"
                          }
                          onClick={() => {
                            setDiscountType("percent");
                          }}
                        >
                          Percentage
                        </Button>
                        <Button
                          disabled={initialValues !== undefined}
                          variant={
                            discountType === "amount"
                              ? "primary"
                              : "outline-primary"
                          }
                          onClick={() => {
                            setDiscountType("amount");
                          }}
                        >
                          Fixed amount
                        </Button>
                      </ButtonGroup>
                    </div>
                  </Col>
                  <Form.Group as={Col}>
                    <Form.Label>Discount value</Form.Label>
                    <InputGroup className="mb-3">
                      {discountType === "amount" && (
                        <InputGroup.Text>£</InputGroup.Text>
                      )}
                      <Form.Control
                        value={discountValue}
                        disabled={initialValues !== undefined}
                        onChange={(e) =>
                          setDiscountValue(parseFloat(e.target.value))
                        }
                        required
                        type="number"
                        min={discountType === "amount" ? 0.01 : undefined}
                        step={discountType === "amount" ? 0.01 : undefined}
                        max={discountType === "percent" ? 100 : undefined}
                        placeholder={discountType === "percent" ? "0" : "0.00"}
                        aria-label="Discount value"
                      />
                      {discountType === "percent" && (
                        <InputGroup.Text>%</InputGroup.Text>
                      )}
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Please choose a discount value.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Row>
              </Card.Body>
            </Card>

            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Minimum requirements</Card.Title>
                <Form.Group key="mimimum-requirements" className="mb-3">
                  <Form.Check
                    required
                    checked={!hasMinimumAmount}
                    onChange={(e) => setHasMinimumAmount(!e.target.checked)}
                    label="None"
                    name="mimimum-requirements"
                    type="radio"
                    id={`mimimum-none`}
                  />
                  <Form.Check
                    checked={hasMinimumAmount}
                    onChange={(e) => setHasMinimumAmount(e.target.checked)}
                    label="Amount"
                    name="mimimum-requirements"
                    type="radio"
                    id={`mimimum-amount`}
                  />
                  {hasMinimumAmount && (
                    <InputGroup className="mb-3 mt-2  px-4">
                      <InputGroup.Text>£</InputGroup.Text>
                      <Form.Control
                        value={minimumAmount}
                        onChange={(e) =>
                          setMinimumAmount(parseFloat(e.target.value))
                        }
                        required={hasMinimumAmount}
                        type="number"
                        max={100}
                        min={0}
                        step=".01"
                        placeholder="0.00"
                        aria-label="Minimum amount"
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Please enter a minimum required amount.
                      </Form.Control.Feedback>
                    </InputGroup>
                  )}
                </Form.Group>
              </Card.Body>
            </Card>

            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Usage Limits</Card.Title>
                <Form.Check
                  checked={hasMaximumNumberOfUses}
                  onChange={(e) => setHasMaximumNumberOfUses(e.target.checked)}
                  type="checkbox"
                  id={`usage-total`}
                  label={`Limit number of times this discount can be used in total`}
                />
                {hasMaximumNumberOfUses && (
                  <InputGroup className="mb-3 mt-2  px-4">
                    <Form.Control
                      value={maximumNumberOfUses}
                      onChange={(e) =>
                        setMaximumNumberOfUses(parseInt(e.target.value))
                      }
                      required={hasMaximumNumberOfUses}
                      type="number"
                      min={1}
                      aria-label="Usage Limits"
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please enter a maximum number of uses.
                    </Form.Control.Feedback>
                  </InputGroup>
                )}
                <Form.Check
                  checked={appliesOncePerCustomer}
                  onChange={(e) => setAppliesOncePerCustomer(e.target.checked)}
                  type="checkbox"
                  id={`usage-customer`}
                  label={`Limit to one use per customer`}
                />
              </Card.Body>
            </Card>

            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Active dates</Card.Title>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                      disabled={discount?.isActive}
                      value={moment(new Date(startDate)).format("YYYY-MM-DD")}
                      onChange={(e) => {
                        setStartDate(new Date(e.target.value));
                      }}
                      required
                      type="date"
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Start Time</Form.Label>
                    <Form.Control
                      disabled={discount?.isActive}
                      value={moment(new Date(startDate)).format("HH:mm")}
                      onChange={(e) => {
                        let dateTime = startDate;
                        dateTime.setHours(
                          parseInt(e.target.value.split(":")[0])
                        );
                        dateTime.setMinutes(
                          parseInt(e.target.value.split(":")[1])
                        );

                        setStartDate(new Date(dateTime));
                      }}
                      required
                      type="time"
                    />
                  </Form.Group>
                </Row>
                <Form.Check
                  checked={hasEndDate}
                  onChange={(e) => setHadEndDate(e.target.checked)}
                  className="mb-3"
                  type="checkbox"
                  id={`active-has-end`}
                  label={`Set end date`}
                />
                {hasEndDate && (
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label>End Date</Form.Label>
                      <Form.Control
                        value={moment(new Date(endDate)).format("YYYY-MM-DD")}
                        onChange={(e) => setEndDate(new Date(e.target.value))}
                        required={hasEndDate}
                        type="date"
                      />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>End Time</Form.Label>
                      <Form.Control
                        defaultValue={"23:59"}
                        required={hasEndDate}
                        type="time"
                      />
                    </Form.Group>
                  </Row>
                )}
              </Card.Body>
            </Card>

            <h5>Notes</h5>

            <Form.Group as={Col}>
              <Form.Control
                defaultValue={notes}
                type="text"
                as="textarea"
                onChange={(e) => setNotes(e.target.value)}
              />
            </Form.Group>
          </Col>

          {children({
            appliesOncePerCustomer,
            discountCode,
            discountValue,
            discountType,
            hasMinimumAmount,
            maximumNumberOfUses,
            minimumAmount,
            endDate,
            hasEndDate,
            hasMaximumNumberOfUses,
            startDate,
            notes,
          })}
        </Row>

        <hr />

        <Stack direction="horizontal" gap={3} className={"mb-3"}>
          {initialValues && (
            <>
              {
                <div>
                  {deleteDiscount && !discount?.numberOfUses && (
                    <ModalButton
                      variant="danger"
                      title={`Delete ${discountCode}`}
                      text="Delete"
                      footer={({ hideModal }) => (
                        <>
                          <Button
                            onClick={hideModal}
                            variant="outline-secondary"
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="danger"
                            onClick={async () => await deleteDiscount()}
                          >
                            Delete Discount
                          </Button>
                        </>
                      )}
                    >
                      Are you sure you want to delete{" "}
                      <b>{initialValues.discountCode}</b>? This can't be undone.
                    </ModalButton>
                  )}
                </div>
              }
              <div className="ms-auto">
                <ModalButton
                  variant="outline-secondary"
                  title="Leave page with unsaved changes"
                  text="Discard"
                  footer={({ hideModal }) => (
                    <>
                      <Button onClick={hideModal} variant="outline-secondary">
                        Stay
                      </Button>
                      <ButtonLink to="/discount" variant="danger">
                        Discard
                      </ButtonLink>
                    </>
                  )}
                >
                  Leaving this page will remove all unsaved changes.
                </ModalButton>
              </div>
              <div>
                <Button variant="success" type="submit">
                  Save Changes
                </Button>
              </div>
            </>
          )}

          {discount === undefined && (
            <>
              <div>
                <ModalButton
                  variant="outline-secondary"
                  title="Leave page with unsaved changes"
                  text="Discard"
                  footer={({ hideModal }) => (
                    <>
                      <Button onClick={hideModal} variant="outline-secondary">
                        Stay
                      </Button>
                      <ButtonLink to="/discount" variant="danger">
                        Discard
                      </ButtonLink>
                    </>
                  )}
                >
                  Leaving this page will remove all unsaved changes.
                </ModalButton>
              </div>
              <div className="ms-auto">
                <Button variant="success" type="submit">
                  Create Discount
                </Button>
              </div>
            </>
          )}
        </Stack>
      </Form>
    </>
  );
};
