import React from "react";
import { Button, Card, ListGroup } from "react-bootstrap";
import { SubscriptionStatusBadge } from "src/components/badges/subscription.status.badge";
import DateFormat, { DateFormats, UTC } from "src/components/DateFormat";
import useAsyncRetry from "src/hooks/useAsyncRetry";
import accountService from "src/services/account/account.service";

const CustomerSubscriptions = ({
  customerId,
  stripeCustomerId,
}: {
  customerId?: string;
  stripeCustomerId?: string;
}) => {
  const { value: subscriptions } = useAsyncRetry(async () => {
    if (customerId === undefined) return undefined;
    const idVal = parseInt(customerId, 10);
    if (isNaN(idVal)) return undefined;
    const { data } = await accountService.findSubscriptions(idVal);
    return data;
  }, []);

  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Membership</Card.Title>
        {subscriptions && (
          <>
            {subscriptions?.length < 1 && (
              <>
                <Card.Text>No subscriptions</Card.Text>
                <a
                  target="_blank"
                  href={`https://dashboard.stripe.com/${
                    process.env.REACT_APP_API_BASE !==
                    "https://api.workfromhub.co.uk"
                      ? "test/"
                      : ""
                  }subscriptions?create=subscription${
                    stripeCustomerId
                      ? `&subscription_default_customer=${stripeCustomerId}`
                      : ""
                  }`}
                  rel="noreferrer"
                >
                  <Button variant="outline-secondary" size="sm">
                    Add subscription
                  </Button>
                </a>
                {/* <ButtonLink
                  variant="outline-secondary"
                  size="sm"
                  to={`/subscription/create?customerId=${customerId}`}
                >
                  Add subscription
                </ButtonLink> */}
              </>
            )}
          </>
        )}
      </Card.Body>
      {subscriptions && (
        <ListGroup variant="flush">
          {subscriptions.map((subscription) => {
            return (
              <ListGroup.Item
                className="d-flex justify-content-between align-items-start"
                key={subscription.id}
              >
                <div className="me-auto">
                  <div className="fw-bold">
                    {subscription.membership?.name}{" "}
                    <SubscriptionStatusBadge subscription={subscription} />
                  </div>
                  Billing monthly -{" "}
                  {!subscription.cancelOn ? (
                    <>
                      Next invoice on{" "}
                      <UTC>
                        <DateFormat
                          format={DateFormats.dayMonth}
                          date={subscription.currentPeriodEnd}
                        />
                      </UTC>{" "}
                      for £{subscription.membership?.cost.toFixed(2)}
                    </>
                  ) : (
                    <>No future invoices</>
                  )}
                </div>
                <div className="mt-2">
                  <a
                    target="_blank"
                    href={`https://dashboard.stripe.com/${
                      process.env.REACT_APP_API_BASE !==
                      "https://api.workfromhub.co.uk"
                        ? "test/"
                        : ""
                    }subscriptions/${subscription.stripeSubscriptionId}`}
                    rel="noreferrer"
                  >
                    <Button variant="outline-secondary" size="sm">
                      Manage
                    </Button>
                  </a>
                </div>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      )}
    </Card>
  );
};

export default CustomerSubscriptions;
