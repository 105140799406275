import { model, property } from "decoverto";
import { AccountDto } from "../account/account.dto";
import { HubAllocationDto } from "../hubAllocation/hubAllocation.dto";
import { BookingDto } from "../booking/booking.dto";
import { OrderDto } from "../order/order.dto";
import { AccountModel } from "../account/account.model";
import { HubAllocationModel } from "../hubAllocation/hubAllocation.model";
import { BookingModel } from "../booking/booking.model";
import { OrderModel } from "../order/order.model";

@model()
export class CheckoutModel {
  @property(() => AccountModel)
  account!: AccountDto;

  @property(() => HubAllocationModel)
  allocation!: HubAllocationDto;

  @property(() => BookingModel)
  booking!: BookingDto;

  @property(() => OrderModel)
  order!: OrderDto;

  @property(() => String)
  emailStatus?: "Success" | "Failed" | "Pending Stripe" | "NA";

  payment: any;
}
