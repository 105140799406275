import React from "react";
import { Badge, Col, Image, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import ButtonLink from "src/components/ButtonLink";
import Page from "src/components/page";
import SearchTable from "src/components/searchTable";
import { SiteModel } from "src/services/site/site.model";
import siteService from "src/services/site/site.service";
import { withAuthAndAuthRequired } from "../../hooks/withAuthorisationRequired";

const SiteIndexPage = () => {
  const navigate = useNavigate();

  return (
    <Page title="">
      <Helmet title="Locations" />
      <SearchTable<SiteModel>
        caption="Locations"
        action={
          <div>
            <ButtonLink variant="success" to="/site/create">
              Add location
            </ButtonLink>
          </div>
        }
        titles={{
          details: "Location",
          address: "Address",
          isActive: "Status",
          numberOfHubs: "Number of hubs",
        }}
        service={siteService}
        mappers={{
          details: (site) => (
            <>
              <Row>
                <Col sm="auto">
                  <Image
                    src={site.featuredMedia?.url}
                    roundedCircle
                    width="50px"
                    height="50px"
                  />
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <strong>{site.name}</strong>
                    </Col>
                  </Row>
                  <Row>
                    <Col>{site.summary}</Col>
                  </Row>
                </Col>
              </Row>
            </>
          ),
          numberOfHubs: (site) => site.hubs?.length,
          address: (site) => site.addressLine1,
          isActive: (site) => (
            <Badge bg={site.isActive ? "success" : "secondary"}>
              {site.isActive ? "Active" : "Disabled"}
            </Badge>
          ),
        }}
        onSelectRow={(site) => navigate(`/site/${site.id}`)}
      />
    </Page>
  );
};

export default withAuthAndAuthRequired(SiteIndexPage);
