import { BaseService } from "../baseService";
import { Lock, LockListItem, LockListItemSearchResults } from "./lock.model";
import {
  decodeSearch,
  PaginationResultDto,
  SearchableService,
  SearchProps,
} from "../searchableService";

class LockService
  extends BaseService<Lock>
  implements SearchableService<LockListItem>
{
  constructor() {
    super(Lock, "/lock");
  }

  async getList() {
    return this._getArray("/");
  }

  async get(id: number) {
    return this._getParsed(`/${id}`);
  }

  async search(
    search: SearchProps
  ): Promise<PaginationResultDto<LockListItem> | undefined> {
    const params = decodeSearch(search);

    return (
      await this._getParsed(`/search?${params}`, {}, LockListItemSearchResults)
    ).data;
  }

  searchFilters(): { [p: string]: string } | undefined {
    return undefined;
  }

  unlock(lockId: number) {
    return this._getUnparsed(`/unlock/${lockId}`);
  }
}

const lockService = new LockService();
export default lockService;
