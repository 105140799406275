import React from "react";
import { Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Page from "src/components/page";
import { withAuthAndAuthRequired } from "src/hooks/withAuthorisationRequired";
import SearchTable from "../../components/searchTable";
import { MembershipAccountDto } from "../../services/membershipAccount/membershipAccount.dto";
import membershipAccountService from "../../services/membershipAccount/membershipAccount.service";
import { SubscriptionStatusBadge } from "../../components/badges/subscription.status.badge";
import { SearchProps } from "../../services/searchableService";
import FilterCard from "../../components/filterCard";
import useAsyncRetry from "../../hooks/useAsyncRetry";
import membershipService from "../../services/membership/membership.service";
import DateFormat, { DateFormats } from "../../components/DateFormat";
import { useSearchParams } from "react-router-dom";

const MembershipStatusFilters = ({
  searchParams,
  setSearchParams,
  initialMembership = undefined,
}: {
  searchParams: SearchProps;
  setSearchParams: React.Dispatch<React.SetStateAction<SearchProps>>;
  initialMembership?: string;
}) => {
  const { value: allMembershipNames } = useAsyncRetry(async () => {
    const result = (await membershipService.findAll()).map(({ name, id }) => [
      id,
      name,
    ]) as [number, string][];
    result.sort((a, b) => a[1].localeCompare(b[1]));
    return result;
  });

  React.useEffect(() => {
    if (initialMembership)
      setSearchParams((current) => ({
        ...current,
        filter: `membership=${initialMembership}`,
      }));
  }, [initialMembership, setSearchParams]);

  return (
    <Row>
      <FilterCard
        filterName="membership"
        filterTitle={"Membership"}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        type="select"
        selectValues={allMembershipNames}
      />
      <FilterCard
        filterName="status"
        filterTitle={"Status"}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        type="select"
        selectValues={[
          ["active", "Active"],
          ["canceled", "Cancelled"],
          ["trialing", "Trialing"],
        ]}
      />
      <FilterCard
        filterName="started"
        filterTitle={"When started"}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        type="select"
        selectValues={[
          ["lastWeek", "Within the last week"],
          ["lastFortnight", "Within the last fortnight"],
          ["lastMonth", "Within the last month"],
        ]}
      />
    </Row>
  );
};

const MembershipAccountIndexPage = () => {
  const [params] = useSearchParams();

  return (
    <Page>
      <Helmet title="Membership" />

      <SearchTable<MembershipAccountDto>
        caption={<>Subscriptions</>}
        service={membershipAccountService}
        useWrapper={true}
        showFilters={false}
        sortableColumns={["account", "status", "membership", "started"]}
        titles={{
          account: "Account",
          status: "Status",
          membership: "Membership",
          started: "Started",
        }}
        showSearch={false}
        mappers={{
          account: (m) => <>{m.account?.displayName}</>,
          status: (m) => {
            return <SubscriptionStatusBadge subscription={m} />;
          },
          membership: (m) => <>{m.membership?.name}</>,
          started: (m) => (
            <>
              <DateFormat
                date={m.currentPeriodStart}
                format={DateFormats.date}
              />
            </>
          ),
        }}
        beforeTableRender={({ searchParams, setSearchParams }) => (
          <MembershipStatusFilters
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            initialMembership={params.get("membership") || undefined}
          />
        )}
      />
    </Page>
  );
};

export default withAuthAndAuthRequired(MembershipAccountIndexPage);
