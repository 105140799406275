import { Auth0Provider } from "@auth0/auth0-react";
import React, { PropsWithChildren } from "react";

const domain =
  process.env.REACT_APP_AUTH0_DOMAIN || "workfromhubs-dev.eu.auth0.com";
const clientId =
  process.env.REACT_APP_AUTH0_CLIENT || "PJUVdueqCafEx0aes9BQEAXak2zPVyGH";

const audience =
  process.env.REACT_APP_AUTH0_AUDIENCE || "https://localhost:8080";

export const Auth0ContextProvider = ({
  children,
}: PropsWithChildren<any>): JSX.Element | null => {
  if (!(domain && clientId)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      audience={audience}
    >
      {children}
    </Auth0Provider>
  );
};
