import { css, cx } from "@emotion/css";
import { Formik, FormikHelpers } from "formik";
import L, { LatLngExpression } from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import "leaflet/dist/leaflet.css";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { MdOutlineCancel, MdOutlineEdit, MdOutlineSave } from "react-icons/md";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import DraggableMarker from "src/components/draggableMarker";
import { FormFieldControl } from "src/components/formFieldControl";
import { HubModel } from "src/services/hub/hub.model";
import * as Yup from "yup";
import { Optional } from "../../types/properties";

const markerIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconAnchor: [12.5, 41],
});

const markerIcon2 = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconAnchor: [12.5, 41],
  className: css`
    filter: hue-rotate(60deg);
  `,
});

export interface HubLocationForm {
  latitude: number;
  longitude: number;
}

const SiteLocationFormSchema = Yup.object().shape({
  latitude: Yup.number().required("A latitude is required"),
  longitude: Yup.number().required("A longitude is required"),
});

export const HubLocation = ({
  hub,
  onSubmit,
}: {
  hub: Optional<HubModel, "id">;
  onSubmit: (values: HubLocationForm) => Promise<void>;
}) => {
  const [lastLocation, setLastLocation] = React.useState<[number, number]>([
    0, 0,
  ]);

  const location = React.useMemo<HubLocationForm>(() => {
    return {
      latitude: hub.latitude,
      longitude: hub.longitude,
    };
  }, [hub.latitude, hub.longitude]);

  const [isEditing, setIsEditing] = React.useState(false);

  const mapRef = React.useRef<L.Map>();

  const updateMapCenter = React.useCallback((latlng: LatLngExpression) => {
    if (mapRef.current) {
      mapRef.current.flyTo(latlng);
    }
  }, []);

  React.useEffect(() => {
    if (
      lastLocation[0] !== location.latitude ||
      lastLocation[1] !== location.longitude
    ) {
      updateMapCenter([location.latitude, location.longitude]);
      setLastLocation([location.latitude, location.longitude]);
    }
  }, [lastLocation, location, updateMapCenter]);

  const _onSubmit = async (
    values: HubLocationForm,
    { setSubmitting }: FormikHelpers<HubLocationForm>
  ) => {
    setSubmitting(true);
    await onSubmit(values);
    await updateMapCenter([values.latitude, values.longitude]);
    setSubmitting(false);
    setIsEditing(false);
  };

  const cancel = (values: HubLocationForm) => {
    updateMapCenter([values.latitude, values.longitude]);
    setIsEditing(false);
  };

  return (
    <>
      <Formik<HubLocationForm>
        validationSchema={SiteLocationFormSchema}
        enableReinitialize
        onSubmit={_onSubmit}
        initialValues={{ ...location }}
      >
        {({
          handleSubmit,
          values,
          setFieldValue,
          handleReset,
          resetForm,
          submitForm,
          isSubmitting,
        }) => (
          <>
            <Row>
              <Col sm="auto">
                <h2 className="h4">Location map</h2>
              </Col>
              <Col>
                {isEditing ? (
                  <>
                    <MdOutlineSave
                      onClick={() => {
                        if (!isSubmitting) submitForm();
                      }}
                      title="Save"
                      role="button"
                      className={cx("h2", isSubmitting ? "text-muted" : "")}
                    />
                    <MdOutlineCancel
                      onClick={() => {
                        if (!isSubmitting) {
                          resetForm();
                          cancel(location);
                        }
                      }}
                      title="Cancel"
                      role="button"
                      className={cx("h2", isSubmitting ? "text-muted" : "")}
                    />
                  </>
                ) : (
                  <>
                    <MdOutlineEdit
                      role="button"
                      onClick={() => setIsEditing(true)}
                      className="h5"
                    />
                  </>
                )}
              </Col>
            </Row>

            <Row
              className={css`
                height: 400px;
              `}
            >
              <Col
                className={css`
                  display: flex;
                `}
              >
                <MapContainer
                  whenCreated={(map) => (mapRef.current = map)}
                  center={[location.latitude, location.longitude]}
                  zoom={16}
                  placeholder={
                    <img
                      src="https://via.placeholder.com/728x400.png?text=Location map"
                      alt="Location map"
                      className="w-100"
                    />
                  }
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />

                  {hub.site && (
                    <Marker
                      position={[hub.site.latitude, hub.site.longitude]}
                      icon={markerIcon}
                      title={hub.site.name}
                      zIndexOffset={-1}
                    />
                  )}

                  <DraggableMarker
                    center={[values.latitude, values.longitude]}
                    onDragEnd={(latlng) => {
                      setFieldValue("latitude", latlng.lat);
                      setFieldValue("longitude", latlng.lng);
                    }}
                    onDragging={(latlng) => {
                      setFieldValue("latitude", latlng.lat);
                      setFieldValue("longitude", latlng.lng);
                    }}
                    icon={markerIcon2}
                    draggable={isEditing}
                    title={hub.name}
                  />
                </MapContainer>
              </Col>
            </Row>
            {isEditing ? (
              <form onSubmit={handleSubmit} onReset={handleReset}>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <h3 className="h5 mt-2">Map coordinates</h3>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormFieldControl
                          fieldName="latitude"
                          placeholder="Latitude"
                          label="Latitude"
                          showLabel
                          type="number"
                        />
                        <FormFieldControl
                          fieldName="longitude"
                          placeholder="Longitude"
                          label="Longitude"
                          showLabel
                          type="number"
                          className="mt-3"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </form>
            ) : (
              <>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <h3 className="h5 mt-2">Map coordinates</h3>
                      </Col>
                    </Row>
                    <Row>
                      <dl>
                        <dt>Latitude</dt>
                        <dd>{hub.latitude}</dd>
                        <dt>Longitude</dt>
                        <dd>{hub.longitude}</dd>
                      </dl>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </Formik>
    </>
  );
};
