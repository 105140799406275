import React from "react";
import ReactDOM from "react-dom";
import "reflect-metadata";
import { Auth0ContextProvider } from "src/context/Auth0ProviderContext";
import AxiosProvider from "src/context/AxiosContext";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { UserContextProvider } from "./context/UserAccountContext";

ReactDOM.render(
  <React.StrictMode>
    <Auth0ContextProvider>
      <AxiosProvider>
        <UserContextProvider>
          <App />
        </UserContextProvider>
      </AxiosProvider>
    </Auth0ContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
