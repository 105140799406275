import React from "react";
import { Alert, Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { HubModel } from "../../services/hub/hub.model";
import InlineTextEdit from "../../components/inlineTextEdit";
import tryAsyncToast from "../../hooks/tryAsyncToast";
import hubService from "../../services/hub/hub.service";
import LockDetails from "src/components/lockDetails";
import SearchTable from "../../components/searchTable";
import lockService from "../../services/lock/lock.service";
import { LockListItem } from "../../services/lock/lock.model";
import { HubDto } from "../../services/hub/hub.dto";

export const HubServicesTab = ({
  hub,
  setValue,
}: {
  hub: HubModel;
  setValue: (value: HubModel | undefined) => void;
}) => {
  const updateHub = React.useCallback(
    (partialHub: Partial<HubModel>) => {
      if (partialHub === undefined) return;

      setValue({
        ...hub,
        ...partialHub,
      } as HubModel);
    },
    [setValue, hub]
  );

  const patchHub = React.useCallback(
    async (partialHub: Partial<HubModel>, success: string) => {
      if (hub.id !== undefined) {
        await tryAsyncToast(hubService.update(hub.id, partialHub), {
          success,
        });
      }

      updateHub(partialHub);
    },
    [hub, updateHub]
  );

  const [isAssigningLock, setIsAssigningLock] = React.useState(false);

  const [showAssignDialog, setShowAssignDialog] = React.useState(false);
  const [existingLockHub, setExistingLockHub] =
    React.useState<Partial<HubDto>>();
  const [lockIdRequiringPermission, setLockIdRequringPermission] =
    React.useState(-1);

  const assignLock = React.useCallback(
    async (lockId, replaceLock = false) => {
      return tryAsyncToast(
        async () => {
          setShowAssignDialog(false);
          setLockIdRequringPermission(-1);
          setExistingLockHub(undefined);

          const partialHub = await hubService.updateLockId(hub.id, {
            lockId,
            replaceLock,
          });
          setShowAssignDialog(false);
          setIsAssigningLock(false);
          updateHub({ id: partialHub.id, lockId: partialHub.lockId });
        },
        {
          success: "Lock assigned to hub",
          error: (err: any) => {
            if (err.response?.data?.message === "alreadyAssigned") {
              setLockIdRequringPermission(lockId);
              setShowAssignDialog(true);
              setExistingLockHub(err.response.data.hub);
              return undefined;
            }
            return err;
          },
        }
      );
    },
    [hub.id, updateHub]
  );

  const unassignLock = React.useCallback(async () => {
    return tryAsyncToast(
      async () => {
        const result = await hubService.updateLockId(hub.id, { lockId: null });
        updateHub({ lockId: result.lockId });
      },
      {
        success: "Lock removed",
      }
    );
  }, [hub.id, updateHub]);

  return (
    <>
      <Row className="tabContainer">
        <Col sm={6}>
          <Row>
            <Col>
              <InlineTextEdit
                fieldTitle="Wifi Network (SSID)"
                displayValue={
                  hub.wifiNetwork === undefined || hub.wifiNetwork === ""
                    ? "not set"
                    : hub.wifiNetwork
                }
                value={hub.wifiNetwork}
                onSubmit={(wifiNetwork) =>
                  patchHub({ wifiNetwork }, "Wifi Network updated")
                }
                placeHolder="Wifi Network"
              />
              <InlineTextEdit
                fieldTitle="Wifi Password"
                displayValue={
                  hub.wifiPassword === undefined || hub.wifiPassword === ""
                    ? "not set"
                    : hub.wifiPassword
                }
                value={hub.wifiPassword}
                onSubmit={(wifiPassword) =>
                  patchHub({ wifiPassword }, "Wifi Password updated")
                }
                placeHolder="Wifi Password"
              />
            </Col>
          </Row>
        </Col>
        <Col sm={6}>
          <h4 className="mt-5">Lock management</h4>

          <Card className="mb-4">
            {hub.lockId ? (
              <>
                <Card.Header as="h5">Currently assigned lock</Card.Header>
                <Card.Body>
                  <LockDetails lockId={hub.lockId} />
                </Card.Body>
                <Card.Footer className="d-flex gap-3">
                  <Button variant="danger" onClick={() => unassignLock()}>
                    Unassign Lock
                  </Button>
                  {
                    <Button
                      variant="secondary"
                      onClick={async () =>
                        tryAsyncToast(hubService.unlock(hub.id), {
                          success: "Unlocked",
                        })
                      }
                    >
                      Unlock
                    </Button>
                  }
                  <Form.Check
                    checked={hub.allowRemoteUnlocking}
                    size={4}
                    onChange={async (e) => {
                      const response = await tryAsyncToast(
                        hubService.update(hub.id, {
                          allowRemoteUnlocking: e.target.checked,
                        }),
                        { success: "Lock updated" }
                      );

                      setValue({
                        ...hub,
                        allowRemoteUnlocking: response.allowRemoteUnlocking,
                      });
                    }}
                    label="Allow remote unlocking"
                    title="Allow remote unlocking by non-admins"
                  />
                </Card.Footer>
              </>
            ) : (
              <Alert variant="warning" className="m-3">
                There is currently no assigned lock
              </Alert>
            )}
          </Card>

          <Row>
            <Col>
              {isAssigningLock ? (
                <Card>
                  <Card.Header as="h5">Select the lock to assign</Card.Header>

                  <Card.Body>
                    <SearchTable<LockListItem>
                      titles={{
                        lockId: "Id",
                        lockName: "Name",
                        lockAlias: "Alias",
                        status: "",
                      }}
                      mappers={{
                        status: (item) =>
                          item.lockId === hub.lockId ? (
                            <div style={{ textAlign: "right" }}>
                              <strong>Assigned</strong>
                            </div>
                          ) : (
                            <div style={{ textAlign: "right" }}>
                              <Button
                                variant="success"
                                onClick={() => assignLock(item.lockId)}
                              >
                                Assign this lock
                              </Button>
                            </div>
                          ),
                      }}
                      service={lockService}
                      canSearch={false}
                    />
                  </Card.Body>

                  <Card.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => setIsAssigningLock(false)}
                    >
                      Cancel
                    </Button>
                  </Card.Footer>
                </Card>
              ) : (
                <Button
                  variant="success"
                  size="lg"
                  onClick={() => setIsAssigningLock(true)}
                >
                  Assign lock
                </Button>
              )}

              <Modal show={showAssignDialog}>
                <Modal.Header>
                  <Modal.Title>Confirm lock reassignment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>The selected lock has been assigned to the hub</p>
                  <p>
                    <em>{existingLockHub?.name}</em>
                  </p>
                  <p>Are you sure you wish to reassign it to this hub?</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowAssignDialog(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => assignLock(lockIdRequiringPermission, true)}
                  >
                    Reassign lock
                  </Button>
                </Modal.Footer>
              </Modal>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
