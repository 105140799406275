import { BaseService } from "src/services/baseService";
import {
  CreateDiscountDto,
  DiscountModel,
  DiscountModelSearchResults,
} from "./discount.model";
import { DiscountStatisticsModel } from "./discountStatistics.model";
import {
  decodeSearch,
  PaginationResultDto,
  SearchableService,
  SearchProps,
} from "../searchableService";

class DiscountService
  extends BaseService<DiscountModel>
  implements SearchableService<DiscountModel>
{
  constructor() {
    super(DiscountModel, "/discount");
  }

  async findAll(): Promise<DiscountModel[]> {
    return (await this._getArray("/")).data;
  }

  async findById(id: string | number): Promise<DiscountModel> {
    return (await this._getParsed(`/${id}`)).data;
  }

  public async create(discount: CreateDiscountDto) {
    const response = await this._post("", discount);
    return response.data;
  }

  public async delete(id: number): Promise<DiscountModel> {
    return (await this._delete(`/${id}`)).data;
  }

  public async deactivate(id: number) {
    let discount = new DiscountModel();
    discount.id = id;
    discount.endDate = new Date();
    return await this.update(id, discount);
  }

  async update(id: number, discount: Partial<DiscountModel>) {
    const response = await this._patch(`/${id}`, discount);
    return response.data;
  }

  async statistics(): Promise<DiscountStatisticsModel | undefined> {
    return await (
      await this._getUnparsed("/statistics")
    ).data;
  }

  async search(
    search: SearchProps
  ): Promise<PaginationResultDto<DiscountModel> | undefined> {
    const params = decodeSearch(search);

    return (
      await this._getParsed(`/search?${params}`, {}, DiscountModelSearchResults)
    ).data;
  }

  searchFilters(): { [p: string]: string } | undefined {
    return {
      active: "Active",
      scheduled: "Scheduled",
      expired: "Expired",
    };
  }
}

const discountService = new DiscountService();

export default discountService;
