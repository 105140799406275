import React from "react";
import { Button, Col, Container, Image, Row, Stack } from "react-bootstrap";
import Logo from "src/images/wfh-logo-text-white.png";
import { useAuth0 } from "@auth0/auth0-react";

const NotAuthorisedPage = () => {
  const { logout } = useAuth0();
  return (
    <Container fluid className="bg-dark vh-100">
      <Row className="justify-content-md-center">
        <Col md="auto" className="">
          <Stack gap={3}>
            <Image src={Logo} width="160px" className="ms-3  p-4" />
          </Stack>
          <Stack gap={3} className="mx-auto">
            <p className="text-white" data-testid="loading">
              You are not authorised to view this page
            </p>
            <p>
              <Button
                onClick={() => logout({ returnTo: window.location.origin })}
              >
                Logout
              </Button>
            </p>
          </Stack>
        </Col>
      </Row>
    </Container>
  );
};

export default NotAuthorisedPage;
