import React, { ReactNode } from "react";
import { Card, Col, Row, Spinner, Table } from "react-bootstrap";
import { css, cx } from "@emotion/css";

export type KeyType<T> = keyof T | string;

export type TableRowType<T> = {
  [key in KeyType<T>]: string | ReactNode;
};

export type TableRowMapper<T> = {
  [key in KeyType<T>]: (row: T) => string | ReactNode;
};

const CardWrapper = ({ children }: { children?: React.ReactNode }) => (
  <Card>
    <Card.Body>{children}</Card.Body>
  </Card>
);

const NullWrapper = ({ children }: { children: React.ReactNode }) => (
  <>{children}</>
);

export interface DynamicTableProps<T> {
  rows?: T[];
  caption?: string | ReactNode;
  extraKeys?: string[];

  titles: TableRowType<T>;

  mappers?: TableRowMapper<T>;
  onSelectRow?: (row: T) => void;
  onClickTitle?: (title: KeyType<T>) => void;

  header?: React.ReactNode;
  footer?: React.ReactNode;
  tableFooter?: React.ReactNode;
  useWrapper?: boolean;
  isLoading?: boolean;

  rowIndex?: string;

  noResults?: ReactNode | ((rows: T[] | undefined) => ReactNode);
}

const DynamicTable = function <T>({
  rows,
  caption,
  extraKeys = undefined,
  titles,
  mappers = undefined,
  onSelectRow = undefined,
  onClickTitle = undefined,
  header = undefined,
  footer = undefined,
  tableFooter = undefined,
  useWrapper = true,
  isLoading = false,
  rowIndex = "id",
  noResults = undefined,
}: DynamicTableProps<T>) {
  const displayKeys = React.useMemo(
    () => Object.keys(titles) as KeyType<T>[],
    [titles]
  );

  const processedKeys = React.useMemo(() => {
    return [...new Set(["id", ...displayKeys, ...(extraKeys || [])])];
  }, [displayKeys, extraKeys]);

  const processHeader = React.useCallback(
    (row: TableRowType<T>) => {
      return displayKeys.reduce(
        (processed, key) => ({
          ...processed,
          [key]: row[key],
        }),
        {} as TableRowType<T>
      );
    },
    [displayKeys]
  );

  const mapRow = React.useCallback(
    (row: T) => {
      const mapKey = (key: KeyType<T>) => {
        if (mappers === undefined || mappers[key] === undefined)
          return row[key as keyof T];
        return mappers[key](row);
      };

      return processedKeys.reduce(
        (processed, key) => ({
          ...processed,
          [key]: mapKey(key),
        }),
        {} as TableRowType<T>
      );
    },
    [mappers, processedKeys]
  );

  const titleStrings = processHeader(titles);

  const Wrapper = useWrapper ? CardWrapper : NullWrapper;

  return (
    <>
      <Wrapper>
        {header && (
          <Row>
            <Col>{header}</Col>
          </Row>
        )}

        <div className="position-relative">
          {isLoading && (
            <div
              className={cx(
                css`
                  width: 100%;
                  height: 100%;
                  background-color: rgba(0, 0, 0, 0.25);
                  position: absolute;
                  display: flex;
                  align-items: center;
                  justify-content: space-around;
                  z-index: 100;
                `
              )}
            >
              <Spinner animation="border" className={css``}>
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
          <Table striped responsive hover>
            <thead>
              <tr>
                {displayKeys.map((key, idx) => (
                  <th
                    key={idx}
                    className="border-0"
                    onClick={() => onClickTitle?.(key)}
                  >
                    {titleStrings[key]}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="border-top-0">
              {rows && (
                <>
                  {rows.length === 0 ? (
                    <tr>
                      <td
                        colSpan={displayKeys.length}
                        height="100px"
                        className="text-center align-middle"
                      >
                        {noResults !== undefined ? (
                          typeof noResults === "function" ? (
                            noResults(rows)
                          ) : (
                            noResults
                          )
                        ) : (
                          <div className="alert alert-primary">
                            There are no results
                          </div>
                        )}
                      </td>
                    </tr>
                  ) : (
                    rows
                      .map((row) => ({
                        row,
                        processedRow: mapRow(row),
                      }))
                      .map(({ row, processedRow }) => (
                        <tr
                          key={processedRow[rowIndex] as string}
                          onClick={() => onSelectRow?.(row)}
                          role="button"
                        >
                          {displayKeys.map((key, colIdx) => (
                            <td key={colIdx} className="align-middle">
                              {processedRow[key]}
                            </td>
                          ))}
                        </tr>
                      ))
                  )}
                </>
              )}
            </tbody>
            {tableFooter ? <tfoot>{tableFooter}</tfoot> : undefined}
          </Table>
        </div>
        {footer && (
          <Row>
            <Col>{footer}</Col>
          </Row>
        )}
      </Wrapper>
    </>
  );
};

export default DynamicTable;
