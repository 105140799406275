import { Formik } from "formik";
import React from "react";
import { TabProps } from "./index";
import { Button, Col, Container, Row } from "react-bootstrap";
import useAsyncRetry from "../../hooks/useAsyncRetry";
import checkoutService from "../../services/checkout/checkout.service";
import {
  errorMessage,
  FormFieldControl,
} from "../../components/formFieldControl";
import DynamicTable from "../../components/dynamicTable";
import {
  getMinutesOfDaySafe,
  getStartOfDaySafe,
} from "../../utils/dateFnsExtra";
import { validateDateTimeSelection } from "./validationUtils";
import { HubAllocationModel } from "../../services/hubAllocation/hubAllocation.model";
import { css } from "@emotion/css";
import moment from "src/types/momentWithLocale";
import DateFormat, { DateFormats } from "../../components/DateFormat";

export interface CheckoutTimeTabValues {
  startDateTime: Date;
  endDateTime: Date;
  outsideScheduledHoursConfirmed?: boolean;
}

interface CheckoutSelectTimeFormValues {
  selectedDate?: Date;
  startTime?: number;
  endTime?: number;

  startDateTime?: Date;
  endDateTime?: Date;

  selectedTime?: string;

  _force?: boolean;
}

const CheckoutSelectTimeForm = ({ context, updateContext }: TabProps) => {
  const onSubmit = React.useCallback(
    (values: CheckoutSelectTimeFormValues) => {
      const startDateTime = moment(values.selectedDate!)
        .utc(true)
        .add(values.startTime!, "minutes")
        .toDate();

      const endDateTime = moment(values.selectedDate!)
        .utc(true)
        .add(
          values.endTime! + (values.endTime! < values.startTime! ? 1440 : 0),
          "minutes"
        )
        .toDate();

      updateContext({
        ...context,
        selectTimeTabValues: {
          startDateTime,
          endDateTime,
          outsideScheduledHoursConfirmed: values._force,
        },
      });
    },
    [context, updateContext]
  );

  const [selectedDate, setSelectedDate] = React.useState<Date | undefined>(
    context.selectTimeTabValues?.startDateTime &&
      moment(context.selectTimeTabValues?.startDateTime).startOf("day").toDate()
  );

  const { value: availabilities } = useAsyncRetry(async () => {
    if (context.hubTabValues?.hub === undefined) return undefined;
    return checkoutService.getHubAvailabilities(context.hubTabValues.hub.id);
  }, [context.hubTabValues?.hub, context.accountTabValues?.account]);

  const { value: allocations } = useAsyncRetry(async () => {
    if (
      context.hubTabValues?.hub?.id === undefined ||
      selectedDate === undefined
    )
      return undefined;
    return checkoutService.getHubAllocations(
      context.hubTabValues?.hub.id,
      selectedDate
    );
  }, [selectedDate]);

  const [formWarnings, setFormWarnings] = React.useState<
    Partial<{ [k in keyof CheckoutSelectTimeFormValues]: string }>
  >({});

  const [overlappingAllocations, setOverlappingAllocations] = React.useState<
    HubAllocationModel[]
  >([]);

  const validate = React.useCallback(
    (values: CheckoutSelectTimeFormValues) => {
      const { newFormWarnings, errors, overlapping } =
        validateDateTimeSelection(values, availabilities, allocations);

      if (values.selectedDate && moment(values.selectedDate).isValid()) {
        setSelectedDate(values.selectedDate);
      } else {
        setSelectedDate(undefined);
      }

      if (values.startTime && values.endTime) {
        if (values.startTime >= values.endTime) {
          errors.selectedTime = "The start time must be before the end time";
        }
      } else {
        errors.selectedTime = "";
      }

      setFormWarnings(newFormWarnings);
      setOverlappingAllocations(overlapping);

      return errors;
    },
    [allocations, availabilities]
  );

  return context.accountTabValues ? (
    <>
      <Container fluid className="m-0 p-0">
        <Formik<CheckoutSelectTimeFormValues>
          onSubmit={onSubmit}
          initialValues={{
            selectedDate: getStartOfDaySafe(
              context.selectTimeTabValues?.startDateTime
            ),
            startTime: getMinutesOfDaySafe(
              context.selectTimeTabValues?.startDateTime
            ),
            endTime: getMinutesOfDaySafe(
              context.selectTimeTabValues?.endDateTime
            ),
          }}
          validate={validate}
          validateOnBlur
        >
          {({ submitForm, values, setFieldValue, errors, isValid }) => (
            <>
              <Container fluid className="m-0 p-0">
                <Row className="bg-white p-4">
                  <Col>
                    <fieldset>
                      <legend className="h4">
                        Select a date for the booking
                      </legend>
                      <p>
                        This <strong>cannot</strong> conflict with existing
                        bookings, but it <strong>can</strong> be outside of
                        normal hours for the hub, but you will need to confirm
                        this
                      </p>
                      <div style={{ width: "10rem" }}>
                        <FormFieldControl
                          fieldName="selectedDate"
                          type="date"
                          label="Day"
                          onChange={() => setFieldValue("_force", undefined)}
                        />
                      </div>

                      {formWarnings.selectedDate && (
                        <div className="mt-3 alert alert-warning d-inline-block">
                          {formWarnings.selectedDate}
                        </div>
                      )}
                    </fieldset>
                  </Col>
                </Row>
              </Container>

              {values.selectedDate && (
                <Container fluid className="m-0 p-0">
                  <Row className="bg-white p-4">
                    <Col>
                      <h3 className="fs-4 fw-normal">
                        Existing bookings on this day
                      </h3>

                      <DynamicTable<HubAllocationModel>
                        titles={{
                          startDateTime: "Start Time",
                          endDateTime: "End Time",
                          overlapping: "",
                        }}
                        rows={allocations}
                        mappers={{
                          startDateTime: ({ startDateTime }) => (
                            <DateFormat
                              format={DateFormats.time}
                              date={startDateTime}
                            />
                          ),
                          endDateTime: ({ endDateTime }) => (
                            <DateFormat
                              format={DateFormats.time}
                              date={endDateTime}
                            />
                          ),
                          overlapping: (row) => (
                            <>
                              {overlappingAllocations.some(
                                ({ id }) => row.id === id
                              ) && (
                                <span className={errorMessage}>Conflict</span>
                              )}
                            </>
                          ),
                        }}
                      />
                    </Col>
                  </Row>

                  <Row className="bg-white px-4">
                    <fieldset>
                      <legend>
                        Select the start and end time for the new booking
                        <br />
                        <em
                          className={css`
                            font-size: 1rem;
                          `}
                        >
                          Please select a time on the hour or half past and
                          allow 30 minutes either side of an existing booking
                        </em>
                      </legend>

                      <Row className="bg-white pt-3">
                        <Col md={1} style={{ minWidth: "10rem" }}>
                          <FormFieldControl
                            fieldName="startTime"
                            type="time"
                            label="Start Time"
                            onChange={() => setFieldValue("_force", undefined)}
                          />
                          {formWarnings.startTime && (
                            <div className="alert alert-warning">
                              {formWarnings.startTime}
                            </div>
                          )}
                        </Col>
                        <Col md={1} style={{ minWidth: "10rem" }}>
                          <FormFieldControl
                            fieldName="endTime"
                            type="time"
                            label="End Time"
                            onChange={() => setFieldValue("_force", undefined)}
                          />
                          {formWarnings.endTime && (
                            <div className="alert alert-warning">
                              {formWarnings.endTime}
                            </div>
                          )}
                        </Col>
                      </Row>
                    </fieldset>
                  </Row>
                  {errors.selectedTime && (
                    <Row className="bg-white px-4">
                      <Col>
                        <div className={errorMessage}>
                          {errors.selectedTime}
                        </div>
                      </Col>
                    </Row>
                  )}
                  {formWarnings.selectedTime && (
                    <Row className="bg-white px-4">
                      <Col>
                        <div>{formWarnings.selectedTime}</div>
                      </Col>
                    </Row>
                  )}
                  {(formWarnings.startTime ||
                    formWarnings.endTime ||
                    formWarnings.selectedDate ||
                    formWarnings.selectedTime) &&
                  values._force !== true ? (
                    <Row className="bg-white p-4">
                      <Col md={"auto"}>
                        <Button
                          className="mt-4"
                          variant="success"
                          size="lg"
                          disabled={!isValid}
                          onClick={() => setFieldValue("_force", true)}
                        >
                          Click to Confirm out-of-hours times
                        </Button>
                      </Col>
                    </Row>
                  ) : (
                    <Row className="bg-white p-4">
                      <Col md={"auto"}>
                        <Button
                          variant="success"
                          size="lg"
                          disabled={
                            errors.selectedTime !== undefined || !isValid
                          }
                          onClick={submitForm}
                        >
                          Next step
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Container>
              )}
            </>
          )}
        </Formik>
      </Container>
    </>
  ) : (
    <></>
  );
};

export default CheckoutSelectTimeForm;
