import React from "react";
import { TabProps } from "./index";
import { Alert, Card, Col, Container, Row } from "react-bootstrap";
import ButtonLink from "../../components/ButtonLink";

const ResultForm = ({ context, updateContext }: TabProps) => {
  const checkout = React.useMemo(
    () => context.confirmationForm?.checkoutResponse,
    [context.confirmationForm?.checkoutResponse]
  );

  return (
    <>
      {checkout && (
        <Container fluid className="m-0 p-0">
          <Row className="bg-white p-4 pb-0">
            <Col>
              <h3>The order has been created successfully</h3>
            </Col>
          </Row>

          <Row className="bg-white p-4">
            <Col>
              <Card>
                {checkout.emailStatus === "Success" && (
                  <Card.Body>
                    <Alert className="m-0">
                      As there was no cost associated with this order, a
                      confirmation email was successfully sent to the Account{" "}
                      {checkout.account.email}
                    </Alert>
                  </Card.Body>
                )}

                {checkout.emailStatus === "Failed" && (
                  <Card.Body>
                    <p>
                      An error occurred when attempt to send the confirmation
                      email to the account {checkout.account.email}
                    </p>
                    <p>This can be attempted again through the order page</p>
                  </Card.Body>
                )}

                {checkout.emailStatus === "Pending Stripe" && (
                  <Card.Body>
                    <p>
                      A payment email was sent via the Stripe API and
                      confirmation of the booking will be sent when the payment
                      is successfully received.
                    </p>
                  </Card.Body>
                )}

                {checkout.emailStatus === "NA" && (
                  <Card.Body>
                    <p>
                      There was no email directly generated for this order, as
                      it is expected to be handled outside the system.
                    </p>
                    <p>
                      If you wish to generate a confirmation manually, this can
                      be done on the order page.
                    </p>
                  </Card.Body>
                )}
              </Card>
            </Col>
          </Row>

          <Row className="bg-white p-4">
            <Col>
              <ButtonLink
                variant="success"
                size="lg"
                to={`/order/${checkout.order.id}`}
              >
                View Order
              </ButtonLink>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default ResultForm;
