import React from "react";
import useAsyncRetry from "../hooks/useAsyncRetry";
import lockService from "../services/lock/lock.service";
import { Col, Row } from "react-bootstrap";

const LockDetails = ({ lockId }: { lockId: number }) => {
  const { value: lock, isLoading } = useAsyncRetry(async () => {
    const result = await lockService.get(lockId);

    return result.data;
  });

  return (
    <>
      {isLoading ? (
        <>Loading data from TTLock API</>
      ) : (
        <>
          {lock && (
            <>
              <Row>
                <Col>id</Col>
                <Col>{lock?.lockId}</Col>
              </Row>
              <Row>
                <Col>name</Col>
                <Col>{lock?.lockName}</Col>
              </Row>
              <Row>
                <Col>alias</Col>
                <Col>{lock?.lockAlias}</Col>
              </Row>
            </>
          )}
        </>
      )}
    </>
  );
};

export default LockDetails;
