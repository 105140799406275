import { Formik } from "formik";
import React from "react";
import { TabProps } from "./index";
import { HubModel } from "../../services/hub/hub.model";
import useAsyncRetry from "../../hooks/useAsyncRetry";
import siteService from "../../services/site/site.service";
import { SiteModel } from "../../services/site/site.model";
import { FormFieldControl } from "../../components/formFieldControl";
import { Button, Col, Row } from "react-bootstrap";

export interface CheckoutHubTabValues {
  hub: HubModel;
}

interface CheckoutHubFormValues {
  hub?: HubModel;
  site?: SiteModel;
  hubId?: string;
  siteId?: string;
}

const CheckoutHubForm = ({ context, updateContext }: TabProps) => {
  const { value: sites } = useAsyncRetry(async () => {
    return await siteService.findAll();
  }, []);

  return (
    <Formik<CheckoutHubFormValues>
      onSubmit={(hubFormValues) => {
        hubFormValues.hub!.site = hubFormValues.site;
        updateContext({
          ...context,
          hubTabValues: {
            hub: hubFormValues.hub!,
          },
        });
      }}
      initialValues={{
        hubId: `${context.hubTabValues?.hub?.id}`,
      }}
    >
      {({ values, setFieldValue, submitForm }) => (
        <>
          <Row className="bg-white p-4">
            <Col md="auto">
              <fieldset>
                <legend className="mb-4 h4">Select a site and hub</legend>
                <FormFieldControl
                  className="mb-4"
                  fieldName="siteId"
                  label="Site"
                  type="select"
                  onChange={(value) => {
                    setFieldValue(
                      "site",
                      sites?.find(({ id }) => `${id}` === value)
                    );
                  }}
                >
                  <option value="">Select site</option>
                  {sites?.map((site) => (
                    <option value={site.id}>{site.name}</option>
                  ))}
                </FormFieldControl>

                <FormFieldControl
                  fieldName="hubId"
                  label="Hub"
                  type="select"
                  disabled={values.site === undefined}
                  onChange={(value) => {
                    setFieldValue(
                      "hub",
                      values.site?.hubs?.find(({ id }) => `${id}` === value)
                    );
                  }}
                >
                  <option value="">Select hub</option>
                  {values.site?.hubs?.map((hub) => (
                    <option value={hub.id}>{hub.name}</option>
                  ))}
                </FormFieldControl>
              </fieldset>
            </Col>
          </Row>

          <Row className="bg-white p-4">
            <Col>
              <Button
                variant="success"
                size="lg"
                disabled={values.hub === undefined}
                onClick={submitForm}
              >
                Next Step
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Formik>
  );
};

export default CheckoutHubForm;
