import { css, cx } from "@emotion/css";
import React from "react";
import { Image } from "react-bootstrap";

const ProfileImage = ({ url }: { url?: string }) => {
  return (
    <Image
      src={
        url
          ? url
          : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
      }
      roundedCircle
      width="50px"
      height="50px"
      referrerPolicy="no-referrer"
      className={cx(
        css`
          box-shadow: 0 0 1px 1px black;
        `,
        "bg-info"
      )}
    />
  );
};

export default ProfileImage;
