import Axios, { AxiosInstance } from "axios";

const axios: AxiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_API_BASE || "http://localhost:3001",
});

export const authorizeApiClient = (accessToken: string) => {
  axios.interceptors.request.use((config) => {
    if (config.headers) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  });
};

export default axios;
