import React from "react";

const dateOrdinals = {
  one: "st",
  two: "nd",
  few: "rd",
  many: "th",
  zero: "th",
  other: "th",
};

export const PluralFormats = {
  default: new Intl.PluralRules("en-GB", { type: "cardinal" }),
  ordinal: new Intl.PluralRules("en-GB", { type: "ordinal" }),
  date: {
    select: (value: number) => {
      const ordinalSuffix = PluralFormats.ordinal.select(value);
      return dateOrdinals[ordinalSuffix];
    },
  },
};

const PluralFormat = ({
  value,
  format = PluralFormats["default"],
}: {
  value: number;
  format?: { select: (n: number) => string };
}) => {
  return <>{format.select(value)}</>;
};

export default PluralFormat;
