export const htmlTime = {
  toValue: (source: string) =>
    source
      .split(":")
      .map((_) => parseInt(_, 10))
      .reduce((cur, next) => cur * 60 + next),
  toString: (value: unknown) => {
    return value === undefined
      ? ""
      : typeof value === "number"
      ? `${Math.floor(value / 60)}`.padStart(2, "0") +
        ":" +
        `${value % 60}`.padStart(2, "0")
      : (value as string);
  },
};
