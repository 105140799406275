import { HubDto } from "../hub/hub.dto";
import { AccountDto } from "../account/account.dto";

export enum HubAllocationType {
  Reserved = 0,
  Booked = 1,
  Cleaning = 2,
  Maintenance = 3,
  OutOfOrder = 4,
}

export const HubAllocationTypeMap = {
  0: "Reserved",
  1: "Booked",
  2: "Cleaning",
  3: "Maintenance",
  4: "OutOfOrder",
};

export interface HubAllocationDto {
  id: number;

  type: HubAllocationType;

  startDateTime: Date;

  endDateTime: Date;

  allocationTimeout: Date;

  hub: HubDto;

  hubId: number;

  createdBy: AccountDto;

  createdById: number;
}
