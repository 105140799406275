import React from "react";
import { Button, Card, Form, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { MdOutlineAssignmentInd } from "react-icons/md";
import ButtonLink from "src/components/ButtonLink";
import Page from "src/components/page";
import { withAuthAndAuthRequired } from "src/hooks/withAuthorisationRequired";
import useAsyncRetry from "src/hooks/useAsyncRetry";
import membershipService from "src/services/membership/membership.service";
import { useNavigate } from "react-router-dom";
import SearchTable from "../../components/searchTable";
import { MembershipStatisticsModel } from "../../services/membership/membershipStatistics.model";
import { SearchProps } from "../../services/searchableService";
import FilterCard from "../../components/filterCard";
import MoneyFormat from "../../components/MoneyFormat";
import { MembershipDto } from "../../services/membership/membership.dto";
import ModalButton from "../../components/modalButton";
import { useAuth0 } from "@auth0/auth0-react";

const NoResultsDisplay = () => {
  return (
    <Card className="text-center">
      <Card.Body className="py-5">
        <MdOutlineAssignmentInd size="5em" />
        <h3>Manage membership plans</h3>
        <p>Create membership plans that customers can subscribe to.</p>
        <ButtonLink variant="success" to="/membership/create">
          Create membership plan
        </ButtonLink>
      </Card.Body>
    </Card>
  );
};

const MembershipFilters = ({
  statistics,
  searchParams,
  setSearchParams,
}: {
  statistics?: MembershipStatisticsModel;
  searchParams: SearchProps;
  setSearchParams: React.Dispatch<React.SetStateAction<SearchProps>>;
}) => {
  return (
    <Row>
      <FilterCard
        filterName="activeMembers"
        filterTitle={"Active Members"}
        filterValue={statistics?.activeMembers}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />
      <FilterCard
        filterName="cancelledMembers"
        filterTitle={"Cancelled Members"}
        filterValue={statistics?.cancelledMembers}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />
      <FilterCard
        filterName="revenue"
        filterTitle={"Monthly Revenue"}
        filterValue={
          statistics && <MoneyFormat value={statistics.monthlyRevenue} />
        }
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        canFilter={false}
      />
      <FilterCard
        filterName="activeMembershipPlans"
        filterTitle={"Active Membership Plans"}
        filterValue={statistics?.activeMembershipPlans}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        canFilter={false}
      />
    </Row>
  );
};

const ExportToCsvModal = () => {
  const auth0 = useAuth0();

  const exportToCsv = async (event: any) => {
    const token = await auth0.getAccessTokenSilently();
    window.location.assign(
      `${
        process.env.REACT_APP_API_BASE || "http://localhost:3001"
      }/membership/csv?auth_token=${token}`
    );
  };

  return (
    <ModalButton
      variant="outline-secondary"
      title="Export Memberships"
      text="Export"
      footer={({ hideModal }) => (
        <>
          <Button onClick={hideModal} variant="outline-secondary">
            Cancel
          </Button>
          <Button variant="success" onClick={exportToCsv}>
            Export Memberships
          </Button>
        </>
      )}
    >
      <h6>Export</h6>
      <Form.Group key="export" className="mb-3">
        <Form.Check
          defaultChecked
          type="radio"
          id="export-all"
          name="export"
          label={`All memberships`}
        />
      </Form.Group>

      <h6>Export as</h6>
      <Form.Group key="export-type" className="mb-3">
        <Form.Check
          defaultChecked
          type="radio"
          id="export-type-csv"
          name="export-type"
          label={`Plain CSV file`}
        />
      </Form.Group>
    </ModalButton>
  );
};

const MembershipIndexPage = () => {
  const { value: statistics } = useAsyncRetry(async () => {
    return membershipService.statistics();
  }, []);
  const navigate = useNavigate();
  return (
    <Page>
      <Helmet title="Membership" />

      <SearchTable<MembershipDto>
        caption={<>Membership</>}
        service={membershipService}
        useWrapper={true}
        showFilters={false}
        sortableColumns={[
          "id",
          "title",
          "numberOfAccounts",
          "cancelledMembers",
          "revenue",
        ]}
        titles={{
          id: "#",
          title: "Title",
          numberOfAccounts: "Active Members",
          cancelledMembers: "Cancelled Members",
          revenue: "Revenue",
          subscriptions: "",
        }}
        action={
          <>
            <div>
              <ExportToCsvModal />
            </div>
            <div className="vr" />
            <div>
              <ButtonLink variant="success" to="/membership/create">
                Create membership plan
              </ButtonLink>
            </div>
          </>
        }
        beforeTableRender={({ searchParams, setSearchParams }) => (
          <MembershipFilters
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            statistics={statistics}
          />
        )}
        mappers={{
          name: (membership) => <>{membership.id}</>,
          title: (membership) => (
            <>
              <b>{membership.name}</b>
              <br />
              <span>
                <>{membership.value}% off</>
              </span>
            </>
          ),
          revenue: (membership) => (
            <>
              {membership.revenue !== undefined ? (
                <MoneyFormat value={membership.revenue} />
              ) : (
                ""
              )}
            </>
          ),
          subscriptions: (membership) => (
            <div style={{ textAlign: "right" }}>
              <ButtonLink
                size="sm"
                variant="outline-secondary"
                to={`/subscriptions?membership=${membership.id}`}
                title="View subscriptions"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                Subscriptions
              </ButtonLink>
            </div>
          ),
        }}
        onSelectRow={(membership) => navigate(`/membership/${membership.id}`)}
        noResults={(rows) => (rows?.length ? undefined : <NoResultsDisplay />)}
      />
    </Page>
  );
};

export default withAuthAndAuthRequired(MembershipIndexPage);
