import React from "react";
import {
  Badge,
  Breadcrumb,
  Button,
  Card,
  Col,
  Dropdown,
  Row,
  Stack,
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Page from "src/components/page";
import { BookingDto } from "../../services/booking/booking.dto";
import DateFormat, { DateFormats, UTC } from "../../components/DateFormat";
import DateTime from "../../components/DateTime";
import DynamicTable from "../../components/dynamicTable";
import useAsyncRetry from "../../hooks/useAsyncRetry";
import orderService from "../../services/order/order.service";
import MoneyFormat from "../../components/MoneyFormat";
import OrderStatus from "./orderStatus";
import ModalButton from "../../components/modalButton";
import tryAsyncToast from "../../hooks/tryAsyncToast";
import ButtonLink from "../../components/ButtonLink";
import { PaymentTypes } from "../../services/order/order.dto";
import { Helmet } from "react-helmet-async";
import { withAuthAndAuthRequired } from "../../hooks/withAuthorisationRequired";
import { BookingStatusText } from "src/services/booking/booking.model";
import { bookingStatusBadge } from "../bookings/view";
import { MdOutlineWarning } from "react-icons/md";
import { css } from "@emotion/css";

const orderStatusBadge = (orderStatus: number) => {
  switch (orderStatus) {
    case 0:
      return "info";
    case 1:
      return "success";
    case 2:
      return "danger";
    case 3:
      return "warning";
    case 4:
      return "dark";
    default:
      return "secondary";
  }
};

const ViewOrderPage = () => {
  const { id } = useParams();

  const { value: order, retry } = useAsyncRetry(async () => {
    if (id === undefined) return undefined;
    const { data } = await orderService.findWithBookings(id);

    console.log(data);
    return data;
  }, []);

  const cancelBooking = React.useCallback(async () => {
    if (order === undefined) return;

    await tryAsyncToast(orderService.cancel(order.id), {
      success: "Order has been Cancelled",
    });

    retry();
  }, [order, retry]);

  return (
    <Page>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/order" }}>
          Orders
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{id}</Breadcrumb.Item>
      </Breadcrumb>
      {order ? (
        <>
          <Helmet title={`Order ${order?.id}`} />
          <Stack direction="horizontal" gap={3} className="mb-3">
            <div>
              <h2>{order?.id}</h2>
            </div>
            <div className="ms-auto">
              {order.status === 1 || order.status === 0 ? (
                <ModalButton
                  title="Confirm Cancellation"
                  text="Cancel Order"
                  variant="outline-danger"
                  footer={({ hideModal }) => (
                    <>
                      <Button variant="secondary" onClick={() => hideModal()}>
                        Close
                      </Button>
                      <Button
                        variant="danger"
                        onClick={async () => {
                          await cancelBooking();
                        }}
                      >
                        Confirm and Cancel booking
                      </Button>
                    </>
                  )}
                >
                  {order.status === 1 ? (
                    <p>
                      The order has already been paid, cancelling will not cause
                      a refund to be issued, this will have to be arranged
                      outside the current system.
                    </p>
                  ) : undefined}

                  <p>Are you sure you wish to cancel this order?</p>
                </ModalButton>
              ) : (
                ""
              )}
            </div>
            <div>
              <Dropdown>
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                  Actions
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {order.status === 0 &&
                    order.paymentType === PaymentTypes.CHECKOUT && (
                      <Dropdown.Item
                        onClick={async () =>
                          await tryAsyncToast(
                            orderService.resendPayment(order.id),
                            {
                              success: "Payment email has been sent",
                            }
                          )
                        }
                      >
                        Resend payment email
                      </Dropdown.Item>
                    )}
                  {(order.status === 1 || order.status === 0) && (
                    <Dropdown.Item
                      onClick={async () =>
                        await tryAsyncToast(
                          orderService.resendConfirmation(order.id),
                          {
                            success: "Confirmation email has been sent",
                          }
                        )
                      }
                    >
                      Resend confirmation email
                    </Dropdown.Item>
                  )}
                  {order.status === 2 ? (
                    <Dropdown.Item>Issue Refund</Dropdown.Item>
                  ) : (
                    ""
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Stack>

          <Card className="mb-3">
            <Card.Body>
              <Stack direction="horizontal" gap={3}>
                <div>
                  Created
                  <br />
                  <UTC>
                    <DateFormat
                      format={DateFormats.dateTimeSimple}
                      date={order?.createdAt}
                    />{" "}
                  </UTC>
                </div>
                <div className="vr" />
                <div>
                  Customer
                  <br />
                  <Link to={`/customer/${order.account.id}`}>
                    {order.account.displayName}
                  </Link>
                </div>
                <div className="vr" />
                <div>
                  Total Paid
                  <br />
                  <span>£{order.totalCost.toFixed(2)}</span>
                </div>
                <div className="vr" />
                <div>
                  Status
                  <br />
                  <Badge bg={orderStatusBadge(order.status)}>
                    <OrderStatus status={order.status} />
                  </Badge>
                </div>
                {order.status === 1 || order.status === 3 ? (
                  <>
                    <div className="vr" />

                    {!order.refundDate ? (
                      <div>
                        Payment Date
                        <br />
                        <UTC>
                          <DateFormat
                            format={DateFormats.dateTimeSimple}
                            date={order.paymentDate}
                          />{" "}
                        </UTC>
                      </div>
                    ) : (
                      <div>
                        Refund Date
                        <br />
                        <UTC>
                          <DateFormat
                            format={DateFormats.dateTimeSimple}
                            date={order.refundDate}
                          />{" "}
                        </UTC>
                      </div>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </Stack>
            </Card.Body>
          </Card>

          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Items</Card.Title>
              <DynamicTable<BookingDto>
                useWrapper={false}
                rows={order.bookings}
                titles={{
                  id: "Booking id",
                  location: "Location",
                  hub: "Hub",
                  startDate: "Day",
                  startTime: "Start Time",
                  endTime: "End Time",
                  type: "Type",
                  status: "Status",
                  costWhenOrdered: "Cost",
                  link: "",
                }}
                mappers={{
                  id: (booking) => (
                    <Link to={`/booking/${booking.id}`}>{booking.id}</Link>
                  ),
                  location: (booking) => (
                    <Link to={`/site/${booking.hub.siteId}`}>
                      {booking.hub?.site?.name}
                    </Link>
                  ),
                  hub: (booking) => (
                    <Link to={`/hub/${booking.hub.id}`}>
                      {booking.hub?.name}
                    </Link>
                  ),
                  startDate: (booking) => (
                    <DateFormat
                      format={DateFormats.date}
                      date={booking.startDateTime}
                    />
                  ),
                  startTime: (booking) => (
                    <DateFormat
                      format={DateFormats.time}
                      date={booking.startDateTime}
                    />
                  ),
                  endTime: (booking) => (
                    <DateFormat
                      format={DateFormats.time}
                      date={booking.endDateTime}
                    />
                  ),
                  type: (booking) => (
                    <>
                      {booking === order.bookings[0] ? (
                        <Badge bg="success">Booking</Badge>
                      ) : (
                        <Badge bg="info">Extension</Badge>
                      )}
                    </>
                  ),
                  status: (booking) => (
                    <Badge bg={bookingStatusBadge(booking.status)}>
                      {BookingStatusText[booking.status]}
                    </Badge>
                  ),
                  costWhenOrdered: (booking) => (
                    <MoneyFormat value={booking.costWhenOrdered} />
                  ),
                  link: (row) => (
                    <ButtonLink
                      size="sm"
                      variant="outline-secondary"
                      to={`/booking/${row.id}`}
                    >
                      View
                    </ButtonLink>
                  ),
                }}
                tableFooter={
                  <>
                    {order.discountUsage?.map(
                      (discountUsage) =>
                        discountUsage.type && (
                          <tr key={discountUsage.id}>
                            <th colSpan={3} />
                            <th
                              colSpan={2}
                              className={css`
                                text-transform: capitalize;
                              `}
                            >
                              {discountUsage.type}
                            </th>

                            <th colSpan={1}>
                              {discountUsage.discount ? (
                                <Badge
                                  bg="warning"
                                  as={Link}
                                  to={`/discount/${discountUsage.discount?.id}`}
                                  style={{ textDecoration: "none" }}
                                >
                                  {discountUsage.discount.code}
                                </Badge>
                              ) : discountUsage.membership ? (
                                <Badge
                                  bg="warning"
                                  as={Link}
                                  to={`/membership/${discountUsage.membership.id}`}
                                  style={{ textDecoration: "none" }}
                                >
                                  {discountUsage.membership?.name}
                                </Badge>
                              ) : (
                                <></>
                              )}
                            </th>
                            <td colSpan={1}>
                              {discountUsage.discount
                                ? discountUsage.discount?.type === "percent"
                                  ? `${discountUsage.discount?.value}%`
                                  : `£${discountUsage.discount?.value.toFixed(
                                      2
                                    )}`
                                : `${discountUsage.membership?.value}%`}
                            </td>
                            <td colSpan={1}>
                              <MoneyFormat
                                value={discountUsage.discountAmountApplied}
                              />
                            </td>
                            <td colSpan={1} />
                          </tr>
                        )
                    )}

                    <tr>
                      <th colSpan={3} />
                      <th colSpan={4}>Total</th>
                      <th colSpan={2}>
                        <MoneyFormat value={order.totalCost} />
                      </th>
                    </tr>
                  </>
                }
              />
            </Card.Body>
          </Card>

          <Row>
            <Col md={8}>
              <Card className="mb-3">
                <Card.Body>
                  <Card.Title>Customer</Card.Title>
                  <Row className="mb-2">
                    <Col xs={3}>
                      <strong>Name:</strong>
                    </Col>
                    <Col>
                      <span>{order.account.displayName}</span>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={3}>
                      <strong>Email:</strong>
                    </Col>
                    <Col>
                      <Link to={`/customer/${order.account.id}`}>
                        {order.account.email}
                      </Link>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Card className="mb-3">
                <Card.Body>
                  <Card.Title>Communication</Card.Title>
                  <Row>
                    <Col xs={3}>
                      <strong>Order confirmation:</strong>
                    </Col>
                    <Col>
                      {order.confirmationSentAt ? (
                        <UTC>
                          <DateFormat
                            format={DateFormats.dateTimeSimple}
                            date={order.confirmationSentAt}
                          />{" "}
                          UTC
                        </UTC>
                      ) : (
                        <>Not sent</>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              {order.paymentType && (
                <Card className="mb-3">
                  <Card.Body>
                    <Card.Title>Payment</Card.Title>
                    <p>
                      <strong>Payment type</strong>
                      <br />
                      <Badge bg="info">
                        {order.paymentType === PaymentTypes.INTENT
                          ? "Mobile App"
                          : "Stripe Checkout"}
                      </Badge>{" "}
                    </p>

                    {order.status > 0 && (
                      <p>
                        <strong>Payment date</strong>
                        <br />
                        <UTC>
                          <DateTime date={order.paymentDate} /> UTC
                        </UTC>
                      </p>
                    )}

                    {order.status === 3 && (
                      <p>
                        <strong>Refund date</strong>
                        <br />
                        <UTC>
                          <DateTime date={order.refundDate} /> UTC
                        </UTC>
                      </p>
                    )}

                    {order.paymentId && (
                      <a
                        style={{ width: "100%" }}
                        target="_blank"
                        href={`https://dashboard.stripe.com/${
                          process.env.REACT_APP_API_BASE !==
                          "https://api.workfromhub.co.uk"
                            ? "test/"
                            : ""
                        }payments/${order.paymentId}`}
                        rel="noreferrer"
                      >
                        <Button variant="primary" style={{ width: "100%" }}>
                          View in Stripe
                        </Button>
                      </a>
                    )}
                  </Card.Body>
                </Card>
              )}

              {order.createdBy.id !== order.accountId && (
                <Card className="mb-5">
                  <Card.Body>
                    <Card.Title>Admin</Card.Title>
                    <p>
                      <strong>Placed by</strong>
                      <br />
                      <Link to={`/account/${order.createdBy.id}`}>
                        {order.createdBy?.displayName}
                      </Link>
                    </p>
                  </Card.Body>
                </Card>
              )}
            </Col>
          </Row>
        </>
      ) : (
        <Card className="text-center mb-3">
          <Card.Body className="py-5">
            <MdOutlineWarning size="5em" className="mb-3" />
            <h3>There’s no order at this address</h3>
            <p>
              Check the URL and try again, or use the orders page to find what
              you need.
            </p>
          </Card.Body>
        </Card>
      )}
    </Page>
  );
};

export default withAuthAndAuthRequired(ViewOrderPage);
