import { Form, Formik } from "formik";
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  FormCheck,
  Row,
  Stack,
} from "react-bootstrap";
import { FormFieldControl } from "../../components/formFieldControl";
import React, { useState } from "react";
import * as Yup from "yup";
import ModalButton from "src/components/modalButton";
import tryAsyncToast from "src/hooks/tryAsyncToast";
import pricingPlanService from "src/services/pricingPlan/pricingPlan.service";
import { useNavigate } from "react-router-dom";

export interface PricingPlanFormData {
  id?: number;
  type?: string;
  paymentProviderId?: string;
  displayName: string;
  slotLengthInMinutes: number | string;
  pricePerSlotLength: number | string;
  usualPricePerSlotLength?: number | undefined;
  minSlotLengthInMinutes: number | string;
  maxSlotLengthInMinutes: number | string;
}

const pricingPlanValidation = Yup.object().shape({
  displayName: Yup.string().required("A name is required"),
  pricePerSlotLength: Yup.number()
    .typeError("Please enter a valid number")
    .required("The price per slot is required"),
  slotLengthInMinutes: Yup.number()
    .typeError("Please enter a valid number")
    .required("The slot length is required"),
  minSlotLengthInMinutes: Yup.number()
    .typeError("Please enter a valid number")
    .required("A minimum slot length is required")
    .min(1, "Must be greater than 1"),
  maxSlotLengthInMinutes: Yup.number()
    .typeError("Please enter a valid number")
    .required("A maximum slot length is required")
    .max(1440, "Cannot exceed 1440"),
});

const PricingPlanForm = ({
  formData,
  onSubmit,
  onCancel,
}: {
  formData: PricingPlanFormData;
  onSubmit: (formData: PricingPlanFormData) => Promise<unknown>;
  onCancel: () => void;
}) => {
  const navigate = useNavigate();

  const [hasDiscount, setHasDiscount] = useState<boolean>(
    formData.usualPricePerSlotLength ? true : false
  );

  const onDelete = async (id: number) => {
    await tryAsyncToast(pricingPlanService.deleteById(id), {
      success: "Pricing plan deleted",
    });
    navigate(`/pricing/`);
  };

  return (
    <Formik<PricingPlanFormData>
      validationSchema={pricingPlanValidation}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        await onSubmit(values);
        setSubmitting(false);
      }}
      initialValues={formData}
    >
      {({ submitForm, values, isSubmitting }) => (
        <Form>
          <Row>
            <Col md={8}>
              <Card className="mb-3">
                <Card.Body>
                  <Card.Title>Pricing plan</Card.Title>
                  <FormFieldControl
                    fieldName="displayName"
                    label="Name"
                    placeholder="e.g. Sprint"
                    className="mb-3 "
                  />
                  {values.id && (
                    <FormFieldControl
                      fieldName="type"
                      label="Type"
                      placeholder="Type"
                      className="mb-3 w-50"
                    />
                  )}
                </Card.Body>
              </Card>

              <Card className="mb-3">
                <Card.Body>
                  <Card.Title>Pricing</Card.Title>
                  <FormFieldControl
                    fieldName="pricePerSlotLength"
                    label="Price"
                    type="number"
                    placeholder="0.00"
                    min={0.01}
                    textBefore="£"
                    className="mb-3 w-50"
                  />

                  <FormCheck
                    checked={hasDiscount}
                    onChange={(e) => {
                      setHasDiscount(e.target.checked);
                      if (!e.target.checked) {
                        // TODO: set usualPricePerSlotLength to undefined
                        console.log("no discount");
                      }
                    }}
                    className="mb-3"
                    type="checkbox"
                    id={`price-has-discount`}
                    label={`Discounted`}
                  />

                  {hasDiscount && (
                    <FormFieldControl
                      fieldName="usualPricePerSlotLength"
                      label="Full Price"
                      type="number"
                      placeholder="0.00"
                      min={
                        values.pricePerSlotLength
                          ? parseFloat(values.pricePerSlotLength.toString())
                          : undefined
                      }
                      textBefore="£"
                      className="mb-3 w-50"
                    />
                  )}

                  <FormFieldControl
                    type="number"
                    fieldName="slotLengthInMinutes"
                    label="Charging period"
                    placeholder="(minutes)"
                    textAfter="Minutes"
                    className="mb-3 w-50"
                  />
                </Card.Body>
              </Card>
              <Card className="mb-3">
                <Card.Body>
                  <Card.Title>Duration</Card.Title>
                  <Row>
                    <Col>
                      <FormFieldControl
                        fieldName="minSlotLengthInMinutes"
                        type="number"
                        label="Minimum duration"
                        min={
                          values.slotLengthInMinutes
                            ? parseFloat(values.slotLengthInMinutes.toString())
                            : undefined
                        }
                        step={
                          values.slotLengthInMinutes
                            ? parseFloat(values.slotLengthInMinutes.toString())
                            : undefined
                        }
                        max={1440}
                        placeholder="(minutes)"
                        textAfter="Minutes"
                        className="mb-3"
                      />
                    </Col>
                    <Col>
                      <FormFieldControl
                        fieldName="maxSlotLengthInMinutes"
                        type="number"
                        label="Maximum duration"
                        min={
                          values.minSlotLengthInMinutes
                            ? parseFloat(
                                values.minSlotLengthInMinutes.toString()
                              )
                            : undefined
                        }
                        step={
                          values.slotLengthInMinutes
                            ? parseFloat(values.slotLengthInMinutes.toString())
                            : undefined
                        }
                        max={1440}
                        placeholder="(minutes)"
                        textAfter="Minutes"
                        className="mb-3"
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {values.id && (
                <Card className="mb-3">
                  <Card.Body>
                    <Card.Title>Stripe</Card.Title>
                    <FormFieldControl
                      disabled={true}
                      fieldName="paymentProviderId"
                      label="Product Id"
                      placeholder="e.g. prod_NJsJpFIqUXveE0"
                      className="mb-3 w-50"
                    />
                  </Card.Body>
                </Card>
              )}
            </Col>
            <Col md={4}>
              <Card className="mb-3" style={{ backgroundColor: "#edf1f5" }}>
                <Card.Body>
                  <Card.Title>Summary</Card.Title>
                  <Card.Text>
                    <b>{values.displayName}</b>
                  </Card.Text>
                  <Card.Text>
                    {values.pricePerSlotLength && (
                      <>
                        {values.usualPricePerSlotLength && (
                          <>
                            <span style={{ textDecoration: "line-through" }}>
                              £{values.usualPricePerSlotLength}
                            </span>{" "}
                          </>
                        )}
                        <b>£{values.pricePerSlotLength}</b>
                      </>
                    )}
                  </Card.Text>
                  <ul>
                    {values.slotLengthInMinutes && (
                      <li>
                        Charged{" "}
                        <Badge bg="secondary">
                          {values.slotLengthInMinutes === 60
                            ? "Hourly"
                            : `Every ${values.slotLengthInMinutes} minutes`}{" "}
                        </Badge>
                      </li>
                    )}
                    {values.minSlotLengthInMinutes &&
                      values.maxSlotLengthInMinutes && (
                        <li>
                          Duration{" "}
                          <Badge bg="primary">
                            {parseFloat(
                              values.minSlotLengthInMinutes.toString()
                            ) / 60}{" "}
                            {values.maxSlotLengthInMinutes > 1440 && (
                              <>
                                {parseFloat(
                                  values.minSlotLengthInMinutes.toString()
                                ) /
                                  60 >
                                1
                                  ? "hours"
                                  : "hour"}
                              </>
                            )}
                            {" - "}
                            {values.maxSlotLengthInMinutes > 1440
                              ? "All day"
                              : parseFloat(
                                  values.maxSlotLengthInMinutes.toString()
                                ) / 60}{" "}
                            {values.maxSlotLengthInMinutes < 1440 && (
                              <>
                                {parseFloat(
                                  values.maxSlotLengthInMinutes.toString()
                                ) /
                                  60 >
                                1
                                  ? "hours"
                                  : "hour"}
                              </>
                            )}
                          </Badge>
                        </li>
                      )}
                  </ul>
                </Card.Body>
              </Card>
              {values.id && (
                <Alert variant="warning">
                  If this pricing plan has been used it can no longer be deleted
                </Alert>
              )}
            </Col>
          </Row>

          <hr />

          <Stack direction="horizontal" gap={3} className={"mb-3"}>
            {formData.id && (
              <ModalButton
                variant="outline-danger"
                title="Confirm Delete"
                text="Delete"
                footer={({ setShow }) => (
                  <>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                      Cancel
                    </Button>
                    <Button
                      variant="danger"
                      onClick={async () => {
                        formData.id && (await onDelete(formData.id));
                        setShow(false);
                      }}
                    >
                      Confirm
                    </Button>
                  </>
                )}
              >
                <p>Are you sure you want to delete the pricing plan?</p>

                <p>
                  It will be removed from all Hubs that use it in their
                  Availability.
                </p>

                <p>
                  You cannot remove a Pricing Plan that has been used in a
                  booking, as it is required for record keeping
                </p>
              </ModalButton>
            )}
            <div className="ms-auto">
              <Button variant="outline-secondary" onClick={onCancel}>
                Discard
              </Button>
            </div>

            <Button
              variant="success"
              className="me-3"
              disabled={isSubmitting}
              onClick={async () => {
                await submitForm();
              }}
            >
              {values.id ? "Save" : "Create Pricing Plan"}
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default PricingPlanForm;
