import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import axios from "src/services/apiClient";

import { Decoverto, Serializable } from "decoverto";

export const decoverto = new Decoverto();

export class BaseService<ResponseDTO> {
  private readonly url: string;
  axios: AxiosInstance;
  private type?: Serializable<ResponseDTO>;

  constructor(type: Serializable<ResponseDTO> | undefined, url: string = "") {
    this.type = type;
    this.url = url;
    this.axios = axios;
  }

  public parseSingleResult<R = ResponseDTO>(
    result: AxiosResponse,
    explicitType?: Serializable<R>
  ): AxiosResponse<R> | AxiosResponse<ResponseDTO> {
    if (explicitType) {
      result.data = decoverto.type(explicitType).plainToInstance(result.data);
      return result as AxiosResponse<R>;
    } else {
      if (this.type === undefined)
        throw new Error("Type must be specified if not defined in service");
      result.data = decoverto.type(this.type).plainToInstance(result.data);
      return result as AxiosResponse<ResponseDTO>;
    }
  }

  private parseArrayResult<R = ResponseDTO>(
    result: AxiosResponse,
    explicitType?: Serializable<R>
  ): AxiosResponse<R[] | ResponseDTO[]> {
    if (explicitType) {
      result.data = decoverto
        .type(explicitType)
        .plainToInstanceArray(result.data);
      return result as AxiosResponse<R[]>;
    } else {
      if (this.type === undefined)
        throw new Error(
          "Type must be explicitly specified if not defined in service"
        );
      result.data = decoverto.type(this.type).plainToInstanceArray(result.data);
      return result as AxiosResponse<ResponseDTO[]>;
    }
  }

  protected async _getUnparsed(
    url: string = "",
    config: AxiosRequestConfig = {}
  ) {
    return axios.get(`${this.url}${url}`, config);
  }

  protected async _getArray(
    url?: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<ResponseDTO[]>>;
  protected async _getArray<R>(
    url: string,
    config: AxiosRequestConfig | undefined,
    explicitType: Serializable<R>
  ): Promise<AxiosResponse<R[]>>;
  protected async _getArray<R>(
    url: string = "",
    config: AxiosRequestConfig | undefined = undefined,
    explicitType?: Serializable<R>
  ): Promise<AxiosResponse<R[]> | AxiosResponse<ResponseDTO[]>> {
    if (explicitType)
      return this.parseArrayResult<R>(
        await this._getUnparsed(url, config || {}),
        explicitType
      ) as AxiosResponse<R[]>;
    else
      return this.parseArrayResult(
        await this._getUnparsed(url, config || {})
      ) as AxiosResponse<ResponseDTO[]>;
  }

  protected async _getParsed(
    url?: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<ResponseDTO>>;
  protected async _getParsed<R>(
    url: string,
    config: AxiosRequestConfig | undefined,
    explicitType: Serializable<R>
  ): Promise<AxiosResponse<R>>;
  protected async _getParsed<R>(
    url: string = "",
    config: AxiosRequestConfig | undefined = undefined,
    explicitType?: Serializable<R>
  ): Promise<AxiosResponse<R> | AxiosResponse<ResponseDTO>> {
    if (explicitType) {
      return this.parseSingleResult<R>(
        await this._getUnparsed(url, config || {}),
        explicitType
      ) as AxiosResponse<R>;
    } else
      return this.parseSingleResult(
        await this._getUnparsed(url, config || {})
      ) as AxiosResponse<ResponseDTO>;
  }

  protected async _postUnParsed(
    url: string = "",
    data?: unknown,
    config: AxiosRequestConfig = {}
  ) {
    return await axios.post(`${this.url}${url}`, data, config);
  }

  protected async _post<T>(
    url?: string,
    data?: Partial<T>,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<ResponseDTO>>;
  protected async _post<T, R>(
    url?: string,
    data?: Partial<T>,
    config?: AxiosRequestConfig,
    explicitType?: Serializable<R>
  ): Promise<AxiosResponse<R>>;
  protected async _post<T, R>(
    url: string = "",
    data?: Partial<T>,
    config: AxiosRequestConfig | undefined = undefined,
    explicitType?: Serializable<R>
  ): Promise<AxiosResponse<R> | AxiosResponse<ResponseDTO>> {
    if (explicitType)
      return this.parseSingleResult<R>(
        await this._postUnParsed(url, data, config),
        explicitType
      ) as AxiosResponse<R>;
    else
      return this.parseSingleResult(
        await this._postUnParsed(url, data, config)
      ) as AxiosResponse<ResponseDTO>;
  }

  protected async _patch<T>(
    url?: string,
    data?: Partial<T>,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<ResponseDTO>>;
  protected async _patch<T, R>(
    url: string,
    data: Partial<T>,
    config: AxiosRequestConfig,
    explicitType: Serializable<R>
  ): Promise<AxiosResponse<R>>;
  protected async _patch<T, R>(
    url: string = "",
    data?: Partial<T>,
    config: AxiosRequestConfig = {},
    explicitType?: Serializable<R>
  ): Promise<AxiosResponse<R> | AxiosResponse<ResponseDTO>> {
    if (explicitType)
      return this.parseSingleResult<R>(
        await axios.patch(`${this.url}${url}`, data, config),
        explicitType
      ) as AxiosResponse<R>;
    else
      return this.parseSingleResult(
        await axios.patch(`${this.url}${url}`, data, config)
      ) as AxiosResponse<ResponseDTO>;
  }

  async _deleteUnparsed(url: string = "", config: AxiosRequestConfig = {}) {
    await axios.delete(`${this.url}${url}`, config);
  }

  async _delete204(url: string = "", config: AxiosRequestConfig = {}) {
    await axios.delete(`${this.url}${url}`, config);
  }

  protected async _delete(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<ResponseDTO>>;
  protected async _delete<R>(
    url: string,
    config?: AxiosRequestConfig,
    explicitType?: Serializable<R>
  ): Promise<AxiosResponse<R>>;
  protected async _delete<R>(
    url: string = "",
    config: AxiosRequestConfig = {},
    explicitType?: Serializable<R>
  ): Promise<AxiosResponse<R> | AxiosResponse<ResponseDTO>> {
    if (explicitType)
      return this.parseSingleResult<R>(
        await axios.delete(`${this.url}${url}`, config),
        explicitType
      ) as AxiosResponse<R>;
    else
      return this.parseSingleResult(
        await axios.delete(`${this.url}${url}`, config)
      ) as AxiosResponse<ResponseDTO>;
  }
}
