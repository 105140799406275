import React, { useCallback } from "react";
import { Breadcrumb, Stack } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import Page from "src/components/page";
import tryAsyncToast from "src/hooks/tryAsyncToast";
import { CreateDiscountDto } from "src/services/discount/discount.model";
import discountService from "src/services/discount/discount.service";
import { withAuthAndAuthRequired } from "../../hooks/withAuthorisationRequired";
import {
  DiscountForm,
  DiscountFormValues,
  formValuesToDiscountDto,
} from "src/pages/discount/discountForm";
import { DiscountSummary } from "src/pages/discount/discountSummary";

const DiscountCreatePage = () => {
  const navigate = useNavigate();

  const createDiscount = React.useCallback(
    async (formValues: CreateDiscountDto) => {
      try {
        console.log("formvalues", formValues);
        const result = await tryAsyncToast(discountService.create(formValues), {
          success: `Discount ${formValues.code} created`,
        });
        navigate(`/discount/${result.id}`);
      } catch (error: any) {}
    },
    [navigate]
  );

  const onSubmit = useCallback(
    async (formValues: DiscountFormValues) => {
      let discount = formValuesToDiscountDto(formValues);
      return await createDiscount(discount);
    },
    [createDiscount]
  );

  return (
    <Page>
      <Helmet title="Create Discount" />
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/discount" }}>
          Discounts
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Create</Breadcrumb.Item>
      </Breadcrumb>
      <Stack direction="horizontal" gap={3} className={"mb-3"}>
        <div>
          <h2>Create Discount</h2>
        </div>
      </Stack>

      <DiscountForm onSubmit={onSubmit}>
        {(values) => (
          <>
            <DiscountSummary {...values} />
          </>
        )}
      </DiscountForm>
    </Page>
  );
};

export default withAuthAndAuthRequired(DiscountCreatePage);
