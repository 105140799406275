import React from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  FormSelect,
  Row,
  Spinner,
} from "react-bootstrap";
import { HubAvailabilityDto } from "../../services/hubAvailability/hubAvailability.dto";
import tryAsyncToast from "../../hooks/tryAsyncToast";
import useAsyncRetry from "../../hooks/useAsyncRetry";
import { HubModel } from "../../services/hub/hub.model";
import hubService from "../../services/hub/hub.service";
import { HubAvailabilityModel } from "../../services/hubAvailability/hubAvailability.model";
import hubAvailabilityService from "../../services/hubAvailability/hubAvailability.service";
import {
  AvailabilityForm,
  initialHubAvailability,
} from "../hubAvailability/availabilityForm";
import HubAvailabilityList from "../hubAvailability/hubAvailabilityList";

const HubAvailabilityTab = ({
  hub: parentHub,
}: //setValue,
{
  hub: HubModel;
  //setValue: (value: HubModel | undefined) => void;
}) => {
  const {
    value: hub,
    isLoading,
    setValue,
  } = useAsyncRetry(async () => {
    const hubAvailabilities = await hubService.findById(
      parentHub.id,
      "hubAvailabilities[pricingPlans]"
    );
    return {
      ...parentHub,
      ...hubAvailabilities,
    };
  }, [parentHub]);

  const [selectedAvailability, setSelectedAvailability] = React.useState<
    HubAvailabilityDto | undefined
  >();

  const { value: allAvailabilities } = useAsyncRetry(async () => {
    return hubAvailabilityService.findAllWithPricingPlans();
  }, []);

  const unusedAvailabilities = React.useMemo(() => {
    if (hub === undefined || allAvailabilities === undefined) return undefined;

    const selectedIds = hub.hubAvailabilities.map(({ id }) => id);
    return allAvailabilities.filter(({ id }) => !selectedIds.includes(id));
  }, [allAvailabilities, hub]);

  const onSubmit = React.useCallback(
    async (resource: HubAvailabilityDto) => {
      if (hub === undefined || selectedAvailability === undefined) return;

      if (resource.id === undefined) {
        const result = await tryAsyncToast(
          hubService.createAvailability(
            hub.id,
            resource as HubAvailabilityModel
          ),
          {
            success: "Availability Plan created",
          }
        );

        setValue({
          ...hub,
          hubAvailabilities: result.data.hubAvailabilities,
        });
      } else {
        await tryAsyncToast(
          hubService.updateAvailability(
            hub.id,
            selectedAvailability.id,
            resource as HubAvailabilityModel
          ),
          {
            success: "Availability Plan updated",
          }
        );

        setValue({
          ...hub,
          hubAvailabilities: hub.hubAvailabilities.map((ha) => {
            return ha.id === selectedAvailability.id ? resource : ha;
          }),
        });
      }

      setSelectedAvailability(undefined);
    },
    [hub, selectedAvailability, setValue]
  );

  const [selectedAvailabilityOption, setSelectedAvailabilityOption] =
    React.useState<number | undefined>(undefined);

  const addAvailability = React.useCallback(
    async (availabilityId: number) => {
      if (hub === undefined) return;

      await tryAsyncToast(hubService.addAvailability(hub.id, availabilityId), {
        success: "Availability plan added",
      });

      const hubAvailabilities = [
        ...hub.hubAvailabilities,
        allAvailabilities?.find(({ id }) => id === availabilityId),
      ].filter(Boolean) as HubAvailabilityDto[];

      setSelectedAvailabilityOption(undefined);

      setValue({
        ...hub,
        hubAvailabilities,
      });
    },
    [allAvailabilities, hub, setValue]
  );

  const removeAvailability = React.useCallback(
    async (availability: HubAvailabilityDto) => {
      if (hub === undefined) return;
      await tryAsyncToast(
        hubService.deleteAvailability(hub.id, availability.id),
        {
          success: "Availability plan removed",
        }
      );

      const hubAvailabilities = hub.hubAvailabilities.filter(
        ({ id }) => id !== availability.id
      );

      setValue({
        ...hub,
        hubAvailabilities,
      });
    },
    [hub, setValue]
  );

  if (hub === undefined) return <></>;

  return isLoading ? (
    <Row className="tabContainer">
      <Col sm="12" className="text-center">
        <Spinner animation="border" />
      </Col>
    </Row>
  ) : (
    <Row className="tabContainer">
      <>
        {hub.hubAvailabilities.length === 0 ? (
          <Alert>
            There is <strong>no</strong> availability set for this hub
          </Alert>
        ) : (
          <Row>
            <Col>
              <HubAvailabilityList
                availabilities={hub.hubAvailabilities}
                setSelectedAvailability={setSelectedAvailability}
                onDelete={removeAvailability}
                isLoading={isLoading}
              />
            </Col>
          </Row>
        )}
        <Row className="my-3">
          <Col>
            <Card className="p-3 h-100">
              <h3 className="h4 mb-3">
                Existing plans that can be assigned to this hub
              </h3>
              {unusedAvailabilities && (
                <Row>
                  {unusedAvailabilities.length === 0 ? (
                    <Col>
                      <Alert>
                        <strong>All Availability plans</strong> have been
                        assigned to this hub
                      </Alert>
                    </Col>
                  ) : (
                    <>
                      <Col>
                        <FormSelect
                          value={selectedAvailabilityOption}
                          onChange={(e) =>
                            setSelectedAvailabilityOption(
                              parseInt(e.target.value, 10)
                            )
                          }
                        >
                          <option value={undefined}>
                            Select an existing plan
                          </option>
                          {unusedAvailabilities?.map((availability) => (
                            <option
                              key={availability.id}
                              value={availability.id}
                            >
                              {availability.name}
                            </option>
                          ))}
                        </FormSelect>
                      </Col>
                      <Col>
                        <Button
                          disabled={selectedAvailabilityOption === undefined}
                          onClick={async () => {
                            if (selectedAvailabilityOption !== undefined) {
                              await addAvailability(selectedAvailabilityOption);
                            }
                          }}
                        >
                          Add existing availability plan
                        </Button>
                      </Col>
                    </>
                  )}
                </Row>
              )}
            </Card>
          </Col>
          <Col>
            <Card className="p-3 h-100">
              {selectedAvailability === undefined && (
                <>
                  <h3 className="h4 mb-3">
                    A new plan can be created if nothing matches requirements
                  </h3>
                  <Row>
                    <Col>
                      <Button
                        variant="success"
                        onClick={() =>
                          setSelectedAvailability({
                            ...initialHubAvailability,
                          } as HubAvailabilityDto)
                        }
                      >
                        Add new availability plan
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </>

      {selectedAvailability !== undefined && (
        <>
          <Alert variant="warning" className="mt-3">
            <strong>Note:</strong> Editing an availability plan will effect all
            hubs that use this plan
          </Alert>
          <AvailabilityForm
            availability={selectedAvailability}
            onSubmit={onSubmit}
            onCancel={async () => setSelectedAvailability(undefined)}
          />
        </>
      )}
    </Row>
  );
};

export default HubAvailabilityTab;
