import { PricingPlanDto } from "src/services/pricingPlan/pricingPlan.dto";
import { PricingPlanModel } from "src/services/pricingPlan/pricingPlan.model";
import { array, model, property } from "decoverto";

@model()
export class HubAvailabilityModel {
  @property(() => Number)
  dayOfWeek!: number;

  @property(() => Number)
  endTime!: number;

  @property(() => Number)
  id!: number;

  @property(() => String)
  name!: string;

  @property(array(() => PricingPlanModel))
  pricingPlans!: PricingPlanDto[];

  @property(() => Number)
  pricingPlanId!: number;

  @property(() => Number)
  startTime!: number;
}
