import { HubAvailabilityDto } from "src/services/hubAvailability/hubAvailability.dto";
import { MediaDto } from "src/services/media/media.dto";
import { HubAllocationModel } from "src/services/hubAllocation/hubAllocation.model";
import { HubAvailabilityModel } from "src/services/hubAvailability/hubAvailability.model";
import { MediaModel } from "src/services/media/media.model";
import { SiteModel } from "src/services/site/site.model";
import { array, model, property } from "decoverto";
import { SiteDto } from "../site/site.dto";
import { PaginationResultDto } from "../searchableService";

@model()
export class HubModel {
  @property(() => String)
  description!: string;

  @property(() => MediaModel)
  featuredMedia?: MediaDto;

  @property(() => Number)
  featuredMediaId?: number | null;

  @property(array(() => HubAvailabilityModel))
  hubAvailabilities!: HubAvailabilityDto[];

  @property(() => Number)
  id!: number;

  @property(() => Boolean)
  isActive!: boolean;

  @property(() => Number)
  latitude!: number;

  @property(() => Number)
  longitude!: number;

  @property(array(() => MediaModel))
  media!: MediaDto[];

  @property(() => String)
  name!: string;

  @property(() => String)
  overview!: string;

  @property(() => SiteModel)
  site?: SiteDto;

  @property(() => Number)
  siteId?: number;

  @property(array(() => HubAllocationModel))
  pastAllocations?: HubAllocationModel[];

  @property(array(() => HubAllocationModel))
  currentAllocations?: HubAllocationModel[];

  @property(array(() => HubAllocationModel))
  futureAllocations?: HubAllocationModel[];

  @property(() => String)
  wifiNetwork?: string;

  @property(() => String)
  wifiPassword?: string;

  @property(() => String)
  amenities?: string;

  @property(() => Number)
  lockId?: number | null;

  @property(() => String)
  locationDirections?: string;

  @property(() => Boolean)
  allowRemoteUnlocking?: boolean;
}

export class HubModelSearchResults implements PaginationResultDto<HubModel> {
  @property(array(() => HubModel))
  public rows!: HubModel[];

  @property(() => Number)
  public count!: number;
}

export type CreateHubDto = Omit<
  HubModel,
  | "id"
  | "featuredMediaId"
  | "media"
  | "featuredMedia"
  | "site"
  | "hubAvailabilities"
>;

export type CreateFullHubDto = Omit<HubModel, "id" | "featuredMediaId">;
