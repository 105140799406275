import React from "react";
import { Helmet } from "react-helmet-async";
import Page from "src/components/page";
import SearchTable from "src/components/searchTable";
import { AccountSearchDto } from "src/services/account/account.model";
import accountService from "src/services/account/account.service";
import ButtonLink from "../../components/ButtonLink";
import { withAuthAndAuthRequired } from "../../hooks/withAuthorisationRequired";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";

const CustomerIndexPage = () => {
  return (
    <Page>
      <Helmet title="Customers" />
      <SearchTable<AccountSearchDto>
        caption="Customers"
        service={accountService}
        defaultFilters={["user", "guest"]}
        mappers={{
          id: (account) => (
            <Link to={`/customer/${account.id}`}>{account.id}</Link>
          ),
          name: (account) => <strong>{account.displayName}</strong>,
          email: (account) => (
            <Link to={`/customer/${account.id}`}>{account.email}</Link>
          ),
          type: ({ type }) => {
            switch (type) {
              case "admin":
                return "Administrator";
              case "guest":
                return <Badge bg="secondary">Guest</Badge>;
              case "deleted":
                return "Deleted";
              default:
                return <Badge bg="primary">Customer</Badge>;
            }
          },
          controls: (account) => (
            <div style={{ textAlign: "right" }}>
              <ButtonLink
                size="sm"
                variant="outline-secondary"
                to={`/customer/${account.id}`}
              >
                View
              </ButtonLink>
            </div>
          ),
        }}
        titles={{
          id: "#",
          name: "Name",
          email: "Email",
          type: "Type",
          controls: "",
        }}
      />
    </Page>
  );
};

export default withAuthAndAuthRequired(CustomerIndexPage);
