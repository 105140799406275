import { BaseService } from "src/services/baseService";
import {
  MembershipModel,
  MembershipModelSearchResults,
} from "src/services/membership/membership.model";
import { CreateMembershipDto } from "src/services/membership/membership.dto";
import { MembershipStatisticsModel } from "src/services/membership/membershipStatistics.model";
import {
  decodeSearch,
  PaginationResultDto,
  SearchableService,
  SearchProps,
} from "../searchableService";

class MembershipService
  extends BaseService<MembershipModel>
  implements SearchableService<MembershipModel>
{
  constructor() {
    super(MembershipModel, "/membership");
  }

  async findAll(): Promise<MembershipModel[]> {
    return (await this._getArray("/")).data;
  }

  async findById(id: string | number): Promise<MembershipModel> {
    return (await this._getParsed(`/${id}?relations=media,membershipAccounts`))
      .data;
  }

  public async create(membership: CreateMembershipDto) {
    const response = await this._post("", membership);
    return response.data;
  }

  public async delete(id: number): Promise<void> {
    return await this._delete204(`/${id}`);
  }

  async update(id: number, membership: Partial<MembershipModel>) {
    const response = await this._patch(`/${id}`, membership);
    return response.data;
  }

  async statistics(): Promise<MembershipStatisticsModel | undefined> {
    const response = await this._getParsed(
      "/statistics",
      undefined,
      MembershipStatisticsModel
    );

    return response.data;
  }

  async search(
    search: SearchProps
  ): Promise<PaginationResultDto<MembershipModel> | undefined> {
    const params = decodeSearch(search);

    return (
      await this._getParsed(
        `/search?${params}`,
        {},
        MembershipModelSearchResults
      )
    ).data;
  }

  searchFilters(): { [p: string]: string } | undefined {
    return {
      activeMembers: "activeMembers",
      cancelledMembers: "cancelledMembers",
      revenue: "revenue",
      activePlans: "activePlans",
    };
  }
}

const membershipService = new MembershipService();

export default membershipService;
