import { css, cx } from "@emotion/css";
import { useFormik } from "formik";
import React from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import { MdOutlineDeleteForever, MdOutlineEdit } from "react-icons/md";
import ModalButton from "src/components/modalButton";
import tryAsyncToast from "src/hooks/tryAsyncToast";
import { MediaModel } from "src/services/media/media.model";
import mediaService from "src/services/media/media.service";
import { useDropzone } from "react-dropzone";
import toDataUrl from "../utils/toDataUrl";
import { FaCamera, FaPlusCircle } from "react-icons/fa";
import * as Yup from "yup";
import { errorMessage } from "./formFieldControl";

export interface MediaFieldProps {
  id?: number;
  type: MediaModel["type"];
  url: string;
  title: string;
  _new?: boolean;
  dataUrl?: string;
}

const emptyMediaField = {
  id: undefined,
  title: "",
  type: "image",
  url: "",
  dataUrl: undefined,
  _new: true,
} as MediaFieldProps;

// region child components
const DeleteFieldButton = ({
  deleteMedia,
  field,
}: {
  field?: MediaFieldProps;
  deleteMedia: () => Promise<void>;
}) => {
  return (
    <ModalButton
      title="Confirm Delete"
      text={<MdOutlineDeleteForever />}
      variant="danger"
      footer={({ setShow }) => (
        <>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={async () => {
              setShow(false);
              await deleteMedia();
            }}
          >
            Delete
          </Button>
        </>
      )}
    >
      This will delete the {field?.type} permanently, are you sure?
    </ModalButton>
  );
};

const uploadButtonStyle = css``;

interface ModalState {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const useModalState = (initialShow: boolean): ModalState => {
  const [show, setShow] = React.useState(initialShow);

  return {
    show,
    setShow,
  };
};

const MediaFieldSchema = Yup.object({
  title: Yup.string().required("A title is required"),
});

const MediaFieldModal = ({
  initialMediaField,
  saveMedia,
  show,
  setShow,
}: {
  initialMediaField: MediaFieldProps | undefined;
  saveMedia: (media: MediaFieldProps) => Promise<void>;
  show: boolean;
  setShow: (show: boolean) => void;
}) => {
  const activeDragStyle = css`
    outline-offset: 5px;
    opacity: 0.5;
  `;

  const {
    values: mediaField,
    handleChange,
    submitForm,
    resetForm,
    setValues,
    isValid,
    errors,
    isSubmitting,
  } = useFormik<MediaFieldProps>({
    initialValues:
      initialMediaField === undefined
        ? { ...emptyMediaField }
        : initialMediaField,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await saveMedia(values);
      setSubmitting(false);
      resetForm();
      setShow(false);
    },
    validationSchema: MediaFieldSchema,
  });

  const { getInputProps, getRootProps, inputRef, isDragActive } = useDropzone({
    onDrop: async (files) => {
      if (files.length < 1) {
        return;
      }
      const dataUrl = await toDataUrl(files[0]);

      await setValues({
        ...mediaField,
        url: "",
        dataUrl: dataUrl as string,
      });
    },
  });

  const hasImage = React.useMemo(
    () => mediaField.url !== "" || mediaField.dataUrl !== undefined,
    [mediaField]
  );

  const containerClass = css`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    margin: auto auto;
    transform: translateY(-50%) translateX(-50%);
    background-color: rgba(255, 255, 255, 0.75);
  `;

  const hideContainer = css`
    visibility: hidden;
  `;

  const hoverClass = css`
    &:hover .container-fluid {
      visibility: visible !important;
    }
  `;

  return (
    <Modal
      data-testid="modal"
      show={show}
      onHide={() => setShow(false)}
      backdrop="static"
      onClick={(e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Modal.Header closeButton>
        {mediaField.title || "<title>"}&nbsp;
        <em>({mediaField._new ? "New" : mediaField.id})</em>{" "}
      </Modal.Header>

      <Modal.Body className={hoverClass}>
        <Form>
          <div
            className={css`
              position: relative;
              display: flex;
              flex-direction: column;
              width: 100%;
              min-height: 200px;
            `}
            onClick={() => {
              if (inputRef && inputRef.current) {
                inputRef.current.click();
              }
            }}
            {...getRootProps()}
          >
            <input
              {...getInputProps()}
              accept="image/*"
              data-testid="drag-n-drop-image"
            />
            <Image
              src={mediaField.url || mediaField.dataUrl}
              className="center"
            />

            <Container
              fluid
              className={cx(
                uploadButtonStyle,
                isDragActive && activeDragStyle,
                containerClass,
                !hasImage || isDragActive ? undefined : hideContainer
              )}
            >
              <Row>
                <Col className="text-center">
                  <FaCamera className="h2 text-black " />
                </Col>
              </Row>
              <Row>
                <Col className="text-center">
                  <span className="text-black">
                    Click or drag here to upload
                  </span>
                </Col>
              </Row>
              <Row>
                <Col className="text-center">
                  {" "}
                  <FaPlusCircle className="h2 text-black" />
                </Col>
              </Row>
            </Container>
          </div>

          <Form.Group as={Row}>
            <Form.Label column sm="2">
              Title
            </Form.Label>
            <Col>
              <Form.Control
                name="title"
                id="title"
                placeholder="Enter title"
                title="Title"
                value={mediaField.title}
                onChange={handleChange}
              />
              {errors["title"] && (
                <div className={errorMessage}>{errors["title"]}</div>
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="2">
              Url
            </Form.Label>
            <Col>
              <Form.Control
                disabled={mediaField.dataUrl !== undefined}
                name="url"
                id="url"
                placeholder={
                  mediaField.dataUrl !== undefined
                    ? "Local file to be uploaded"
                    : "url"
                }
                title="url"
                value={mediaField.url}
                onChange={handleChange}
              />
              {mediaField.dataUrl === undefined && errors["url"] && (
                <div className={errorMessage}>{errors["url"]}</div>
              )}
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button
          type="reset"
          disabled={isSubmitting}
          onClick={() => {
            resetForm();
            setShow(false);
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={!isValid || isSubmitting}
          onClick={submitForm}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// endregion

export function MediaFieldControl({
  initialMediaField,
  onRemove,
  onSave,
  label = undefined,
  autoUpdateMedia = true,
}: {
  initialMediaField?: MediaFieldProps;
  onRemove?: () => Promise<void>;
  onSave: (media: MediaModel) => Promise<void>;
  label?: string;
  autoUpdateMedia?: boolean;
}) {
  const modalState = useModalState(false);

  const [mediaField, setMediaField] = React.useState(() =>
    !initialMediaField ? emptyMediaField : initialMediaField
  );

  const resetMediaField = () =>
    setMediaField(() =>
      !initialMediaField ? emptyMediaField : initialMediaField
    );

  React.useEffect(resetMediaField, [initialMediaField]);

  const activeDragStyle = css`
    outline-offset: 5px;
    opacity: 0.5;
  `;

  const saveMedia = React.useCallback(
    async (_mediaField) => {
      const { _new, ...mediaField }: MediaFieldProps = _mediaField;

      if (autoUpdateMedia) {
        return tryAsyncToast(
          async () => {
            const result =
              mediaField.id === undefined
                ? await mediaService.create(mediaField)
                : await mediaService.update(mediaField.id, mediaField);

            await onSave(result);
          },
          {
            success: `Media has been ${mediaField.id ? "updated" : "created"}`,
          }
        );
      }

      await onSave(mediaField as MediaModel);
    },
    [autoUpdateMedia, onSave]
  );

  const { getRootProps, isDragActive } = useDropzone({
    noClick: true,
    onDrop: async (files) => {
      if (files.length < 1) {
        return;
      }
      const dataUrl = await toDataUrl(files[0]);

      setMediaField({
        ...(initialMediaField || emptyMediaField),
        dataUrl: dataUrl as string,
      });

      modalState.setShow(true);
    },
  });

  const deleteMedia = React.useCallback(
    async () =>
      autoUpdateMedia
        ? tryAsyncToast(
            async () => {
              if (onRemove === undefined) return;

              if (initialMediaField?.id === undefined)
                throw new Error("Trying to delete undefined Media");

              await onRemove();
              await mediaService.deleteById(initialMediaField?.id);
            },
            { success: "Media deleted" }
          )
        : await onRemove?.(),
    [initialMediaField?.id, onRemove, autoUpdateMedia]
  );

  return (
    <>
      <Container
        fluid
        className={cx(
          "p-2 bg-light",
          css`
            height: 160px;
          `
        )}
      >
        <Row>
          {mediaField._new ? (
            <>
              <Button
                className={cx(
                  css`
                    height: 160px;
                  `,
                  "p-0 m-1",
                  uploadButtonStyle,
                  isDragActive && activeDragStyle
                )}
                {...getRootProps()}
                onClick={() => {
                  resetMediaField();
                  modalState.setShow(true);
                }}
              >
                <Container fluid className="p-0 m-0">
                  {label !== undefined && (
                    <Row className="m-1">
                      <Col className="bg-dark opacity-75 text-white w-100 text-center ">
                        {label}
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col>
                      <FaCamera className="h1 m-1" />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <span>Drag here to upload</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {" "}
                      <FaPlusCircle
                        className="h1 m-1"
                        data-testid="upload-media"
                      />
                    </Col>
                  </Row>
                </Container>
              </Button>
            </>
          ) : (
            <Col
              className="p-0 m-1"
              style={{
                backgroundImage: `url(${
                  initialMediaField?.url || initialMediaField?.dataUrl
                })`,
                width: "100%",
                height: "160px",
                backgroundSize: "cover",
                borderRadius: "4px",
              }}
            >
              <Row>
                {initialMediaField && onRemove && (
                  <Col sm="auto">
                    <DeleteFieldButton
                      deleteMedia={deleteMedia}
                      field={initialMediaField}
                    />
                  </Col>
                )}
                <Col className="">
                  {label !== undefined && (
                    <div className="bg-dark opacity-75 text-white w-100 text-center">
                      {label}
                    </div>
                  )}
                </Col>
                <Col sm="auto" className="justify-content-end">
                  <Button onClick={() => modalState.setShow(true)}>
                    <MdOutlineEdit />
                  </Button>
                </Col>
              </Row>

              <div className="flex-grow-1" />
            </Col>
          )}
        </Row>
        <MediaFieldModal
          initialMediaField={mediaField}
          saveMedia={saveMedia}
          {...modalState}
        />
      </Container>
    </>
  );
}
