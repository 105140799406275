import React from "react";
import { Col } from "react-bootstrap";
import { MediaFieldControl } from "src/components/mediaFieldControl";
import { MediaModel } from "src/services/media/media.model";

const MediaFieldGallery = ({
  values,
  onRemove,
  onSave,
  onCreate,
  autoUpdateMedia = true,
}: {
  values: MediaModel[];
  onRemove: (mediaIndex: number) => Promise<void>;
  onSave: (media: MediaModel, mediaIndex: number) => Promise<void>;
  onCreate: (media: MediaModel) => Promise<void>;
  autoUpdateMedia?: boolean;
}) => {
  return (
    <>
      {values.map((media, index) => (
        <Col key={index}>
          <MediaFieldControl
            initialMediaField={media}
            onSave={(value) => onSave(value, index)}
            onRemove={() => onRemove(index)}
            autoUpdateMedia={autoUpdateMedia}
          />
        </Col>
      ))}

      <Col>
        <MediaFieldControl
          initialMediaField={undefined}
          onSave={(value) => onCreate(value)}
          autoUpdateMedia={autoUpdateMedia}
        />
      </Col>
    </>
  );
};

export default MediaFieldGallery;
