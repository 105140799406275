import { Formik } from "formik";
import { parseInt } from "lodash";
import React from "react";
import { Button, Card, Col, FormSelect, Row, Table } from "react-bootstrap";
import { HubAvailabilityDto } from "src/services/hubAvailability/hubAvailability.dto";
import { PricingPlanDto } from "src/services/pricingPlan/pricingPlan.dto";
import { FormFieldControl } from "src/components/formFieldControl";
import useAsyncRetry from "src/hooks/useAsyncRetry";
import pricingPlanService from "src/services/pricingPlan/pricingPlan.service";
import * as Yup from "yup";

interface HubAvailabilityForm {
  id: number;
  name: string;
  dayOfWeek?: number;
  startTime?: number;
  endTime?: number;
  pricingPlans: PricingPlanDto[];
  selectedPricingPlan?: string;
}

export const initialHubAvailability: HubAvailabilityForm = {
  id: -1,
  dayOfWeek: undefined,
  endTime: undefined,
  name: "",
  pricingPlans: [],
  startTime: undefined,
};

const hubAvailabilityValidation = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  startTime: Yup.number().required("Start time is required"),
  endTime: Yup.number()
    .required("End time is required")
    .test(
      "after-start",
      "End time must be after start",
      (value, context) =>
        value !== undefined && value > context.parent.startTime
    ),
  dayOfWeek: Yup.number().required("Please specify the day of week"),
});

const formValuesToResource = (values: HubAvailabilityForm) => {
  return {
    ...values,
    id: values.id === -1 ? undefined : values.id,
    dayOfWeek: parseInt(`${values.dayOfWeek}`),
    startTime: values.startTime as number,
    endTime: values.endTime as number,
    selectedPricingPlan: undefined,
  } as HubAvailabilityDto;
};

export const AvailabilityForm = ({
  availability,
  onSubmit,
  onCancel,
}: {
  availability?: HubAvailabilityDto;
  onSubmit: (resource: HubAvailabilityDto) => Promise<void>;
  onCancel: () => Promise<void>;
}) => {
  const { value: pricingPlans } = useAsyncRetry(() => {
    return pricingPlanService.findAll();
  });

  const availablePricingPlans = React.useCallback(
    (currentPlans?: PricingPlanDto[]) => {
      if (pricingPlans === undefined) return [];
      if (currentPlans === undefined) return pricingPlans;

      const selectedIds = currentPlans.map(({ id }) => id);
      return pricingPlans.filter(({ id }) => {
        return !selectedIds.includes(id);
      });
    },
    [pricingPlans]
  );

  if (availability === undefined) return <></>;

  return (
    <Formik<HubAvailabilityForm>
      enableReinitialize
      validationSchema={hubAvailabilityValidation}
      initialValues={{
        ...availability,
        selectedPricingPlan: undefined,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        await onSubmit(formValuesToResource(values));
        setSubmitting(false);
      }}
    >
      {({ values, submitForm, handleChange, setValues, isValid }) => (
        <>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title>Availability plan - Details</Card.Title>

                  <Row className="my-4">
                    <Col>
                      <div className="w-50" style={{ minWidth: "40rem" }}>
                        <FormFieldControl
                          fieldName="name"
                          label="Name"
                          placeholder="Availability name"
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="mb-4">
                    <Col>
                      <div style={{ width: "18.5rem" }}>
                        <FormFieldControl
                          type="select"
                          fieldName="dayOfWeek"
                          label="Day of week"
                        >
                          <option>Day of week</option>
                          <option value="0">Sunday</option>
                          <option value="1">Monday</option>
                          <option value="2">Tuesday</option>
                          <option value="3">Wednesday</option>
                          <option value="4">Thursday</option>
                          <option value="5">Friday</option>
                          <option value="6">Saturday</option>
                        </FormFieldControl>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col sm={1} style={{ minWidth: "10rem" }}>
                      <FormFieldControl
                        fieldName="startTime"
                        type="time"
                        label="Start Time"
                      />
                    </Col>
                    <Col sm={1} style={{ minWidth: "10rem" }}>
                      <FormFieldControl
                        fieldName="endTime"
                        type="time"
                        label="End Time"
                      />
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Body>
                  <Card.Title>
                    Active Pricing Plans used during this time
                  </Card.Title>
                  <Row>
                    <Col>
                      <Table striped hover className="w-100">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Slot chunk size</th>
                            <th>Price per chunk</th>
                            <th>Min slot size</th>
                            <th>Max slot size</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {values.pricingPlans?.map((pricingPlan) => (
                            <tr key={pricingPlan.id}>
                              <td>{pricingPlan.displayName}</td>
                              <td>{pricingPlan.slotLengthInMinutes}</td>
                              <td>{pricingPlan.pricePerSlotLength}</td>
                              <td>{pricingPlan.minSlotLengthInMinutes}</td>
                              <td>
                                {pricingPlan.maxSlotLengthInMinutes > 86400
                                  ? "---"
                                  : pricingPlan.maxSlotLengthInMinutes}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                <Button
                                  variant="danger"
                                  onClick={() => {
                                    setValues({
                                      ...values,
                                      pricingPlans: values.pricingPlans.filter(
                                        ({ id }) => id !== pricingPlan.id
                                      ),
                                    });
                                  }}
                                >
                                  Remove plan
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>

                      {pricingPlans !== undefined &&
                        availablePricingPlans(values.pricingPlans).length >
                          0 && (
                          <Row className="my-5">
                            <Col>
                              <FormSelect
                                name="selectedPricingPlan"
                                id="selectedPricingPlan"
                                value={values.selectedPricingPlan}
                                onChange={handleChange}
                              >
                                <option>Select a pricing plan to add</option>
                                {availablePricingPlans(values.pricingPlans).map(
                                  (pricingPlan) => (
                                    <option
                                      key={pricingPlan.id}
                                      value={pricingPlan.id}
                                    >
                                      {pricingPlan.displayName}
                                    </option>
                                  )
                                )}
                              </FormSelect>
                            </Col>
                            <Col>
                              <Button
                                variant="success"
                                disabled={values.selectedPricingPlan === ""}
                                onClick={() => {
                                  if (values.selectedPricingPlan === undefined)
                                    return;

                                  const intVal = parseInt(
                                    values.selectedPricingPlan,
                                    10
                                  );
                                  const plan = pricingPlans.find(
                                    (_) => _.id === intVal
                                  );

                                  if (plan) {
                                    setValues({
                                      ...values,
                                      pricingPlans: [
                                        ...values.pricingPlans,
                                        plan,
                                      ],
                                      selectedPricingPlan: undefined,
                                    });
                                  }
                                }}
                              >
                                Add Pricing plan
                              </Button>
                            </Col>
                          </Row>
                        )}
                    </Col>
                  </Row>

                  <hr />

                  <Button
                    onClick={() => submitForm()}
                    size="lg"
                    variant="success"
                    disabled={!isValid}
                  >
                    {values.id === -1
                      ? "Add availability"
                      : "Update Availability"}
                  </Button>
                  <Button
                    variant="link"
                    className="ms-3"
                    size="lg"
                    onClick={() => onCancel()}
                  >
                    Cancel
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </Formik>
  );
};
