import React from "react";
import DateFormat from "./DateFormat";

const dateTimeParser = new Intl.DateTimeFormat("en-GB", {
  //dateStyle: "medium",
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  hour: "2-digit",
  hourCycle: "h23",

  minute: "numeric",
  //timeStyle: "short",
  timeZone: "UTC",
});

const DateTime = ({ date }: { date: Date | undefined }) => (
  <DateFormat date={date} format={dateTimeParser} />
);

export default DateTime;
