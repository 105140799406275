import moment from "src/types/momentWithLocale";

export function getMinutesOfDaySafe(date?: Date) {
  if (date === undefined) return undefined;

  return moment(date).hours() * 60 + moment(date).minutes();
}

export function getStartOfDaySafe(date?: Date) {
  if (date === undefined) return undefined;

  return moment(date).startOf("day").toDate();
}

export const isDateEqualSafe = (a: Date | undefined, b: Date | undefined) => {
  if (a === undefined && b === undefined) return true;
  if (a === undefined) return false;
  if (b === undefined) return false;
  return moment(a).isSame(b);
};

export const makeUtc = (date: Date) => moment(date).utc(true).toDate();

export const makeFieldsUtc = (data: any, ...fields: string[]) =>
  fields.reduce(
    (result, key) => ({
      ...result,
      [key]: data[key] ? makeUtc(data[key]) : undefined,
    }),
    data
  );
