import { Formik } from "formik";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Page from "src/components/page";
import * as Yup from "yup";
import { FormFieldControl } from "../../components/formFieldControl";
import accountService from "../../services/account/account.service";
import tryAsyncToast from "../../hooks/tryAsyncToast";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { withAuthAndAuthRequired } from "../../hooks/withAuthorisationRequired";

interface CreateAdminForm {
  email: string;
  displayName: string;
}

const CreateFormSchema = Yup.object({
  email: Yup.string()
    .email("Email address must be a valid email")
    .required("Email address required"),
  displayName: Yup.string().required("Display name required"),
});

const CreateAdminPage = () => {
  const navigate = useNavigate();

  const createAdmin = React.useCallback(
    async (formValues: CreateAdminForm, { setSubmitting }) => {
      try {
        setSubmitting(true);
        await tryAsyncToast(accountService.createAdmin(formValues), {
          success: "Admin user created",
        });
        navigate("/account");
      } catch (error: any) {
        setSubmitting(false);
      }
    },
    [navigate]
  );

  return (
    <Page title="Create a new Administrator">
      <Helmet title="Create an administrator" />
      <Container fluid>
        <Row className="mt-5">
          <Col>
            <p>
              Provide the following information to create a new administrator
            </p>
            <p>
              The user will be created in Auth0 if they don't already exist and
              will be required to change their password when they first log in
            </p>
          </Col>
        </Row>

        <Row>
          <Col sm="6">
            <Formik<CreateAdminForm>
              onSubmit={createAdmin}
              initialValues={{
                email: "",
                displayName: "",
              }}
              validationSchema={CreateFormSchema}
            >
              {({ submitForm, isValid, isSubmitting }) => (
                <>
                  <FormFieldControl
                    fieldName="email"
                    label="Email address"
                    className="mb-4"
                  />
                  <FormFieldControl
                    fieldName="displayName"
                    label="Display Name"
                    className="mb-4"
                  />
                  <Button
                    variant="success"
                    size="lg"
                    disabled={!isValid || isSubmitting}
                    onClick={submitForm}
                  >
                    Create Admin
                  </Button>
                </>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </Page>
  );
};

export default withAuthAndAuthRequired(CreateAdminPage);
