import { array, model, property } from "decoverto";
import { PaginationResultDto } from "../searchableService";

@model()
class LockVersion {
  @property(() => Number)
  groupId!: number;

  @property(() => Number)
  protocolVersion!: number;

  @property(() => Number)
  protocolType!: number;

  @property(() => Number)
  orgId!: number;

  @property(() => Number)
  scene!: number;
}

@model()
export class Lock {
  @property(() => Number)
  date!: number;

  @property(() => String)
  lockAlias!: string;

  @property(() => Number)
  lockSound!: number;

  @property(() => String)
  modelNum!: string;

  @property(() => String)
  lockMac!: string;

  @property(() => Number)
  privacyLock!: number;

  @property(() => String)
  deletePwd!: string;

  @property(() => String)
  featureValue!: string;

  @property(() => String)
  adminPwd!: string;

  @property(() => Number)
  autoLockTime!: number;

  @property(() => String)
  lockKey!: string;

  @property(() => String)
  lockName!: string;

  @property(() => Number)
  resetButton!: number;

  @property(() => String)
  firmwareRevision!: string;

  @property(() => Number)
  tamperAlert!: number;

  @property(() => Number)
  specialValue!: number;

  @property(() => String)
  noKeyPwd!: string;

  @property(() => Number)
  passageMode!: number;

  @property(() => Number)
  timezoneRawOffset!: number;

  @property(() => Number)
  lockId!: number;

  @property(() => Number)
  electricQuantity!: number;

  @property(() => Number)
  lockFlagPos!: number;

  @property(() => Number)
  lockUpdateDate!: number;

  @property(() => Number)
  keyboardPwdVersion!: number;

  @property(() => String)
  aesKeyStr!: string;

  @property(() => String)
  hardwareRevision!: string;

  @property(() => LockVersion)
  lockVersion!: LockVersion;
}

@model()
export class LockListItem {
  @property(() => Number)
  date!: number;

  @property(() => Number)
  specialValue!: number;

  @property(() => String)
  lockAlias!: string;

  @property(() => String)
  noKeyPwd!: string;

  @property(() => Number)
  electricQuantityUpdateDate!: number;

  @property(() => String)
  lockMac!: string;

  @property(() => Number)
  passageMode!: number;

  @property(() => Number)
  timezoneRawOffset!: number;

  @property(() => Number)
  lockId!: number;

  @property(() => String)
  featureValue!: string;

  @property(() => Number)
  electricQuantity!: number;

  @property(() => Number)
  bindDate!: number;

  @property(() => String)
  lockData!: string;

  @property(() => Number)
  hasGateway!: number;

  @property(() => Number)
  keyboardPwdVersion!: number;

  @property(() => String)
  wirelessKeypadFeatureValue!: string;

  @property(() => LockVersion)
  lockVersion!: LockVersion;

  @property(() => String)
  lockName!: string;
}

@model()
export class LockListItemSearchResults
  implements PaginationResultDto<LockListItem>
{
  @property(array(() => LockListItem))
  public rows!: LockListItem[];

  @property(() => Number)
  public count!: number;
}
