import React from "react";
import { Breadcrumb, Card, Col, Row, Stack } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Page from "src/components/page";
import tryAsyncToast from "src/hooks/tryAsyncToast";
import { PricingPlanModel } from "src/services/pricingPlan/pricingPlan.model";
import pricingPlanService from "src/services/pricingPlan/pricingPlan.service";
import PricingPlanForm, { PricingPlanFormData } from "./pricingPlanForm";
import { withAuthAndAuthRequired } from "../../hooks/withAuthorisationRequired";
import { Link, useNavigate, useParams } from "react-router-dom";
import useAsyncRetry from "src/hooks/useAsyncRetry";
import { MdOutlineWarning } from "react-icons/md";

const EditPricingPlanPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = React.useState<
    PricingPlanFormData | undefined
  >(undefined);

  const { isLoading } = useAsyncRetry(async () => {
    if (!id) return undefined;

    const pricingPlan = await pricingPlanService.findById(id);

    setFormData(pricingPlan);

    return;
  }, []);

  const onSubmit = async (values: PricingPlanFormData) => {
    if (values.id) {
      return tryAsyncToast(
        pricingPlanService.update(values.id, values as PricingPlanModel),
        { success: "Pricing plan updated" }
      );
    } else {
      return tryAsyncToast(
        pricingPlanService.create(values as PricingPlanModel),
        { success: "Pricing Plan created" }
      );
    }
  };

  return (
    <Page>
      <Helmet title="Create Pricing Plan" />
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/pricing" }}>
          Pricing Plans
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{id}</Breadcrumb.Item>
      </Breadcrumb>

      {isLoading ? (
        <></>
      ) : (
        <>
          {formData ? (
            <>
              <Stack direction="horizontal" gap={3} className={"mb-3"}>
                <div>
                  <h2>{formData.displayName}</h2>
                </div>
              </Stack>

              <Row>
                <Col>
                  {formData !== undefined && (
                    <PricingPlanForm
                      formData={formData}
                      onSubmit={async (values) => {
                        await onSubmit(values);
                        setFormData(undefined);
                      }}
                      onCancel={() => navigate(`/pricing/`)}
                    />
                  )}
                </Col>
              </Row>
            </>
          ) : (
            <Card className="text-center mb-3">
              <Card.Body className="py-5">
                <MdOutlineWarning size="5em" className="mb-3" />
                <h3>There’s no pricing plan at this address</h3>
                <p>
                  Check the URL and try again, or use the pricing plans page to
                  find what you need.
                </p>
              </Card.Body>
            </Card>
          )}
        </>
      )}
    </Page>
  );
};

export default withAuthAndAuthRequired(EditPricingPlanPage);
