import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BookingModel } from "../../services/booking/booking.model";
import bookingService from "../../services/booking/booking.service";
import { HubModel } from "../../services/hub/hub.model";
import {
  PaginationResultDto,
  SearchableService,
  SearchProps,
} from "../../services/searchableService";
import BookingsTable from "../../components/BookingsTable";
import ButtonLink from "src/components/ButtonLink";

export const HubBookingsTab = ({ hub }: { hub: HubModel }) => {
  const service: SearchableService<BookingModel> = React.useMemo(
    () => ({
      async search(
        search: SearchProps
      ): Promise<PaginationResultDto<BookingModel> | undefined> {
        return bookingService.search(search, hub.id);
      },
      searchFilters(): { [p: string]: string } | undefined {
        return bookingService.searchFilters();
      },
    }),
    [hub.id]
  );

  return (
    <Container className="tabContainer" fluid>
      <Row>
        <Col className="flex-grow-1 p-3">
          <BookingsTable
            canSearch={false}
            numberPerPage={5}
            service={service}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ButtonLink className="btn-success" to={`/hub/${hub.id}/checkout`}>
            Make Booking
          </ButtonLink>
        </Col>
      </Row>
    </Container>
  );
};
