import "reflect-metadata";
import { BookingStatus } from "src/services/booking/booking.dto";
import { HubAllocationDto } from "src/services/hubAllocation/hubAllocation.dto";
import { PricingPlanDto } from "src/services/pricingPlan/pricingPlan.dto";
import { array, model, property } from "decoverto";
import { HubAllocationModel } from "../hubAllocation/hubAllocation.model";
import { OrderModel } from "../order/order.model";
import { PricingPlanModel } from "../pricingPlan/pricingPlan.model";
import { OrderDto } from "../order/order.dto";
import { AccountDto } from "../account/account.dto";
import { AccountModel } from "../account/account.model";
import { HubDto } from "../hub/hub.dto";
import { HubModel } from "../hub/hub.model";
import { PaginationResultDto } from "../searchableService";

export const BookingStatusText = {
  [BookingStatus.Ordered]: "Ordered",
  [BookingStatus.Completed]: "Completed",
  [BookingStatus.Cancelled]: "Cancelled",
  [BookingStatus.InProgress]: "In Progress",
  [BookingStatus.Incomplete]: "Incomplete",
};

@model()
export class BookingModel {
  @property(() => Number)
  id!: number;

  @property(() => Number)
  status!: BookingStatus;

  @property(() => Number)
  costWhenOrdered!: number;

  @property(() => Number)
  listCostWhenOrdered!: number;

  @property(() => OrderModel)
  order!: OrderDto;

  @property(() => Number)
  orderId!: number;

  @property(() => PricingPlanModel)
  pricingPlan!: PricingPlanDto;

  @property(() => Number)
  pricingPlanId!: number;

  @property(() => Date)
  startDateTime!: Date;

  @property(() => Date)
  endDateTime!: Date;

  @property(() => HubAllocationModel)
  hubAllocation!: HubAllocationDto;

  @property(() => Number)
  hubAllocationId!: number;

  @property(() => HubModel)
  hub!: HubDto;

  @property(() => Number)
  hubId!: number;

  @property(() => Date)
  createdAt!: Date;

  @property(() => AccountModel)
  createdByAccount?: AccountDto;

  @property(() => String)
  lockCode?: string | null;
}

@model()
export class PaginationBookingResponse
  implements PaginationResultDto<BookingModel>
{
  @property(array(() => BookingModel))
  public rows!: BookingModel[];

  @property(() => Number)
  public count!: number;
}
