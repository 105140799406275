import React from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { HubModel } from "../../services/hub/hub.model";
import SearchTable from "../../components/searchTable";
import { HubAllocationModel } from "../../services/hubAllocation/hubAllocation.model";
import hubAllocationService from "../../services/hubAllocation/hubAllocation.service";
import { overrideSearchService } from "../../services/searchableService";
import DateTime from "../../components/DateTime";
import {
  errorMessage,
  FormFieldControl,
} from "../../components/formFieldControl";
import * as Yup from "yup";
import { Formik } from "formik";
import hubService from "../../services/hub/hub.service";
import { HubAllocationTypeMap } from "../../services/hubAllocation/hubAllocation.dto";
import ModalButton from "../../components/modalButton";
import moment from "src/types/momentWithLocale";

interface AllocationTimeFormValues {
  startDate?: Date;
  startTime?: number;
  startDateTime?: Date;

  overlappingTimes?: HubAllocationModel[];

  endDateTime?: Date;
  endDate?: Date;
  endTime?: number;
  type?: number;
  _force?: boolean;
  /*
    type?: number
     */
}

const AllocateTimeFormValidation = Yup.object({
  startDate: Yup.date().required("A Start date is required"),
  startTime: Yup.number().required("A start time is required"),
  endDate: Yup.string().required("An end date is required"),
  endTime: Yup.number().required("An end time is required"),
  type: Yup.number().required("A type is required"),
});

const AllocateTimeForm = ({
  hubId,
  onSubmitFinished,
}: {
  hubId: number;
  onSubmitFinished?: () => void;
}) => {
  const [showAllowAvailability, setShowAllowAvailability] =
    React.useState<boolean>(false);

  const calculateFinalValues = (values: AllocationTimeFormValues) => {
    return {
      ...values,
      startDateTime: moment(values.startDate)
        .add(values.startTime as number, "minutes")
        .toDate(),
      endDateTime: moment(values.endDate as Date)
        .add(values.endTime as number, "minutes")
        .toDate(),
    };
  };

  const submitToServer = React.useCallback(
    async (values: AllocationTimeFormValues) => {
      await hubService.createAllocation({
        hubId,
        startDateTime: values.startDateTime as Date,
        endDateTime: values.endDateTime as Date,
        type: values.type as number,
        _force: values._force,
      });

      onSubmitFinished?.();
    },
    [hubId, onSubmitFinished]
  );

  const preSubmitChecks = React.useCallback(
    async (values: AllocationTimeFormValues) => {
      values = calculateFinalValues(values);

      const result = await hubService.validateAllocationDates({
        hubId,
        startDateTime: values.startDateTime as Date,
        endDateTime: values.endDateTime as Date,
      });

      if (result.overlappingTimes && result.overlappingTimes.length > 0) {
        throw new Error("Cannot allocate when there are overlapping times");
      }

      if (result.availability?.length === 0) {
        setShowAllowAvailability(true);
        return;
      }

      await submitToServer(values);
    },
    [hubId, submitToServer]
  );

  const onValidate = React.useCallback(
    async (values: AllocationTimeFormValues) => {
      if (moment(values.endDate as Date).diff(values.startDate as Date) < 0) {
        return {
          startDate:
            "The start date must be before or be the same as the end date",
        };
      }

      if (
        values.startTime !== undefined &&
        values.endTime !== undefined &&
        values.startTime >= values.endTime
      ) {
        return {
          startTime: "The start time must be before the end time",
        };
      }

      values = calculateFinalValues(values);

      const result = await hubService.validateAllocationDates({
        hubId,
        startDateTime: values.startDateTime as Date,
        endDateTime: values.endDateTime as Date,
      });

      if (result.overlappingTimes && result.overlappingTimes.length > 0) {
        return {
          overlappingTimes:
            "The selected dates & times overlap with existing allocated times",
        };
      }

      return {};
    },
    [hubId]
  );

  return (
    <Formik<AllocationTimeFormValues>
      onSubmit={preSubmitChecks}
      validationSchema={AllocateTimeFormValidation}
      validate={onValidate}
      validateOnChange={false}
      validateOnBlur
      initialValues={{
        startDate: new Date("2022-03-30T00:00:00"),
        startTime: 10 * 60,
        endDate: new Date("2022-03-30T00:00:00"),
        endTime: 14 * 60,
      }}
    >
      {({
        values,
        submitForm,
        errors,
        isValid,
        isSubmitting,
        setSubmitting,
      }) => (
        <>
          <Modal show={showAllowAvailability}>
            <Modal.Header>Confirm invalid times</Modal.Header>
            <Modal.Body>
              <p>
                The select times are not within the available usage for this
                hub, are you sure you wish to continue?
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="bg-secondary"
                onClick={() => {
                  setShowAllowAvailability(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                disabled={isSubmitting}
                onClick={async () => {
                  setSubmitting(true);
                  await submitToServer({
                    ...calculateFinalValues(values),
                    _force: true,
                  });
                  setSubmitting(false);
                  setShowAllowAvailability(false);
                }}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>

          <Row className="my-4">
            <Col md={6}>
              <FormFieldControl
                label="Type"
                showLabel
                fieldName="type"
                type="select"
              >
                <option value="">Select an allocation type</option>
                {Object.entries(HubAllocationTypeMap)
                  .filter(([idx, type]) => type !== "Booked")
                  .map(([idx, type]) => (
                    <option value={idx}>{type}</option>
                  ))}
              </FormFieldControl>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col sm={1} style={{ minWidth: "10rem" }}>
              <FormFieldControl
                label="Start date"
                showLabel
                fieldName="startDate"
                type="date"
              />
            </Col>
            <Col sm={1} style={{ minWidth: "10rem" }}>
              <FormFieldControl
                label="Start time"
                showLabel
                fieldName="startTime"
                type="time"
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={1} style={{ minWidth: "10rem" }}>
              <FormFieldControl
                label="End date"
                showLabel
                fieldName="endDate"
                type="date"
              />
            </Col>
            <Col sm={1} style={{ minWidth: "10rem" }}>
              <FormFieldControl
                label="End time"
                showLabel
                fieldName="endTime"
                type="time"
              />
            </Col>
          </Row>
          {errors.overlappingTimes && (
            <div className={errorMessage}>{errors.overlappingTimes}</div>
          )}
          <Button
            size="lg"
            variant="success"
            disabled={!isValid}
            onClick={submitForm}
          >
            Add
          </Button>
        </>
      )}
    </Formik>
  );
};

export const HubAllocationsTab = ({ hub }: { hub: HubModel }) => {
  const retry = React.useRef<() => void>();

  const service = React.useMemo(
    () =>
      overrideSearchService<HubAllocationModel>(hubAllocationService, {
        search: (originalService, search) => {
          return originalService.search({
            ...search,
            filter: [
              ...(search.filter || "").split(","),
              `hubId=${hub.id}`,
            ].join(","),
          });
        },
      }),
    [hub]
  );

  const onDelete = React.useCallback(async (id: number) => {
    await hubAllocationService.delete(id);

    retry.current?.();
  }, []);

  return (
    <Container className="tabContainer" fluid>
      <Row>
        <Col className="flex-grow-1 p-3">
          <SearchTable<HubAllocationModel>
            service={service}
            retryCallback={(callback) => (retry.current = callback)}
            defaultFilters={[
              "maintenance",
              "cleaning",
              "outOfOrder",
              "reserved",
              "past",
              "current",
              "future",
            ]}
            sortableColumns={["startDateTime", "endDateTime", "type", "id"]}
            defaultSortColumn={"startDateTime"}
            defaultSortAscending={false}
            canSearch={false}
            titles={{
              id: "Id",
              type: "Type",
              startDateTime: "Start",
              endDateTime: "End",
              createdBy: "Created By",
              controls: "",
            }}
            mappers={{
              startDateTime: ({ startDateTime }) => (
                <DateTime date={startDateTime} />
              ),
              endDateTime: ({ endDateTime }) => <DateTime date={endDateTime} />,
              createdBy: ({ createdBy }) => <>{createdBy.displayName}</>,
              type: ({ type }) => HubAllocationTypeMap[type],
              controls: (row) => (
                <div style={{ textAlign: "right" }}>
                  {row.booking === undefined ? (
                    <ModalButton
                      variant="danger"
                      text="Delete"
                      footer={({ hideModal }) => (
                        <>
                          <Button className="bg-secondary" onClick={hideModal}>
                            Cancel
                          </Button>
                          <Button
                            variant="danger"
                            onClick={async () => {
                              await onDelete(row.id);
                              hideModal();
                            }}
                          >
                            Delete
                          </Button>
                        </>
                      )}
                    >
                      Are you sure you wish to delete this time allocation?
                    </ModalButton>
                  ) : (
                    <Button>Manage booking ...</Button>
                  )}
                </div>
              ),
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <h3>Allocate time in hub</h3>
          <AllocateTimeForm
            hubId={hub.id}
            onSubmitFinished={() => {
              retry.current?.();
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};
