import { SearchProps } from "../services/searchableService";
import React, { ReactNode } from "react";
import { Card, Col, Form, Placeholder, Row } from "react-bootstrap";

const FilterCard = ({
  filterName,
  filterTitle,
  filterValue,
  setSearchParams,
  searchParams,
  canFilter = true,
  type = "value",
  selectValues = [],
}: {
  filterName: string;
  filterTitle: string;
  filterValue?: number | ReactNode;
  searchParams: SearchProps;
  canFilter?: boolean;
  type?: "value" | "select";
  selectValues?: [string | number, string][];

  setSearchParams: React.Dispatch<React.SetStateAction<SearchProps>>;
}) => {
  const convertFiltersToObject = (filter: string | undefined) => {
    if (filter === undefined) return {};
    if (filter.length === 0) return {};

    return Object.fromEntries(
      filter.split(",").map((compounded) => compounded.split("="))
    ) as Record<string, any>;
  };

  const convertFilterObjectToString = (filters: Record<string, any>) => {
    return Object.entries(filters)
      .map((e) => (e[1] !== undefined ? e.join("=") : e[0]))
      .join(",");
  };

  const splitFilters = React.useMemo(
    () => convertFiltersToObject(searchParams.filter),
    [searchParams.filter]
  );

  const isActive = React.useMemo(
    () => Object.keys(splitFilters).includes(filterName),
    [filterName, splitFilters]
  );

  const setIsActive = React.useCallback(
    (value: string | undefined) => {
      splitFilters[filterName] = value;
      setSearchParams((current) => ({
        ...current,
        filter: convertFilterObjectToString(splitFilters),
      }));
    },
    [filterName, setSearchParams, splitFilters]
  );

  const setIsNotActive = React.useCallback(() => {
    delete splitFilters[filterName];
    setSearchParams((current) => ({
      ...current,
      filter: convertFilterObjectToString(splitFilters),
    }));
  }, [filterName, setSearchParams, splitFilters]);

  const toggleIsActive = React.useCallback(() => {
    if (isActive) {
      setIsNotActive();
    } else {
      setIsActive(undefined);
    }
  }, [isActive, setIsActive, setIsNotActive]);

  return (
    <Col className="mb-3 " md={3} sm={12}>
      <Card
        onClick={() => type === "value" && canFilter && toggleIsActive()}
        className={isActive ? "bg-info" : ""}
      >
        <Card.Body>
          <Card.Title>{filterTitle}</Card.Title>
          {type === "value" && (
            <Row className="d-flex">
              <Col>
                {filterValue !== undefined ? (
                  <span className="fs-4">{filterValue}</span>
                ) : (
                  <Placeholder xs={2} size="lg"></Placeholder>
                )}
              </Col>
              {canFilter && (
                <Col className="align-self-center flex-grow-1 text-end">
                  <span className="btn-sm">
                    {isActive ? "disable" : "enable"} filter
                  </span>
                </Col>
              )}
            </Row>
          )}
          {type === "select" && (
            <Row>
              <Col>
                <Form.Select
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === "") {
                      setIsNotActive();
                    } else {
                      setIsActive(value);
                    }
                  }}
                  value={splitFilters[filterName]}
                >
                  <option value="">Select...</option>
                  {selectValues &&
                    selectValues.map((value) => (
                      <option value={value[0]} key={value[0]}>
                        {value[1]}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    </Col>
  );
};

export default FilterCard;
