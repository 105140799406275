import { BaseService } from "src/services/baseService";

import {
  decodeSearch,
  PaginationResultDto,
  SearchableService,
  SearchProps,
} from "../searchableService";
import { OrderModel, OrderModelSearchResults } from "./order.model";

export class OrderService
  extends BaseService<OrderModel>
  implements SearchableService<OrderModel>
{
  constructor() {
    super(OrderModel, "/order");
  }

  async findAll() {
    return this._getArray();
  }

  async findById(id: string) {
    return this._getParsed(`/${id}`);
  }

  async findWithBookings(id: string) {
    return this._getParsed(
      `/${id}?relations=bookings[hub[site]],account,createdBy,discountUsage[discount,membership]`
    );
  }

  async cancel(id: number) {
    return this._postUnParsed(`/${id}/cancel`);
  }

  async search(
    search: SearchProps,
    hubId?: number
  ): Promise<PaginationResultDto<OrderModel> | undefined> {
    const params = decodeSearch(search);

    return (
      await this._getParsed(`/search?${params}`, {}, OrderModelSearchResults)
    ).data;
  }

  searchFilters(): { [p: string]: string } | undefined {
    return {
      cancelled: "Cancelled orders",
      paid: "Paid orders",
      inProgress: "In-progress orders",
      refunded: "Refunded orders",
      incomplete: "Incomplete orders",
    };
  }

  async resendConfirmation(id: number) {
    return this._postUnParsed(`/${id}/resend-confirmation`);
  }

  async resendPayment(id: number) {
    return this._postUnParsed(`/${id}/resend-payment`);
  }
}

const orderService = new OrderService();

export default orderService;
