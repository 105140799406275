import React from "react";
import { Breadcrumb, Col, Row, Stack } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Page from "src/components/page";
import tryAsyncToast from "src/hooks/tryAsyncToast";
import { PricingPlanModel } from "src/services/pricingPlan/pricingPlan.model";
import pricingPlanService from "src/services/pricingPlan/pricingPlan.service";
import PricingPlanForm, { PricingPlanFormData } from "./pricingPlanForm";
import { withAuthAndAuthRequired } from "../../hooks/withAuthorisationRequired";
import { Link, useNavigate } from "react-router-dom";

const newPricingPlanData: PricingPlanFormData = {
  maxSlotLengthInMinutes: "",
  minSlotLengthInMinutes: "",
  pricePerSlotLength: "",
  usualPricePerSlotLength: undefined,
  displayName: "",
  type: "",
  slotLengthInMinutes: "",
};

const CreatePricingPlanPage = () => {
  const [formData] = React.useState<PricingPlanFormData | undefined>(
    newPricingPlanData
  );
  const navigate = useNavigate();

  const onSubmit = async (values: PricingPlanFormData) => {
    if (values.id) {
      return tryAsyncToast(
        pricingPlanService.update(values.id, values as PricingPlanModel),
        { success: "Pricing plan updated" }
      );
    } else {
      return tryAsyncToast(
        pricingPlanService.create(values as PricingPlanModel),
        { success: "Pricing Plan created" }
      );
    }
  };

  return (
    <Page>
      <Helmet title="Create Pricing Plan" />
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/pricing" }}>
          Pricing Plans
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Create</Breadcrumb.Item>
      </Breadcrumb>
      <Stack direction="horizontal" gap={3} className={"mb-3"}>
        <div>
          <h2>Create Pricing Plan</h2>
        </div>
      </Stack>

      <Row>
        <Col>
          {formData !== undefined && (
            <PricingPlanForm
              formData={formData}
              onSubmit={async (values) => {
                let plan = await onSubmit(values);
                navigate(`/pricing/${plan.id}`);
              }}
              onCancel={() => navigate(`/pricing/`)}
            />
          )}
        </Col>
      </Row>
    </Page>
  );
};

export default withAuthAndAuthRequired(CreatePricingPlanPage);
