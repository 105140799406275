import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Button, Card, Col, Placeholder, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Page from "src/components/page";
import { withAuthAndAuthRequired } from "../../hooks/withAuthorisationRequired";

const HomePage = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  return (
    <Page>
      <Helmet title="Home" />
      <Row>
        <Col>
          <h1>Home Page</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          {!isAuthenticated && (
            <Button
              variant="outline-success"
              onClick={() => loginWithRedirect()}
            >
              Log In
            </Button>
          )}
        </Col>
      </Row>
      <Row className="flex-wrap w-100 gy-4">
        <Col className="col-sm-4">
          <Card bg="success" text="white" className="h-100">
            <Card.Body>
              <Card.Title>Weekly highlights - Sheffield</Card.Title>
              <Placeholder as={Card.Text} animation="wave">
                <Placeholder xs={7} /> <Placeholder xs={4} />{" "}
                <Placeholder xs={4} /> <Placeholder xs={6} />{" "}
                <Placeholder xs={8} />
              </Placeholder>
              <Button variant="primary">View more</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col className="col-sm-4">
          <Card bg="danger" text="white" className="h-100">
            <Card.Body>
              <Card.Title>Weekly occupancy rate - Sheffield</Card.Title>
              <Placeholder as={Card.Text} animation="wave" size="lg">
                <Placeholder xs={3} /> <Placeholder xs={7} />{" "}
                <Placeholder xs={4} /> <Placeholder xs={6} />{" "}
                <Placeholder xs={8} />
              </Placeholder>
              <Button variant="primary">View more</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col className="col-sm-4">
          <Card bg="success" text="white" className="h-100">
            <Card.Body>
              <Card.Title>Weekly income - Sheffield </Card.Title>
              <Placeholder as={Card.Text} animation="wave">
                <Placeholder xs={7} size="lg" /> <Placeholder xs={4} />{" "}
                <Placeholder xs={4} /> <Placeholder xs={6} />{" "}
                <Placeholder xs={8} />
              </Placeholder>
              <Button variant="primary">View more</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <hr className="mt-5" />
      <Row className="flex-wrap w-100 gy-4 mt-2">
        <Col className="col-sm-6">
          <Card className="h-100">
            <Card.Body>
              <Card.Title>Hub Cleaning - Sheffield</Card.Title>
              <Placeholder as={Card.Text} animation="wave">
                <Placeholder xs={7} /> <Placeholder xs={4} />{" "}
                <Placeholder xs={4} /> <Placeholder xs={6} />{" "}
                <Placeholder xs={8} />
              </Placeholder>
              <Button variant="primary">View more</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col className="col-sm-6">
          <Card className="h-100">
            <Card.Body>
              <Card.Title>Urgent issue log - Sheffield TEST</Card.Title>
              <Placeholder as={Card.Text} animation="wave" size="lg">
                <Placeholder xs={3} /> <Placeholder xs={7} />{" "}
                <Placeholder xs={4} /> <Placeholder xs={6} />{" "}
                <Placeholder xs={8} />
                <Placeholder xs={3} /> <Placeholder xs={7} />{" "}
                <Placeholder xs={4} /> <Placeholder xs={6} />{" "}
                <Placeholder xs={8} />
              </Placeholder>
              <Button variant="primary">View more</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withAuthAndAuthRequired(HomePage);
