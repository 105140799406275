import { array, model, property } from "decoverto";
import { DiscountDto } from "src/services/discount/discount.dto";
import { PaginationResultDto } from "../searchableService";

@model()
export class DiscountModel implements DiscountDto {
  public constructor(init?: Partial<DiscountModel>) {
    Object.assign(this, init);
  }

  @property(() => Number)
  id!: number;

  @property(() => String)
  code!: string;

  @property(() => String)
  type!: string;

  @property(() => Number)
  value!: number;

  @property(() => Number)
  maximumNumberOfUses?: number | null;

  @property(() => Boolean)
  appliesOncePerCustomer!: boolean;

  @property(() => Date)
  startDate!: Date;

  @property(() => Date)
  endDate?: Date | null;

  @property(() => Boolean)
  isActive!: boolean;

  @property(() => String)
  status!: string;

  @property(() => Number)
  numberOfUses?: number;

  @property(() => Number)
  minimumAmount?: number | null;

  @property(() => String)
  notes!: string;
}

export type CreateDiscountDto = Omit<DiscountModel, "id" | "isActive">;

@model()
export class DiscountModelSearchResults
  implements PaginationResultDto<DiscountModel>
{
  @property(array(() => DiscountModel))
  public rows!: DiscountModel[];

  @property(() => Number)
  public count!: number;
}
