import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { Button, Col, Row } from "react-bootstrap";
import { FormFieldControl } from "../../components/formFieldControl";
import accountService from "../../services/account/account.service";
import SearchTable from "../../components/searchTable";
import {
  AccountModel,
  AccountSearchDto,
} from "../../services/account/account.model";
import { overrideSearchService } from "../../services/searchableService";
import { TabProps } from "./index";

export interface AccountFormTabValues {
  account?: AccountModel;
  guestAccountEmailAddress?: string;
}

interface CheckoutAccountFormValues {
  account?: AccountModel;
  accountId: string;
  emailAddress: string;
}

const checkoutAccountFormSchema = Yup.object({
  accountId: Yup.string().notRequired(),
  emailAddress: Yup.string().notRequired().email(),
});

const CheckoutAccountForm = ({ context, updateContext }: TabProps) => {
  const search = React.useMemo(
    () =>
      overrideSearchService(accountService, {
        search: (originalService, search) => {
          return accountService.simpleSearch(search);
        },
      }),
    []
  );

  const onSubmit = React.useCallback(
    (accountFormValues: CheckoutAccountFormValues) => {
      updateContext({
        ...context,
        accountTabValues: {
          account: accountFormValues.account,
          guestAccountEmailAddress: accountFormValues.emailAddress,
        },
      });
    },
    [context, updateContext]
  );

  return (
    <Formik<CheckoutAccountFormValues>
      onSubmit={onSubmit}
      initialValues={{
        account: context.accountTabValues?.account as AccountModel,
        accountId: `${context.accountTabValues?.account?.id}` || "-1",
        emailAddress: context.accountTabValues?.guestAccountEmailAddress || "",
      }}
      validationSchema={checkoutAccountFormSchema}
    >
      {({ setFieldValue, submitForm }) => (
        <Row className="bg-white p-4">
          <Col>
            <h3>Choose account or enter email address</h3>
            <p>
              To create a booking either use an existing account, or enter an
              email address to create a booking for a guest
            </p>
            <p>Note: Accounts will be created for guests, to keep a record</p>

            <SearchTable<AccountSearchDto>
              titles={{
                id: "Account Id",
                displayName: "Display Name",
                email: "Email Address",
              }}
              service={search}
              canFilter={false}
              onSelectRow={(row) => {
                setFieldValue("accountId", row.id);
                setFieldValue("account", row);
                setFieldValue("emailAddress", row.email);
              }}
            />

            <FormFieldControl
              className="my-3 w-50"
              fieldName="emailAddress"
              placeholder="or enter an email address"
              label="Email address"
              onChange={() => setFieldValue("account", undefined)}
            />
          </Col>
          <Row className="mt-4">
            <Col>
              <Button variant="success" size="lg" onClick={submitForm}>
                Next Step
              </Button>
            </Col>
          </Row>
        </Row>
      )}
    </Formik>
  );
};

export default CheckoutAccountForm;
