import React from "react";
import { Badge, Col, Image, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import ButtonLink from "src/components/ButtonLink";
import Page from "src/components/page";
import SearchTable from "src/components/searchTable";
import { HubModel } from "src/services/hub/hub.model";
import hubService from "src/services/hub/hub.service";
import { withAuthAndAuthRequired } from "../../hooks/withAuthorisationRequired";

const HubIndexPage = () => {
  const navigate = useNavigate();

  return (
    <Page>
      <Helmet title="Hub" />
      <SearchTable<HubModel>
        caption="Hubs"
        service={hubService}
        action={
          <div>
            <ButtonLink variant="success" to="/hub/create">
              Add Hub
            </ButtonLink>
          </div>
        }
        titles={{
          details: "Hub",
          location: "Hub location",
          isActive: "Status",
        }}
        mappers={{
          details: (hub) => (
            <>
              <Row>
                <Col sm="auto">
                  <Image
                    src={hub.featuredMedia?.url}
                    roundedCircle
                    width="50px"
                    height="50px"
                  />
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <b>{hub.name}</b>
                    </Col>
                  </Row>
                  <Row>
                    <Col>{hub.overview}</Col>
                  </Row>
                </Col>
              </Row>
            </>
          ),
          location: (hub) => hub.site?.name,
          isActive: (hub) => (
            <Badge bg={hub.isActive ? "success" : "secondary"}>
              {hub.isActive ? "Active" : "Disabled"}
            </Badge>
          ),
        }}
        onSelectRow={(hub) => navigate(`/hub/${hub.id}`)}
      />
    </Page>
  );
};

export default withAuthAndAuthRequired(HubIndexPage);
