import { HubAllocationType } from "src/services/hubAllocation/hubAllocation.dto";
import { HubModel } from "src/services/hub/hub.model";
import { array, model, property } from "decoverto";
import { BookingModel } from "../booking/booking.model";
import { HubDto } from "../hub/hub.dto";
import { AccountModel } from "../account/account.model";
import { AccountDto } from "../account/account.dto";
import { PaginationResultDto } from "../searchableService";

@model()
export class HubAllocationModel {
  @property(() => Date)
  allocationTimeout!: Date;

  @property(() => Date)
  endDateTime!: Date;

  @property(() => HubModel)
  hub!: HubDto;

  @property(() => Number)
  hubId!: number;

  @property(() => Number)
  id!: number;

  @property(() => Date)
  startDateTime!: Date;

  @property(() => Number)
  type!: HubAllocationType;

  @property(() => AccountModel)
  createdBy!: AccountDto;

  @property(() => Number)
  createdById!: number;

  @property(() => BookingModel)
  booking!: BookingModel;
}

@model()
export class HubAllocationSearchResults
  implements PaginationResultDto<HubAllocationModel>
{
  @property(array(() => HubAllocationModel))
  public rows!: HubAllocationModel[];

  @property(() => Number)
  public count!: number;
}
