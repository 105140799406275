import React, { useCallback, useMemo } from "react";
import { Breadcrumb, Button, Stack } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Page from "src/components/page";
import { withAuthAndAuthRequired } from "../../hooks/withAuthorisationRequired";
import { MembershipModel } from "src/services/membership/membership.model";
import membershipService from "src/services/membership/membership.service";
import tryAsyncToast from "src/hooks/tryAsyncToast";
import { MembershipForm } from "src/pages/membership/membership.form";
import useAsyncRetry from "src/hooks/useAsyncRetry";
import { Link, useNavigate, useParams } from "react-router-dom";
import ModalButton from "../../components/modalButton";

const MembershipEditPage = () => {
  const { id: idStr } = useParams();

  const navigation = useNavigate();

  const id = useMemo(() => {
    const value = idStr && parseInt(idStr, 10);
    return Number.isNaN(value) ? undefined : value;
  }, [idStr]);

  const handleSubmit = async (membership: MembershipModel) => {
    if (!id) return;
    await tryAsyncToast(membershipService.update(id, membership), {
      success: "Membership plan was updated",
    });
  };

  const { value: membership } = useAsyncRetry(async () => {
    if (!id) return null;
    return await membershipService.findById(id);
  }, [id]);

  const deleteMembership = useCallback(
    () =>
      tryAsyncToast(
        async () => {
          if (!id) return;
          await membershipService.delete(id);
          navigation("/membership");
        },
        {
          success: "Membership plan was deleted",
        }
      ),
    [id, navigation]
  );

  return (
    <Page>
      <Helmet title="Edit Membership Plan" />
      <>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/membership" }}>
            Memberships
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{id}</Breadcrumb.Item>
        </Breadcrumb>
      </>
      <Stack direction="horizontal" gap={3} className={"mb-3"}>
        <div>
          <h2>{membership?.name}</h2>
        </div>
        <div className="ms-auto" />
        {(membership?.membershipAccounts === undefined ||
          membership?.membershipAccounts.length === 0) && (
          <>
            <ModalButton
              title="Confirm Deletion"
              text="Delete Membership"
              variant="outline-danger"
              className=""
              footer={({ hideModal }) => (
                <>
                  <Button variant="secondary" onClick={() => hideModal()}>
                    Close
                  </Button>
                  <Button
                    variant="danger"
                    onClick={async () => {
                      await deleteMembership();
                    }}
                  >
                    Confirm deletion
                  </Button>
                </>
              )}
            >
              <p>Are you sure you wish to cancel this membership plan?</p>
            </ModalButton>
          </>
        )}
        {membership?.stripeProductId && (
          <div className="">
            <a
              href={`https://dashboard.stripe.com/${
                process.env.REACT_APP_API_BASE !==
                  "https://api.workfromhub.co.uk" && "test/"
              }products/${membership?.stripeProductId}`}
              target={"_blank"}
              rel="noreferrer"
            >
              <Button
                style={{
                  backgroundColor: "#625afa",
                  borderColor: "#625afa",
                }}
              >
                View in Stripe
              </Button>
            </a>
          </div>
        )}
      </Stack>

      {membership && (
        <MembershipForm onSubmit={handleSubmit} initialValues={membership} />
      )}
    </Page>
  );
};

export default withAuthAndAuthRequired(MembershipEditPage);
