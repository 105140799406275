import { model, property } from "decoverto";
import { MembershipStatisticsDto } from "src/services/membership/membershipStatistics.dto";

@model()
export class MembershipStatisticsModel implements MembershipStatisticsDto {
  public constructor(init?: Partial<MembershipStatisticsModel>) {
    Object.assign(this, init);
  }

  @property(() => Number)
  activeMembers!: number;

  @property(() => Number)
  cancelledMembers!: number;

  @property(() => Number)
  monthlyRevenue!: number;

  @property(() => Number)
  activeMembershipPlans!: number;
}
