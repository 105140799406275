import React from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { MediaFieldControl } from "src/components/mediaFieldControl";
import MediaFieldGallery from "src/components/mediaFieldGallery";
import Page from "src/components/page";
import tryAsyncToast from "src/hooks/tryAsyncToast";
import useAsyncRetry from "src/hooks/useAsyncRetry";
import SiteForm, { SiteFormValues } from "src/pages/site/siteForm";
import { SiteModel } from "src/services/site/site.model";
import siteService from "src/services/site/site.service";
import { withAuthAndAuthRequired } from "../../hooks/withAuthorisationRequired";

const EditSitePage = () => {
  const { id } = useParams();

  const idValue = React.useMemo(() => {
    if (id === undefined) return undefined;
    const idValue = parseInt(id, 10);
    if (isNaN(idValue)) return undefined;
    return idValue;
  }, [id]);

  const { value: site, setValue } = useAsyncRetry<
    SiteModel | undefined
  >(async () => {
    if (idValue === undefined) return undefined;
    return siteService.find(idValue);
  }, [idValue]);

  const refreshFeaturedMedia = React.useCallback(async () => {
    if (site === undefined) return;

    const { featuredMedia, featuredMediaId } = await siteService.find(site.id);
    setValue({
      ...site,
      featuredMedia,
      featuredMediaId,
    });
  }, [setValue, site]);

  const refreshMedia = React.useCallback(async () => {
    if (site === undefined) return;

    const { media } = await siteService.find(site.id);
    setValue({
      ...site,
      media,
    });
  }, [setValue, site]);

  const onSubmit = React.useMemo(() => {
    if (idValue === undefined) return async () => {};

    return async (newValues: SiteFormValues) => {
      try {
        await tryAsyncToast(siteService.update(idValue, newValues), {
          success: "Site updated",
        });

        setValue({
          ...(site as SiteModel),
          ...newValues,
        });
      } catch (e: any) {}
    };
  }, [site, idValue, setValue]);

  return (
    <Page title="Edit Site">
      <Helmet title="Edit location" />
      {site && (
        <>
          <SiteForm site={site} onSubmit={onSubmit} />

          <hr className="mt-5" />

          <Container fluid className="p-0">
            <Row sm={12} className="mt-5">
              <Col>
                <Card className="h-100">
                  <Card.Body>
                    <Card.Title>Featured Image or Media</Card.Title>
                    <MediaFieldControl
                      initialMediaField={site.featuredMedia}
                      onSave={async (values) => {
                        if (site.id === undefined) return;
                        await siteService.updateFeaturedMedia(site.id, values);
                        await refreshFeaturedMedia();
                      }}
                      onRemove={async () => {
                        await siteService.updateFeaturedMedia(site.id, null);
                        await refreshFeaturedMedia();
                      }}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row className="mt-5">
              <Col>
                <Card className="h-100">
                  <Card.Body>
                    <Card.Title>Images &amp; Media Gallery</Card.Title>
                    <Form.Group>
                      <Row sm={2} md={4} lg={6} xl={8}>
                        <MediaFieldGallery
                          values={site.media!}
                          onCreate={async (media) => {
                            await siteService.createMedia(site.id, media);
                            await refreshMedia();
                          }}
                          onSave={async (media, mediaId) => {
                            await siteService.updateMedia(
                              site.id,
                              mediaId,
                              media
                            );
                            await refreshMedia();
                          }}
                          onRemove={async (mediaId) => {
                            await siteService.deleteMedia(site.id, mediaId);
                            await refreshMedia();
                          }}
                        />
                      </Row>
                    </Form.Group>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </Page>
  );
};

export default withAuthAndAuthRequired(EditSitePage);
