import { HubAvailabilityModel } from "../../services/hubAvailability/hubAvailability.model";
import { HubAllocationModel } from "../../services/hubAllocation/hubAllocation.model";
import moment from "src/types/momentWithLocale";

const validateAvailability = (
  values: {
    selectedDate?: Date;
    startTime?: number;
    endTime?: number;
  },
  availabilities?: HubAvailabilityModel[]
) => {
  const warnings: any = {};
  const errors: any = {};

  if (values.selectedDate === undefined) {
    errors.selectedDate = "Please select a date";
  } else if (moment(values.selectedDate).isValid()) {
    const dayOfWeek = moment(values.selectedDate).day();

    const validAvailabilities =
      availabilities === undefined
        ? []
        : availabilities.filter(
            (availability) => availability.dayOfWeek === dayOfWeek
          );

    if (validAvailabilities.length === 0) {
      warnings.selectedDate = "The selected date is outside normal hours";
    } else {
      if (values.startTime) {
        const timeAvailability = validAvailabilities.filter(
          (a) =>
            values.startTime! >= a.startTime && values.startTime! <= a.endTime
        );

        if (timeAvailability.length === 0) {
          warnings.startTime = "The start time is outside normal hours";
        }
      } else {
        errors.startTime = "Start time is required";
      }

      if (values.endTime) {
        const timeAvailability = validAvailabilities.filter(
          (a) => values.endTime! >= a.startTime && values.endTime! <= a.endTime
        );

        if (timeAvailability.length === 0) {
          warnings.endTime = "The end time is outside normal hours";
        }
      } else {
        errors.endTime = "End time is required";
      }

      let length = values.endTime! - values.startTime!;
      if (length < 0) length += 60 * 24;

      const lengthAvailability =
        length === 0
          ? []
          : validAvailabilities.filter((a) => {
              return a.pricingPlans.some((pp) => {
                return length % pp.slotLengthInMinutes === 0;
              });
            });

      if (length === 0 && lengthAvailability.length === 0) {
        warnings.selectedTime = "Irregular length specified";
      }
    }
  }

  return { warnings, errors };
};

const validateAgainstExistingAllocations = (
  values: {
    selectedDate?: Date;
    startTime?: number;
    endTime?: number;
  },
  allocations?: HubAllocationModel[]
) => {
  const errors: any = {};
  let overlapping: HubAllocationModel[] = [];

  if (
    values.startTime &&
    values.endTime &&
    allocations &&
    allocations.length > 0
  ) {
    const paddedEndTime = values.endTime + 30;

    overlapping = allocations.filter((allocation) => {
      // They are stored on the server in UTC so we need to convert them back to UTC
      const startUtc = moment(allocation.startDateTime).utc(false);
      const endUtc = moment(allocation.endDateTime).utc(false);

      const allocationStartTime = startUtc.hours() * 60 + startUtc.minutes();
      const allocationEndTime = endUtc.hours() * 60 + endUtc.minutes() + 30;
      return (
        values.startTime! < allocationEndTime &&
        paddedEndTime > allocationStartTime
      );
    });

    if (overlapping.length === 1) {
      errors.selectedTime =
        "The selected times conflict with an existing booking";
    } else if (overlapping.length > 1) {
      errors.selectedTime =
        "The selected times conflict with existing bookings";
    }
  }

  return { errors, overlapping };
};

const validateAllowedMinutes = (values: {
  startTime?: number;
  endTime?: number;
}) => {
  const errors: any = {};

  const startMinutes = moment()
    .startOf("day")
    .add(values.startTime!, "minutes")
    .minutes();

  const endMinutes = moment()
    .startOf("day")
    .add(values.endTime!, "minutes")
    .minutes();

  const allowedMinutes = [0, 30];
  const allowedMinutesError = "Select an hour or half past";
  if (!allowedMinutes.includes(startMinutes)) {
    errors.startTime = allowedMinutesError;
  }
  if (!allowedMinutes.includes(endMinutes)) {
    errors.endTime = allowedMinutesError;
  }
  return { errors };
};

export const validateDateTimeSelection = (
  values: {
    selectedDate?: Date;
    startTime?: number;
    endTime?: number;
  },
  availabilities?: HubAvailabilityModel[],
  allocations?: HubAllocationModel[]
) => {
  const result = {
    ...validateAvailability(values, availabilities),
    ...validateAgainstExistingAllocations(values, allocations),
    ...validateAllowedMinutes(values),
  };

  return {
    newFormWarnings: result.warnings,
    errors: result.errors,
    overlapping: result.overlapping,
  };
};
