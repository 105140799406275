import {
  Alert,
  Badge,
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  InputGroup,
  Row,
  Stack,
} from "react-bootstrap";
import ModalButton from "src/components/modalButton";
import ButtonLink from "src/components/ButtonLink";
import React, { useState } from "react";
import { MembershipModel } from "src/services/membership/membership.model";
import { MediaFieldControl } from "../../components/mediaFieldControl";
import { MediaModel } from "../../services/media/media.model";
import mediaService from "../../services/media/media.service";
import { css } from "@emotion/css";

export const MembershipForm = ({
  initialValues,
  onSubmit,
}: {
  initialValues?: MembershipModel;
  onSubmit: (membership: MembershipModel) => Promise<void>;
}) => {
  const [validated, setValidated] = useState(false);
  const [validationErrors, setValidationErrors] = useState(false);

  const [planName, setPlanName] = useState(initialValues?.name || "");
  const [description, setDescription] = useState(
    initialValues?.description || ""
  );
  const [planCost, setPlanCost] = useState<undefined | number>(
    initialValues?.cost
  );
  const [discountValue, setDiscountValue] = useState<undefined | number>(
    initialValues?.value
  );

  const [benefits, setBenefits] = useState(initialValues?.benefits || "");

  const [media, setMedia] = useState<MediaModel | null | undefined>(
    initialValues?.media
  );

  const [isPublic, setIsPublic] = useState<boolean | undefined>(
    initialValues?.isPublic || false
  );

  const [paymentUrl, setPaymentUrl] = useState(initialValues?.paymentUrl || "");

  const handleSubmit = React.useCallback(
    async (event: any) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        setValidationErrors(true);
      } else {
        setValidationErrors(false);

        // TODO: mockup prevent nav on success
        event.preventDefault();
        event.stopPropagation();

        const membership = new MembershipModel();

        let mediaResponse: MediaModel | undefined = undefined;

        if (media) {
          if (initialValues?.media?.id) {
            mediaResponse = await mediaService.update(
              initialValues.media.id,
              media
            );
          } else {
            mediaResponse = await mediaService.create(media);
          }
        }

        membership.name = planName;
        membership.description = description;
        membership.value = discountValue as number;
        membership.type = "percentage";
        membership.cost = planCost as number;
        membership.benefits = benefits;
        membership.media = undefined;
        membership.mediaId = mediaResponse ? mediaResponse.id : null;
        membership.isPublic = isPublic;
        membership.paymentUrl = isPublic ? paymentUrl : "";

        await onSubmit(membership);
      }

      setValidated(true);
    },
    [
      benefits,
      description,
      discountValue,
      initialValues?.media?.id,
      media,
      onSubmit,
      planCost,
      planName,
      isPublic,
      paymentUrl,
    ]
  );

  return (
    <>
      {validationErrors && (
        <Alert variant="danger">
          <Alert.Heading>Membership plan errors</Alert.Heading>
          Please resolve the errors with this membership plan
        </Alert>
      )}

      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col md={8}>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Plan name</Card.Title>
                <InputGroup className="mb-3">
                  <Form.Control
                    defaultValue={planName}
                    onChange={(e) => setPlanName(e.target.value)}
                    required
                    maxLength={32}
                    placeholder="e.g. WorkfromHub+"
                    aria-label="discount code"
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Please choose membership plan name.
                  </Form.Control.Feedback>
                </InputGroup>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Description{" "}
                    <Badge bg="light" text="dark">
                      Optional
                    </Badge>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    defaultValue={description}
                    onChange={(e) => setDescription(e.target.value)}
                    type="textarea"
                  />
                </Form.Group>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Is public</Form.Label>
                  </Col>
                  <Col>
                    <Form.Check
                      defaultChecked={isPublic}
                      onChange={(e) => setIsPublic(e.target.checked)}
                    />
                  </Col>
                </Row>
                {isPublic && (
                  <Row>
                    <Form.Group className="mb-3">
                      <Form.Label>Stripe Payment Link</Form.Label>
                      <Form.Control
                        required
                        defaultValue={paymentUrl}
                        onChange={(e) => setPaymentUrl(e.target.value)}
                        type="url"
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        A valid Stripe Payment Link is required for a public
                        Plan
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                )}
              </Card.Body>
            </Card>

            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Pricing</Card.Title>
                <Form.Label>Price</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text>£</InputGroup.Text>
                  <Form.Control
                    disabled={initialValues === undefined ? false : true}
                    defaultValue={planCost}
                    onChange={(e) => setPlanCost(parseFloat(e.target.value))}
                    required
                    type="number"
                    max={100}
                    step={0.01}
                    min={0}
                    placeholder="0.00"
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Please set a membership plan cost.
                  </Form.Control.Feedback>
                </InputGroup>
                <Form.Label>Billing period</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  className="mb-3"
                >
                  <option value="monthly">Monthly</option>
                </Form.Select>{" "}
              </Card.Body>
            </Card>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Benefits</Card.Title>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Discount type</Form.Label>
                    </Form.Group>
                    <div className="d-grid gap-2">
                      <ButtonGroup className="mb-2">
                        <Button variant={"primary"}>Percentage</Button>
                      </ButtonGroup>
                    </div>
                  </Col>
                  <Form.Group as={Col}>
                    <Form.Label>Discount value</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        defaultValue={discountValue}
                        onChange={(e) =>
                          setDiscountValue(parseFloat(e.target.value))
                        }
                        required
                        type="number"
                        max={100}
                        step={0.01}
                        min={0}
                        placeholder="0"
                        aria-label="Discount value"
                      />
                      <InputGroup.Text>%</InputGroup.Text>
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Please choose a discount value.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Row>

                <Form.Group className="mb-3">
                  <Form.Label>
                    Membership features{" "}
                    <Badge bg="light" text="dark">
                      Optional
                    </Badge>
                  </Form.Label>
                  {/* change to match up with feature list in stripe, array of
                      strings, each being a benefit. stripe doesnt currenty
                      support this but would be good to align for future */}
                  <Form.Control
                    as="textarea"
                    rows={4}
                    defaultValue={benefits}
                    onChange={(e) => setBenefits(e.target.value)}
                    type="textarea"
                  />
                </Form.Group>
              </Card.Body>
            </Card>

            <Card className="mb-3">
              <Card.Body>
                <Card.Title>
                  Image
                  <Badge
                    bg="light"
                    text="dark"
                    className={css`
                      font-size: 0.6em;
                    `}
                  >
                    Optional
                  </Badge>
                </Card.Title>
                <Row className="justify-content-evenly">
                  <Col sm={4}>
                    <MediaFieldControl
                      autoUpdateMedia={false}
                      initialMediaField={media ? media : undefined}
                      onRemove={async () => setMedia(null)}
                      onSave={async (media) => setMedia(media)}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="mb-3" style={{ backgroundColor: "#edf1f5" }}>
              <Card.Header>
                <Card.Title>Summary</Card.Title>
              </Card.Header>
              <Card.Body>
                {planName.length > 0 && <Card.Title>{planName}</Card.Title>}

                {planCost && (
                  <Card.Text>
                    <b>£{planCost} per month</b>
                  </Card.Text>
                )}
                {discountValue && (
                  <Card.Text>{discountValue}% off all bookings</Card.Text>
                )}

                {description && <Card.Text>{description}</Card.Text>}

                <h6>Additional benefits</h6>
                <Card.Text>{benefits}</Card.Text>
              </Card.Body>
              <Card.Footer>
                {isPublic ? (
                  <Card.Text>Is visible in App</Card.Text>
                ) : (
                  <Card.Text>Will be hidden in App</Card.Text>
                )}
              </Card.Footer>
            </Card>

            {initialValues?.id && !initialValues.stripeProductId && (
              <Alert variant="danger" className="mb-3">
                This membership plan does not have an associated stripe product
              </Alert>
            )}

            <Alert variant="warning">
              Pricing cannot currently be changed once the membership has been
              created.
            </Alert>

            {initialValues?.membershipAccounts &&
              initialValues?.membershipAccounts.length > 0 && (
                <Alert variant="warning">
                  This membership cannot be deleted as it has been used.
                </Alert>
              )}
          </Col>
        </Row>

        <hr />

        <Stack direction="horizontal" gap={3} className={"mb-3"}>
          <div>
            <ModalButton
              variant="outline-secondary"
              title="Leave page with unsaved changes"
              text="Discard"
              footer={({ hideModal }) => (
                <>
                  <Button onClick={hideModal} variant="outline-secondary">
                    Stay
                  </Button>
                  <ButtonLink to="/discount" variant="danger">
                    Leave
                  </ButtonLink>
                </>
              )}
            >
              Leaving this page will remove all unsaved changes.
            </ModalButton>
          </div>
          <div className="ms-auto">
            <Button variant="success" type="submit">
              {initialValues ? "Save changes" : "Create Membership Plan"}
            </Button>
          </div>
        </Stack>
      </Form>
    </>
  );
};
