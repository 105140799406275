import React from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Modal,
  Row,
  Stack,
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import InlineTextEdit from "src/components/inlineTextEdit";
import Page from "src/components/page";
import tryAsyncToast from "src/hooks/tryAsyncToast";
import useAsyncRetry from "src/hooks/useAsyncRetry";
import useToggle from "../../hooks/useToggle";
import { SiteModel } from "src/services/site/site.model";
import siteService from "src/services/site/site.service";
import { SiteImages } from "./siteImages";
import { SiteLocation, SiteLocationForm } from "./siteLocation";
import { Helmet } from "react-helmet-async";
import { withAuthAndAuthRequired } from "../../hooks/withAuthorisationRequired";
import { MdOutlineWarning } from "react-icons/md";
import { SiteSummary } from "./site.summary";

const ViewSitePage = () => {
  const { id } = useParams();

  const { value: site, setValue } = useAsyncRetry(async () => {
    if (id === undefined) return undefined;
    const idValue = parseInt(id, 10);
    if (isNaN(idValue)) return undefined;

    return siteService.find(idValue);
  });

  const updateSite = React.useCallback(
    (partialSite: Partial<SiteModel>) => {
      if (site === undefined) return;

      setValue({
        ...site,
        ...partialSite,
      });
    },
    [setValue, site]
  );

  const patchSite = React.useCallback(
    async (partialSite: Partial<SiteModel>, success: string) => {
      if (site === undefined) return;

      await tryAsyncToast(siteService.update(site.id, partialSite), {
        success,
      });

      updateSite(partialSite);
    },
    [site, updateSite]
  );

  const [showIsActiveModal, toggleIsActiveModal] = useToggle(false);

  return (
    <Page>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/site" }}>
          Locations
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{id}</Breadcrumb.Item>
      </Breadcrumb>

      {site ? (
        <>
          <Helmet title={`Location - ${site?.name}`} />
          <Stack direction="horizontal" gap={3} className={"mb-3"}>
            <div>
              <h2>{site?.name}</h2>
            </div>
          </Stack>
          <Row>
            <Col md={8}>
              <Card className="mb-3">
                <Card.Body>
                  <Card.Title>Status</Card.Title>
                  <div className="d-flex mt-2">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="isActive"
                        id="siteToggleOnline"
                        value="online"
                        checked={site.isActive}
                        onChange={toggleIsActiveModal.on}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="siteToggleOnline"
                      >
                        Location online (is active)
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="isActive"
                        id="siteToggleOffline"
                        value="offline"
                        checked={!site.isActive}
                        onChange={toggleIsActiveModal.on}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="siteToggleOffline"
                      >
                        Location offline (inactive, unavailable for booking)
                      </label>
                    </div>
                  </div>

                  <Modal
                    show={showIsActiveModal}
                    onHide={toggleIsActiveModal.off}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>
                        {site.name}:{" "}
                        {site.isActive
                          ? "Take offline (inactive)"
                          : "Make online (active)"}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {site.isActive
                        ? `Please confirm that you wish to take this location offline (inactive). 
              Doing so will mean that this location will be immediately unavailable for bookings`
                        : `Please confirm that you wish to make this location online (active). 
              Doing so will mean that this location will be immediately made available for bookings`}
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={toggleIsActiveModal.off}
                      >
                        Close
                      </Button>
                      <Button
                        variant="danger"
                        onClick={async () => {
                          await patchSite(
                            { isActive: !site.isActive },
                            "Location active status updated"
                          );
                          toggleIsActiveModal.off();
                        }}
                      >
                        Confirm
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </Card.Body>
              </Card>

              <Card className="mb-3">
                <Card.Body>
                  <InlineTextEdit
                    fieldTitle="Name"
                    value={site.name}
                    onSubmit={(name) => patchSite({ name }, "Name updated")}
                    required
                    placeHolder="Name of site"
                  />

                  <InlineTextEdit
                    fieldTitle="Summary"
                    value={site.summary}
                    onSubmit={(summary) =>
                      patchSite({ summary }, "Summary updated")
                    }
                    required
                    placeHolder="Short, quick, summary of site"
                  />

                  <InlineTextEdit
                    fieldTitle="Description"
                    value={site.description}
                    onSubmit={(description) =>
                      patchSite({ description }, "Description updated")
                    }
                    required
                    placeHolder="Detailed description for Site"
                    inputAs="textarea"
                  />
                </Card.Body>
              </Card>

              <SiteImages
                site={site}
                onSave={async (updatedSite) => {
                  updateSite(updatedSite);
                }}
              />
              <SiteLocation
                site={site}
                onSubmit={(location: SiteLocationForm) =>
                  patchSite(location, "Location Updated")
                }
              />
              <InlineTextEdit
                fieldTitle="Notes"
                inputAs="textarea"
                value={site.notes}
                onSubmit={(notes) => patchSite({ notes }, "Notes updated")}
                placeHolder="Administration notes for the site"
                DisplayAs="pre"
              />
            </Col>
            <Col md={4}>
              <SiteSummary site={site} />
            </Col>
          </Row>
        </>
      ) : (
        <Card className="text-center mb-3">
          <Card.Body className="py-5">
            <MdOutlineWarning size="5em" className="mb-3" />
            <h3>There’s no location at this address</h3>
            <p>
              Check the URL and try again, or use the location page to find what
              you need.
            </p>
          </Card.Body>
        </Card>
      )}
    </Page>
  );
};

export default withAuthAndAuthRequired(ViewSitePage);
