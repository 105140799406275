import { AccountDto } from "src/services/account/account.dto";
import { BookingDto } from "src/services/booking/booking.dto";
import { array, model, property } from "decoverto";
import { AccountModel } from "../account/account.model";
import { BookingModel } from "../booking/booking.model";
import { PaginationResultDto } from "../searchableService";
import { DiscountUsage } from "../discount/discountUsage.model";

@model()
export class OrderModel {
  @property(() => AccountModel)
  account!: AccountDto;

  @property(() => AccountModel)
  createdBy!: AccountDto;

  @property(() => Number)
  accountId!: number;

  @property(array(() => BookingModel))
  bookings!: BookingDto[];

  @property(() => Number)
  id!: number;

  @property(() => String)
  invoiceId!: string;

  @property(() => Date)
  paymentDate!: Date;

  @property(() => String)
  paymentId!: string;

  @property(() => String)
  paymentType?: string;

  @property(() => Date)
  refundDate!: Date;

  @property(() => Number)
  totalListCost!: number;

  @property(() => Number)
  totalCost!: number;

  @property(() => Date)
  createdAt!: Date;

  @property(() => Number)
  status!: number;

  @property(() => Date)
  confirmationSentAt?: Date;

  @property(() => Date)
  latestBookingStartDate?: Date;

  @property(array(() => DiscountUsage))
  discountUsage?: DiscountUsage[];
}

@model()
export class OrderModelSearchResults
  implements PaginationResultDto<OrderModel>
{
  @property(array(() => OrderModel))
  public rows!: OrderModel[];

  @property(() => Number)
  public count!: number;
}
