import { DiscountDto } from "src/services/discount/discount.dto";
import { Alert, Badge, Card, Col, Stack } from "react-bootstrap";
import { discountStatusBadge } from "src/pages/discount/discount.index";
import moment from "src/types/momentWithLocale";
import { Link } from "react-router-dom";
import React from "react";

export const DiscountSummary = ({
  discount,
  discountCode,
  discountValue,
  discountType,
  hasMinimumAmount,
  maximumNumberOfUses,
  minimumAmount,
  hasMaximumNumberOfUses,
  appliesOncePerCustomer,
  startDate,
  hasEndDate,
  endDate,
}: {
  discount?: DiscountDto;
  discountCode: string;
  discountValue?: number;
  discountType?: string;
  hasMinimumAmount: boolean;
  maximumNumberOfUses?: number;
  minimumAmount?: number;
  hasMaximumNumberOfUses: boolean;
  appliesOncePerCustomer: boolean;
  startDate?: Date;
  hasEndDate?: boolean;
  endDate?: Date;
}) => {
  return (
    <>
      <Col>
        <Card className="mb-3" style={{ backgroundColor: "#edf1f5" }}>
          <Card.Body>
            <Card.Title>Summary</Card.Title>
            {discountCode.length > 0 && (
              <Card.Text>
                <Stack direction="horizontal">
                  <div>
                    <b>{discountCode}</b>
                  </div>
                  <div className="mx-auto">
                    {discount && (
                      <Badge bg={discountStatusBadge(discount.status)}>
                        {discount.status}
                      </Badge>
                    )}
                  </div>
                </Stack>
              </Card.Text>
            )}
            <h6>Details</h6>
            <ul>
              {!!discountValue && !Number.isNaN(discountValue) && (
                <>
                  {discountType === "percent" ? (
                    <li>{discountValue}% off entire order</li>
                  ) : (
                    <li>£{discountValue} off entire order</li>
                  )}
                </>
              )}

              {!hasMinimumAmount ? (
                <li>No minimum spend required</li>
              ) : (
                <>
                  {minimumAmount && <li>Minimum spend of £{minimumAmount}</li>}
                </>
              )}

              {maximumNumberOfUses !== undefined && (
                <>
                  {hasMaximumNumberOfUses && !appliesOncePerCustomer && (
                    <li>Limited to {maximumNumberOfUses} uses</li>
                  )}
                  {hasMaximumNumberOfUses && appliesOncePerCustomer && (
                    <li>
                      Limited to {maximumNumberOfUses} uses, one per customer
                    </li>
                  )}
                </>
              )}
              {appliesOncePerCustomer && !hasMaximumNumberOfUses && (
                <li>One use per customer</li>
              )}

              {startDate && <li>Starts {moment(startDate).calendar()}</li>}

              {hasEndDate && <li>Ends {moment(endDate).calendar()}</li>}
            </ul>
          </Card.Body>
        </Card>

        {discount && (
          <>
            <Card className="mb-3" style={{ backgroundColor: "#edf1f5" }}>
              <Card.Body>
                <Card.Title>Use</Card.Title>
                {discount.numberOfUses ? (
                  <>
                    <ul>
                      <li>
                        {discount.numberOfUses} Use
                        {discount.numberOfUses > 1 && "s"}
                      </li>
                    </ul>
                    <Link to={`/order?discountId=${discount.id}`}>
                      View orders with this discount
                    </Link>
                  </>
                ) : (
                  <Card.Text>This discount has not yet been used</Card.Text>
                )}
              </Card.Body>
            </Card>

            {discount.numberOfUses && (
              <Alert variant="warning">
                This discount has been used, editing is restricted
              </Alert>
            )}
          </>
        )}
      </Col>
    </>
  );
};
