import React from "react";
import { Badge } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Page from "src/components/page";
import SearchTable from "src/components/searchTable";
import { PricingPlanModel } from "src/services/pricingPlan/pricingPlan.model";
import pricingPlanService from "src/services/pricingPlan/pricingPlan.service";
import { withAuthAndAuthRequired } from "../../hooks/withAuthorisationRequired";
import ButtonLink from "src/components/ButtonLink";
import { useNavigate } from "react-router-dom";

const PricingPlansPage = () => {
  const navigate = useNavigate();

  return (
    <Page>
      <Helmet title="Pricing plans" />
      <SearchTable<PricingPlanModel>
        caption="Pricing Plans"
        action={
          <div>
            <ButtonLink variant="success" to="/pricing/create">
              Create pricing plan
            </ButtonLink>
          </div>
        }
        service={pricingPlanService}
        titles={{
          id: "#",
          name: "Name",
          charged: "Charged",
          duration: "Duration",
        }}
        mappers={{
          name: (plan) => (
            <>
              <>
                <b>{plan.displayName}</b>
                <br />
                <span>
                  {plan.usualPricePerSlotLength && (
                    <i>
                      <span style={{ textDecoration: "line-through" }}>
                        £{plan.usualPricePerSlotLength.toFixed(2)}
                      </span>{" "}
                    </i>
                  )}
                  £{plan.pricePerSlotLength.toFixed(2)}
                </span>
              </>
            </>
          ),
          duration: (plan) => (
            <>
              <Badge bg="primary">
                {plan.minSlotLengthInMinutes / 60}{" "}
                {plan.maxSlotLengthInMinutes > 1440 && (
                  <>{plan.minSlotLengthInMinutes / 60 > 1 ? "hours" : "hour"}</>
                )}
                {" - "}
                {plan.maxSlotLengthInMinutes > 1440
                  ? "All day"
                  : plan.maxSlotLengthInMinutes / 60}{" "}
                {plan.maxSlotLengthInMinutes < 1440 && (
                  <>{plan.maxSlotLengthInMinutes / 60 > 1 ? "hours" : "hour"}</>
                )}
              </Badge>
            </>
          ),
          charged: (plan) => (
            <Badge bg="secondary">
              {plan.slotLengthInMinutes === 60
                ? "Hourly"
                : `Every ${plan.slotLengthInMinutes} minutes`}{" "}
            </Badge>
          ),
        }}
        onSelectRow={(row) => navigate(`/pricing/${row.id}`)}
      />
    </Page>
  );
};

export default withAuthAndAuthRequired(PricingPlansPage);
