import { Formik } from "formik";
import React from "react";
import { Breadcrumb, Button, Card, Col, Row, Stack } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Page from "src/components/page";
import { SiteCreateDTO } from "src/services/site/site.model";
import * as Yup from "yup";
import { FormFieldControl } from "../../components/formFieldControl";
import tryAsyncToast from "../../hooks/tryAsyncToast";
import siteService from "../../services/site/site.service";
import { SiteImages } from "./siteImages";
import { SiteLocation } from "./siteLocation";
import { FormikHelpers } from "formik/dist/types";
import { Helmet } from "react-helmet-async";
import { withAuthAndAuthRequired } from "../../hooks/withAuthorisationRequired";
import ModalButton from "src/components/modalButton";
import ButtonLink from "src/components/ButtonLink";
import { SiteSummary } from "./site.summary";

const initialSiteValues: SiteCreateDTO = {
  featuredMediaId: undefined,
  featuredMedia: undefined,
  hubs: [],
  media: [],
  addressLine0: "",
  addressLine1: "",
  description: "",
  isActive: false,
  latitude: 0,
  longitude: 0,
  name: "",
  notes: "",
  summary: "",
};

const SiteFormSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  latitude: Yup.number(),
  longitude: Yup.number(),
  summary: Yup.string(),
  description: Yup.string(),
  notes: Yup.string(),
  //media: Yup.object(),
  //featuredMedia: Yup.object(),
});

const CreateSitePage = () => {
  const navigate = useNavigate();

  const createSite = React.useCallback(
    async (
      site: SiteCreateDTO,
      { setSubmitting }: FormikHelpers<SiteCreateDTO>
    ) => {
      setSubmitting(true);

      const newSite = await tryAsyncToast(siteService.create(site), {
        success: "Site created",
      });

      setSubmitting(false);

      navigate(`/site/${newSite.id}`);
    },
    [navigate]
  );

  return (
    <Page>
      <Helmet title="Create location" />
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/site" }}>
          Locations
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Create</Breadcrumb.Item>
      </Breadcrumb>
      <Stack direction="horizontal" gap={3} className={"mb-3"}>
        <div>
          <h2>Create location</h2>
        </div>
      </Stack>
      <Formik<SiteCreateDTO>
        initialValues={initialSiteValues}
        onSubmit={createSite}
        validationSchema={SiteFormSchema}
      >
        {({
          values: site,
          handleSubmit,
          setFieldValue,
          submitForm,
          isValid,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col md={8}>
                <Card className="mb-3">
                  <Card.Body>
                    <Card.Title>Details</Card.Title>

                    <FormFieldControl
                      fieldName="name"
                      label="Name"
                      showLabel
                      placeholder="Name of site"
                      className="mb-4"
                    />

                    <FormFieldControl
                      fieldName="summary"
                      label="Summary"
                      showLabel
                      placeholder="Short, quick, summary of site"
                      className="mb-4"
                    />

                    <FormFieldControl
                      fieldName="description"
                      label="Description"
                      showLabel
                      placeholder="Detailed description for Site"
                      as="textarea"
                      className="mb-4"
                    />
                  </Card.Body>
                </Card>

                <SiteImages
                  site={site}
                  onSave={async (media) => {
                    const newValues = {
                      ...site,
                      ...media,
                    };

                    setFieldValue("media", newValues.media);
                    setFieldValue("featuredMedia", newValues.featuredMedia);
                  }}
                />

                <SiteLocation
                  site={site}
                  onSubmit={async ({
                    latitude,
                    longitude,
                    addressLine0,
                    addressLine1,
                  }) => {
                    setFieldValue("addressLine0", addressLine0);
                    setFieldValue("addressLine1", addressLine1);
                    setFieldValue("latitude", latitude);
                    setFieldValue("longitude", longitude);
                  }}
                />

                <FormFieldControl
                  fieldName="notes"
                  label="Notes"
                  showLabel
                  as="textarea"
                  placeholder="Administration notes for the site"
                  className="mb-4"
                />
              </Col>
              <Col md={4}>
                <SiteSummary site={site} />
              </Col>
            </Row>
            <hr />

            <Stack direction="horizontal" gap={3} className={"mb-3"}>
              <div>
                <ModalButton
                  variant="outline-secondary"
                  title="Leave page with unsaved changes"
                  text="Discard"
                  footer={({ hideModal }) => (
                    <>
                      <Button onClick={hideModal} variant="outline-secondary">
                        Stay
                      </Button>
                      <ButtonLink to="/site" variant="danger">
                        Discard
                      </ButtonLink>
                    </>
                  )}
                >
                  Leaving this page will remove all unsaved changes.
                </ModalButton>
              </div>
              <div className="ms-auto">
                <Button
                  variant="success"
                  disabled={isSubmitting || !isValid}
                  onClick={() => submitForm()}
                >
                  Create Location
                </Button>
              </div>
            </Stack>
          </form>
        )}
      </Formik>
    </Page>
  );
};

export default withAuthAndAuthRequired(CreateSitePage);
