import React from "react";
import Page from "src/components/page";
import { useNavigate, useParams } from "react-router-dom";
import accountService from "../../services/account/account.service";
import useAsyncRetry from "../../hooks/useAsyncRetry";
import { Button, Card, Col, Row } from "react-bootstrap";
import ProfileImage from "../../components/profileImage";
import { BookingModel } from "../../services/booking/booking.model";
import bookingService from "../../services/booking/booking.service";
import {
  PaginationResultDto,
  SearchableService,
  SearchProps,
} from "../../services/searchableService";
import BookingsTable from "../../components/BookingsTable";
import DateTime from "../../components/DateTime";
import InlineTextEdit from "../../components/inlineTextEdit";
import ButtonLink from "../../components/ButtonLink";
import { Helmet } from "react-helmet-async";
import { withAuthAndAuthRequired } from "../../hooks/withAuthorisationRequired";
import ModalButton from "../../components/modalButton";
import tryAsyncToast from "../../hooks/tryAsyncToast";

const AccountPage = () => {
  const { id } = useParams();

  const { value: account } = useAsyncRetry(async () => {
    if (id === undefined) return undefined;
    const idVal = parseInt(id, 10);
    if (isNaN(idVal)) return undefined;
    const { data } = await accountService.findAccountDetails(idVal);
    return data;
  });

  const bookingForCustomerSearchService: SearchableService<BookingModel> =
    React.useMemo(
      () => ({
        async search(
          search: SearchProps
        ): Promise<PaginationResultDto<BookingModel> | undefined> {
          return bookingService.search({
            ...search,
            filter: [
              ...(search.filter || "").split(","),
              `accountId=${account?.id}`,
            ].join(","),
          });
        },
        searchFilters(): { [p: string]: string } | undefined {
          return bookingService.searchFilters();
        },
      }),
      [account?.id]
    );

  const navigate = useNavigate();

  const deleteAccount = React.useCallback(async () => {
    if (!account) return;

    await tryAsyncToast(accountService.deleteAccount(account.id), {
      success: "Account was deleted",
    });

    navigate("/account");
  }, [account, navigate]);

  return (
    <Page title="Account profile">
      {account && (
        <>
          <Helmet title={`Account - ${account.displayName}`} />
          <Row>
            <Col>
              <Row>
                <Col className="p-3 pb-6">
                  <div className="row mb-5">
                    <div className="col">
                      <div className="d-flex align-items-center">
                        <div className="me-3">
                          `
                          <ProfileImage url={account.profileImage?.url} />
                        </div>
                        <h4>
                          <strong>{account.displayName}</strong>
                        </h4>
                      </div>
                    </div>
                    <div className="col">
                      <Card.Subtitle>Email address</Card.Subtitle>
                      <Card.Text>{account.email}</Card.Text>
                    </div>
                    <div className="col">
                      <Card.Subtitle>Last login</Card.Subtitle>
                      <Card.Text>
                        <DateTime date={account.user?.lastLogin} />
                      </Card.Text>
                    </div>
                    <div className="col">
                      <Card.Subtitle>Address</Card.Subtitle>
                      {account.addressLine0 ? (
                        <>
                          <Card.Text>{account.addressLine0}</Card.Text>
                          <Card.Text>{account.addressLine1}</Card.Text>
                          <Card.Text>{account.postcode}</Card.Text>
                        </>
                      ) : (
                        <Card.Text>Not Specified</Card.Text>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col sm={6}>
                          <InlineTextEdit
                            value={account.type}
                            inputType="select"
                            onSubmit={async (value) => {
                              console.log(value);
                            }}
                            fieldTitle="Account Type"
                            required
                            displayValue={
                              account.type === "admin"
                                ? "Administrator"
                                : account.type === "deleted"
                                ? "Deleted"
                                : "User"
                            }
                          >
                            <option value="">Select an account type</option>
                            <option value="user">User</option>
                            <option value="admin">Administrator</option>
                          </InlineTextEdit>
                        </Col>
                      </Row>
                      {account.type !== "deleted" ? (
                        <Row className="mt-3">
                          <Col>
                            <ButtonLink
                              variant="success"
                              className="me-3"
                              to={`/account/${account.id}/makeBooking`}
                            >
                              Make Booking
                            </ButtonLink>
                            <Button disabled className="me-3" variant="danger">
                              Suspend Account (WIP)
                            </Button>
                            <ModalButton
                              variant="danger"
                              title="Confirm account deletion"
                              text="Delete Account"
                              footer={({ hideModal }) => (
                                <>
                                  <Button
                                    onClick={hideModal}
                                    variant="secondary"
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    onClick={deleteAccount}
                                    variant="danger"
                                  >
                                    Confirm
                                  </Button>
                                </>
                              )}
                            >
                              <p>
                                Are you sure you want to delete this Account.
                              </p>
                              <p>The user will no longer be able to log in.</p>
                              <p>
                                The account will be obfuscated and encrypted,
                                and re-registration will create a totally new
                                account.
                              </p>
                            </ModalButton>
                          </Col>
                        </Row>
                      ) : (
                        <></>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title>Bookings made by this Account</Card.Title>

                  <BookingsTable
                    useWrapper={false}
                    canSearch={false}
                    service={bookingForCustomerSearchService}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </Page>
  );
};

export default withAuthAndAuthRequired(AccountPage);
