import React from "react";

export const TimeOfDay = ({ timeInMinutes }: { timeInMinutes: number }) => {
  return (
    <>
      {Math.floor(timeInMinutes / 60)}:
      {`${Math.floor(timeInMinutes % 60)}`.padStart(2, "0")}
    </>
  );
};
