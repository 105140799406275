import React, { ReactNode } from "react";
import { Button, Modal } from "react-bootstrap";
import { ButtonVariant } from "react-bootstrap/types";

interface ModalCallback {
  setShow: (show: boolean) => void;
  hideModal: () => void;
}

const ModalButton = ({
  children,
  className,
  modalClassName,
  variant,
  title,
  text,
  size,
  footer,
}: {
  children: ReactNode;
  className?: string;
  modalClassName?: string;
  variant?: ButtonVariant;
  title?: string | ReactNode;
  text: ReactNode;
  size?: "sm" | "lg" | "xl" | undefined;
  footer?: (context: ModalCallback) => ReactNode;
}) => {
  const [show, setShow] = React.useState(false);

  const hide = React.useCallback(() => {
    setShow(false);
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        className={modalClassName}
        size={size}
        onClick={(e: MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {title && <Modal.Header closeButton>{title}</Modal.Header>}
        <Modal.Body>{children}</Modal.Body>
        {footer && (
          <Modal.Footer>
            {footer({
              setShow,
              hideModal: hide,
            })}
          </Modal.Footer>
        )}
      </Modal>
      <Button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setShow(true);
        }}
        className={className}
        variant={variant}
      >
        {text}
      </Button>
    </>
  );
};

export default ModalButton;
