import { array, model, property } from "decoverto";
import { MembershipAccountDto } from "./membershipAccount.dto";
import { PaginationResultDto } from "../searchableService";
import { MembershipModel } from "../membership/membership.model";
import { AccountModel } from "../account/account.model";

@model()
export class MembershipAccount implements MembershipAccountDto {
  public constructor(init?: Partial<MembershipAccount>) {
    Object.assign(this, init);
  }

  @property(() => Number)
  accountId?: number;

  @property(() => Date)
  cancelOn?: Date;

  @property(() => Date)
  cancelledAt?: Date;

  @property(() => Date)
  currentPeriodEnd?: Date;

  @property(() => Date)
  currentPeriodStart?: Date;

  @property(() => Number)
  id!: number;

  @property(() => Boolean)
  isActive!: boolean;

  @property(() => Number)
  membershipId?: number;

  @property(() => String)
  status!: string;

  @property(() => String)
  stripeSubscriptionId?: string;

  @property(() => Date)
  trialEnd?: Date;

  @property(() => Date)
  trialStart?: Date;

  @property(() => MembershipModel)
  membership?: MembershipModel;

  @property(() => AccountModel)
  account?: AccountModel;
}

@model()
export class MembershipAccountSearchResults
  implements PaginationResultDto<MembershipAccount>
{
  @property(array(() => MembershipAccount))
  public rows!: MembershipAccount[];

  @property(() => Number)
  public count!: number;
}
