import {
  AccountSearchResource,
  AccountType,
} from "src/services/account/account.dto";
import { OrderDto } from "src/services/order/order.dto";
import { OrderModel } from "src/services/order/order.model";
import { array, model, property } from "decoverto";
import { BookingDto } from "../booking/booking.dto";
import { BookingModel } from "../booking/booking.model";
import UserModel from "../user/user.model";
import { MediaModel } from "../media/media.model";
import { UserDto } from "../user/user.dto";
import { MediaDto } from "../media/media.dto";
import { PaginationResultDto } from "../searchableService";

@model()
export class AccountModel {
  @property(() => String)
  addressLine0!: string;

  @property(() => String)
  addressLine1!: string;

  @property(() => String)
  displayName!: string;

  @property(() => Number)
  id!: number;

  @property(array(() => OrderModel))
  orders!: OrderDto[];

  @property(() => String)
  postcode!: string;

  @property(() => String)
  type!: AccountType;

  @property(() => UserModel)
  user?: UserDto;

  @property(() => Number)
  userId!: number;

  @property(() => MediaModel)
  profileImage: MediaDto | undefined;

  @property(() => String)
  email!: string;

  @property(() => String)
  stripeCustomerId!: string;

  @property(() => Boolean)
  marketingEmailConfirm?: boolean;
}

@model()
export class AccountSearchDto
  extends AccountModel
  implements AccountSearchResource
{
  @property(array(() => OrderModel))
  currentOrders!: OrderDto[];

  @property(array(() => OrderModel))
  previousOrders!: OrderDto[];

  @property(array(() => BookingModel))
  pastBookings?: BookingDto[];

  @property(array(() => BookingModel))
  nextBookings?: BookingDto[];
}

@model()
export class AccountSearchResponse
  implements PaginationResultDto<AccountSearchDto>
{
  @property(array(() => AccountSearchDto))
  public rows!: AccountSearchDto[];

  @property(() => Number)
  public count!: number;
}
