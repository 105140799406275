import {
  AccountModel,
  AccountSearchDto,
  AccountSearchResponse,
} from "src/services/account/account.model";
import { BaseService } from "src/services/baseService";
import {
  decodeSearch,
  SearchableService,
  SearchProps,
} from "src/services/searchableService";
import { MembershipAccount } from "../membershipAccount/membershipAccount.model";

class AccountService
  extends BaseService<AccountModel>
  implements SearchableService<AccountSearchDto>
{
  constructor() {
    super(AccountModel, "/account");
  }

  async findById(id: number) {
    return this._getParsed(`/${id}`);
  }

  async findAll() {
    return this._getArray("/");
  }

  async deleteAccount(id: number) {
    return this._delete204(`/${id}`);
  }

  async findAccountDetails(id: number) {
    return this._getParsed(`/${id}?relations=user,profileImage`);
  }

  async findSubscriptions(id: number) {
    return await this._getArray(`/${id}/subscriptions`, {}, MembershipAccount);
  }

  async search(search: SearchProps) {
    const params = decodeSearch(search);

    return (
      await this._getParsed(
        `/search?relations=orders,nextBookings,pastBookings,profileImage&${params}`,
        {},
        AccountSearchResponse
      )
    ).data;
  }

  async simpleSearch(search: SearchProps) {
    const params = decodeSearch(search);

    return (
      await this._getParsed(
        `/search?relations=profileImage&${params}`,
        {},
        AccountSearchResponse
      )
    ).data;
  }

  async createAdmin(values: { email: string; displayName: string }) {
    return this._postUnParsed("/createAdmin", values);
  }

  searchFilters() {
    return {
      admin: "Administrators",
      user: "Customers",
      guest: "Guests",
      deleted: "Deleted",
    };
  }
}

const accountService = new AccountService();

export default accountService;
