import { css } from "@emotion/css";
import { debounce } from "lodash";
import React, { ReactNode } from "react";
import {
  Form,
  InputGroup,
  Pagination,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import {
  MdChevronLeft,
  MdChevronRight,
  MdOutlineFirstPage,
  MdOutlineLastPage,
  MdSearch,
} from "react-icons/md";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import DynamicTable, {
  DynamicTableProps,
  KeyType,
  TableRowType,
} from "src/components/dynamicTable";
import useAsyncRetry from "src/hooks/useAsyncRetry";
import { SearchableService, SearchProps } from "src/services/searchableService";

/**
 * TODO:
 * Refactor "service" into a "useAsyncRetry" object so the parent can use the functionality it provides
 */
export interface SearchTableProps<T>
  extends Omit<DynamicTableProps<T>, "rows" | "isLoading"> {
  caption?: string | ReactNode;
  service: SearchableService<T>;
  // this is not nice, and more hacky than I'd like
  retryCallback?: (callback: () => void) => void;
  canSearch?: boolean;
  showSearch?: boolean;
  canFilter?: boolean;
  showFilters?: boolean;
  defaultFilters?: string[];
  numberPerPage?: number;
  defaultSortColumn?: string;
  defaultSortAscending?: boolean;
  sortableColumns?: string[];
  action?: any;
  beforeTableRender?: ({
    searchParams,
    setSearchParams,
  }: {
    searchParams: SearchProps;
    setSearchParams: React.Dispatch<React.SetStateAction<SearchProps>>;
  }) => ReactNode;
}

const SearchTable = function SearchTable<T>({
  service,
  retryCallback,
  caption,
  titles,
  canSearch = true,
  showSearch = true,
  canFilter = true,
  showFilters = true,
  defaultFilters = [],
  defaultSortColumn = "",
  defaultSortAscending = true,
  numberPerPage = 10,
  sortableColumns = [],
  action,
  beforeTableRender = undefined,
  ...props
}: SearchTableProps<T>) {
  const [searchParams, setSearchParams] = React.useState<SearchProps>({
    text: "",
    // filter: canFilter ? defaultFilters.join(",") : undefined,
    sortColumn: defaultSortColumn,
    sortAscending: defaultSortAscending,
    ...(canFilter ? { filter: defaultFilters.join(",") } : {}),
  });

  //const [result, setResult] = React.useState<PaginationResultDto<T>>();

  const {
    value: result,
    retry,
    isLoading,
  } = useAsyncRetry(async () => {
    const result = await service.search({
      ...searchParams,
      numberPerPage,
    });

    //setResult(result);
    return result;
  }, [numberPerPage, searchParams, service]);

  React.useEffect(() => {
    retryCallback && retryCallback(retry);
  }, [retry, retryCallback]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateSearchText = React.useCallback(
    debounce((text: string) => {
      setSearchParams({
        ...searchParams,
        text,
      });
    }, 1000),
    [searchParams]
  );

  const [currentPage, setCurrentPage] = React.useState(1);

  const updatePage = React.useCallback(
    (page: number) => {
      setCurrentPage(page);
      setSearchParams({
        ...searchParams,
        page,
      });
    },
    [searchParams]
  );

  const onClickTitle = React.useCallback(
    (sort: KeyType<T>) => {
      if (sort === undefined || !sortableColumns.includes(sort as string))
        return;

      if (sort === searchParams.sortColumn) {
        setSearchParams({
          ...searchParams,
          sortAscending: !searchParams.sortAscending,
        });
      } else {
        setSearchParams({
          ...searchParams,
          sortColumn: sort as string,
        });
      }
    },
    [searchParams, sortableColumns]
  );

  const pagination = React.useMemo(() => {
    const pageCount = result ? Math.ceil(result.count / numberPerPage) : 1;

    if (pageCount < 2) return <></>;

    const minPage = Math.max(currentPage - 5, 0);
    const maxPage = Math.min(currentPage + 4, pageCount);

    const numberOfPagesToShow =
      currentPage > pageCount - 4 ? maxPage - minPage : 9;

    return (
      <>
        {result && (
          <Pagination>
            <Pagination.Item onClick={() => updatePage(1)}>
              <MdOutlineFirstPage />
            </Pagination.Item>
            <Pagination.Item
              onClick={() => updatePage(currentPage > 1 ? currentPage - 1 : 1)}
            >
              <MdChevronLeft />
            </Pagination.Item>
            {[...Array(numberOfPagesToShow)].map((_, idx) => (
              <Pagination.Item
                key={idx + 1 + minPage}
                active={idx + 1 + minPage === currentPage}
                onClick={() => updatePage(idx + 1 + minPage)}
              >
                {idx + 1 + minPage}
              </Pagination.Item>
            ))}
            <Pagination.Item
              onClick={() =>
                updatePage(
                  currentPage < pageCount ? currentPage + 1 : pageCount
                )
              }
            >
              <MdChevronRight />
            </Pagination.Item>
            <Pagination.Item onClick={() => updatePage(pageCount)}>
              <MdOutlineLastPage />
            </Pagination.Item>
          </Pagination>
        )}
      </>
    );
  }, [currentPage, numberPerPage, result, updatePage]);

  const sortTitles = React.useMemo(() => {
    if (sortableColumns === undefined) return titles;

    return Object.entries(titles).reduce(
      (result, [title, value]: [KeyType<T>, string | ReactNode]) => {
        if (!sortableColumns.includes(title as string)) result[title] = value;
        else if (searchParams.sortColumn !== title) {
          result[title] = <span role="button">{value}</span>;
        } else {
          result[title] = (
            <span role="button">
              {value}
              <span className="position-absolute">
                {searchParams.sortAscending ? (
                  <TiArrowSortedUp />
                ) : (
                  <TiArrowSortedDown />
                )}
              </span>
            </span>
          );
        }

        return result;
      },
      {} as TableRowType<T>
    );
  }, [
    searchParams.sortAscending,
    searchParams.sortColumn,
    sortableColumns,
    titles,
  ]);

  return (
    <div>
      {(caption || (canSearch && showSearch) || action) && (
        <Stack direction="horizontal" gap={3} className={"mb-3"}>
          {caption && (
            <div>
              <h2>{caption}</h2>
            </div>
          )}

          {canSearch && showSearch && (
            <div className="ms-auto">
              <InputGroup>
                <Form.Control
                  name="search"
                  placeholder="Search"
                  defaultValue=""
                  onChange={(e) => {
                    updateSearchText(e.target.value);
                  }}
                />
                <InputGroup.Text id="basic-addon2">
                  <MdSearch />
                </InputGroup.Text>
              </InputGroup>
            </div>
          )}
          {canSearch && showSearch && action && <div className="vr" />}
          {action}
        </Stack>
      )}

      {canFilter && showFilters && service.searchFilters() !== undefined && (
        <div className="mb-3">
          <span className="me-2">Show:</span>
          <ToggleButtonGroup
            className={css`
              .btn-check:not(:checked) + .btn {
                opacity: 0.8;
              }
            `}
            type="checkbox"
            defaultValue={defaultFilters}
            onChange={(e) => {
              setSearchParams({
                ...searchParams,
                filter: e.join(","),
              });
            }}
            size="sm"
          >
            {Object.entries(service.searchFilters() || {}).map(
              ([key, title]) => (
                <ToggleButton
                  id={key}
                  key={key}
                  value={key}
                  variant="outline-secondary"
                >
                  {title}
                </ToggleButton>
              )
            )}
          </ToggleButtonGroup>
        </div>
      )}

      {beforeTableRender &&
        beforeTableRender({
          searchParams,
          setSearchParams,
        })}

      <DynamicTable<T>
        rows={result?.rows}
        titles={sortTitles}
        // header={pagination}
        footer={pagination}
        onClickTitle={onClickTitle}
        isLoading={isLoading}
        {...props}
      />
    </div>
  );
};

export default SearchTable;
