import React from "react";
import {
  Badge,
  Breadcrumb,
  Button,
  Card,
  Col,
  Dropdown,
  Row,
  Stack,
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Page from "src/components/page";
import DateFormat, { DateFormats, UTC } from "../../components/DateFormat";
import useAsyncRetry from "../../hooks/useAsyncRetry";
import bookingService from "../../services/booking/booking.service";
import tryAsyncToast from "../../hooks/tryAsyncToast";
import { BookingStatusText } from "../../services/booking/booking.model";
import { AxiosError } from "axios";
import { Helmet } from "react-helmet-async";
import { withAuthAndAuthRequired } from "../../hooks/withAuthorisationRequired";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import "leaflet/dist/leaflet.css";
import { PaymentTypes } from "src/services/order/order.dto";
import { BookingStatus } from "src/services/booking/booking.dto";
import OrderStatus from "../orders/orderStatus";
import orderService from "src/services/order/order.service";
import { MdOutlineWarning } from "react-icons/md";
import DateTime from "src/components/DateTime";
import ButtonLink from "src/components/ButtonLink";

const markerIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconAnchor: [12.5, 41],
});

export const bookingStatusBadge = (bookingStatus: BookingStatus) => {
  switch (bookingStatus) {
    case BookingStatus.Cancelled:
      return "danger";
    case BookingStatus.Completed:
      return "success";
    case BookingStatus.InProgress:
      return "info";
    case BookingStatus.Incomplete:
      return "dark";
    default:
      return "secondary";
  }
};

export const orderStatusBadge = (orderStatus: number) => {
  switch (orderStatus) {
    case 0:
      return "info";
    case 1:
      return "success";
    case 2:
      return "danger";
    case 3:
      return "warning";
    case 4:
      return "dark";
    default:
      return "secondary";
  }
};

const ViewBookingPage = () => {
  const { id } = useParams();

  const { value: booking } = useAsyncRetry(async () => {
    if (id === undefined) return undefined;
    const { data } = await bookingService.findById(id);
    return data;
  }, [id]);

  const {
    value: lockCode,
    retry,
    setValue,
  } = useAsyncRetry(
    async (generate: boolean = false) => {
      if (booking === undefined) return undefined;

      if (generate) {
        return tryAsyncToast(bookingService.generateLockCode(booking.id), {
          success: "Code generated",
          error: (error) => {
            const err = error as AxiosError;
            return `An error occurred, please ensure the lock is working and connected to the system. 
          (${err.response?.data?.errmsg})`;
          },
        });
      }
      return bookingService.getLockCode(booking.id);
    },
    [booking]
  );

  const removeLockCode = React.useCallback(async () => {
    if (booking === undefined) return;
    await tryAsyncToast(
      async () => {
        const result = await bookingService.removeLockCode(booking.id);

        setValue({
          ...booking,
          lockCode: null,
        });

        return result;
      },
      {
        success: "Code removed",
        error: (error) => {
          const err = error as AxiosError;
          return `An error occurred, please ensure the lock is working and connected to the system. 
          (${err.response?.data?.errmsg})`;
        },
      }
    );
  }, [booking, setValue]);

  return booking === undefined ? (
    <></>
  ) : (
    <Page>
      <Helmet title={`Booking ${booking.id}`} />
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/booking" }}>
          Bookings
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{id}</Breadcrumb.Item>
      </Breadcrumb>

      {booking ? (
        <>
          <Stack direction="horizontal" gap={3} className="mb-3">
            <div>
              <h2>{booking.id}</h2>
            </div>
            <div className="ms-auto">
              {/* {booking.order.status === 1 || booking.order.status === 0 ? (
            <ModalButton
              title="Confirm Cancellation"
              text="Cancel Booking"
              variant="outline-danger"
              footer={({ hideModal }) => (
                <>
                  <Button variant="secondary" onClick={() => hideModal()}>
                    Close
                  </Button>
                  <Button
                    variant="danger"
                    onClick={async () => {
                      await cancelBooking();
                    }}
                  >
                    Confirm and Cancel booking
                  </Button>
                </>
              )}
            >
              {booking.order.status === 1 ? (
                <p>
                  The booking has already been paid, cancelling will not cause a
                  refund to be issued, this will have to be arranged outside the
                  current system.
                </p>
              ) : undefined}

              <p>Are you sure you wish to cancel this booking?</p>
            </ModalButton>
          ) : (
            ""
          )}
        </div>
        <div> */}
              <Dropdown>
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                  Actions
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {/* <Dropdown.Item href="#/action-2">Resend confirmation email</Dropdown.Item> */}
                  {lockCode?.lockCode ? (
                    <Dropdown.Item onClick={() => removeLockCode()}>
                      Remove lock code
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item onClick={() => retry(true)}>
                      Generate lock code
                    </Dropdown.Item>
                  )}

                  {(booking.order.status === 1 ||
                    booking.order.status === 0) && <Dropdown.Divider />}

                  {booking.order.status === 0 &&
                    booking.order.paymentType === PaymentTypes.CHECKOUT && (
                      <>
                        <Dropdown.Item
                          onClick={async () =>
                            await tryAsyncToast(
                              orderService.resendPayment(booking.orderId),
                              {
                                success: "Payment email has been sent",
                              }
                            )
                          }
                        >
                          Resend payment email
                        </Dropdown.Item>
                      </>
                    )}

                  {booking.order.status === 1 && (
                    <Dropdown.Item
                      onClick={async () =>
                        await tryAsyncToast(
                          orderService.resendConfirmation(booking.orderId),
                          {
                            success: "Confirmation email has been sent",
                          }
                        )
                      }
                    >
                      Resend confirmation email
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Stack>

          <Card className="mb-3">
            <Card.Body>
              <Stack direction="horizontal" gap={3}>
                <div>
                  Created
                  <br />
                  <UTC>
                    <DateFormat
                      format={DateFormats.dateTimeSimple}
                      date={booking.createdAt}
                    />{" "}
                  </UTC>
                </div>
                <div className="vr" />
                <div>
                  Customer
                  <br />
                  <Link to={`/customer/${booking.order.account.id}`}>
                    {booking.order.account.displayName}
                  </Link>
                </div>
                <div className="vr" />
                <div>
                  Cost
                  <br />
                  <span>£{booking.costWhenOrdered.toFixed(2)}</span>
                </div>
                <div className="vr" />
                <div>
                  Status
                  <br />
                  <Badge bg={bookingStatusBadge(booking.status)}>
                    {BookingStatusText[booking.status]}
                  </Badge>
                </div>
                <div className="vr" />

                <div>
                  Payment
                  <br />
                  <Badge bg={orderStatusBadge(booking.order.status)}>
                    <OrderStatus status={booking.order.status} />
                  </Badge>
                </div>
                <div className="vr" />

                <div>
                  Lock Code
                  <br />{" "}
                  <Badge bg={lockCode?.lockCode ? "success" : "secondary"}>
                    {lockCode?.lockCode || <em>Not defined</em>}
                  </Badge>
                </div>
              </Stack>
            </Card.Body>
          </Card>

          <Row>
            <Col lg={8} md={12}>
              <Card className="mb-3">
                <Card.Body>
                  <Card.Title>Booking overview</Card.Title>
                  <Row className="mb-2">
                    <Col xs={3}>
                      <strong>Date:</strong>
                    </Col>
                    <Col>
                      <DateFormat
                        format={DateFormats.dayOfWeekString}
                        date={booking.startDateTime}
                      />{" "}
                      <DateFormat
                        format={DateFormats.date}
                        date={booking.startDateTime}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={3}>
                      <strong>Start Time:</strong>
                    </Col>
                    <Col>
                      <DateFormat
                        format={DateFormats.time}
                        date={booking.startDateTime}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={3}>
                      <strong>End Time</strong>
                    </Col>
                    <Col>
                      <DateFormat
                        format={DateFormats.time}
                        date={booking.endDateTime}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={3}>
                      <strong>Lock code</strong>
                    </Col>
                    <Col>
                      {lockCode?.lockCode ? (
                        <Badge
                          bg={"success"}
                          style={{ fontSize: "3rem", width: "100%" }}
                        >
                          {lockCode?.lockCode} #
                        </Badge>
                      ) : (
                        <Badge bg={"secondary"}>{<em>Not defined</em>}</Badge>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Card className="mb-3">
                <Card.Header style={{ height: 250, padding: 0 }}>
                  <MapContainer
                    center={[booking.hub.latitude, booking.hub.longitude]}
                    zoom={15}
                    placeholder={
                      <img
                        src="https://via.placeholder.com/728x400.png?text=Location map"
                        alt="Location map"
                        className="w-100"
                      />
                    }
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />

                    <Marker
                      position={[booking.hub.latitude, booking.hub.longitude]}
                      icon={markerIcon}
                      title={booking.hub.name}
                      zIndexOffset={-1}
                    />
                  </MapContainer>
                </Card.Header>
                <Card.Body>
                  <Row className="mb-2">
                    <Col xs={3}>
                      <strong>Location:</strong>
                    </Col>
                    <Col>
                      <Link to={`/site/${booking.hub.siteId}`}>
                        {booking.hub?.site?.name}
                      </Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={3}>
                      <strong>Hub:</strong>
                    </Col>
                    <Col>
                      <Link to={`/hub/${booking.hub.id}`}>
                        {booking.hub?.name}
                      </Link>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Card className="mb-3">
                <Card.Body>
                  <Card.Title>Communication</Card.Title>
                  <Row>
                    <Col xs={3}>
                      <strong>Order confirmation:</strong>
                    </Col>
                    <Col>
                      {booking.order.confirmationSentAt ? (
                        <UTC>
                          <DateFormat
                            format={DateFormats.dateTimeSimple}
                            date={booking.order.confirmationSentAt}
                          />{" "}
                          UTC
                        </UTC>
                      ) : (
                        <>Not sent</>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} md={12}>
              <Card className="mb-3">
                <Card.Body>
                  <Card.Title>Customer</Card.Title>

                  <p>
                    <strong>Name</strong>
                    <br />
                    <Link to={`/customer/${booking.order.account.id}`}>
                      {booking.order.account.displayName}
                    </Link>
                  </p>
                  <ButtonLink
                    variant="outline-primary"
                    to={`/customer/${booking.order.account.id}`}
                    style={{ width: "100%" }}
                  >
                    View Customer
                  </ButtonLink>
                </Card.Body>
              </Card>
              <Card className="mb-3">
                <Card.Body>
                  <Card.Title>Payment</Card.Title>

                  <p>
                    <strong>Order</strong>
                    <br />
                    <Link className="m-2" to={`/order/${booking.orderId}`}>
                      {booking.orderId}
                    </Link>
                  </p>

                  <p>
                    <strong>Status</strong>
                    <br />
                    <Badge bg={orderStatusBadge(booking.order.status)}>
                      <OrderStatus status={booking.order.status} />
                    </Badge>
                  </p>

                  <p>
                    <strong>Payment type</strong>
                    <br />
                    <Badge bg="info">
                      {booking.order.paymentType === PaymentTypes.INTENT
                        ? "Mobile App"
                        : "Stripe Checkout"}
                    </Badge>
                  </p>

                  {booking.order.status > 0 && (
                    <p>
                      <strong>Payment date</strong>
                      <br />
                      <UTC>
                        <DateTime date={booking.order.paymentDate} /> UTC
                      </UTC>
                    </p>
                  )}

                  {booking.order.status === 3 && (
                    <p>
                      <strong>Refund date</strong>
                      <br />
                      <UTC>
                        <DateTime date={booking.order.refundDate} /> UTC
                      </UTC>
                    </p>
                  )}

                  {booking.order.paymentId && (
                    <a
                      style={{ width: "100%" }}
                      target="_blank"
                      href={`https://dashboard.stripe.com/${
                        process.env.REACT_APP_API_BASE !==
                        "https://api.workfromhub.co.uk"
                          ? "test/"
                          : ""
                      }payments/${booking.order.paymentId}`}
                      rel="noreferrer"
                    >
                      <Button
                        variant="primary"
                        style={{
                          width: "100%",
                          backgroundColor: "#625afa",
                          borderColor: "#625afa",
                        }}
                      >
                        View in Stripe
                      </Button>
                    </a>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      ) : (
        <Card className="text-center mb-3">
          <Card.Body className="py-5">
            <MdOutlineWarning size="5em" className="mb-3" />
            <h3>There’s no booking at this address</h3>
            <p>
              Check the URL and try again, or use the bookings page to find what
              you need.
            </p>
          </Card.Body>
        </Card>
      )}
    </Page>
  );
};

export default withAuthAndAuthRequired(ViewBookingPage);
