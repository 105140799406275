import L, { LatLng, LatLngExpression } from "leaflet";
import React from "react";
import { Marker } from "react-leaflet";

const DraggableMarker = ({
  center,
  icon,
  draggable = false,
  onDragging,
  onDragEnd,
  title = undefined,
}: {
  center: LatLngExpression;
  icon?: L.Icon;
  draggable?: boolean;
  onDragging: (latLong: LatLng) => void;
  onDragEnd: (latLong: LatLng) => void;
  title?: string;
}) => {
  const markerRef = React.useRef<L.Marker>(null);

  const eventHandlers = React.useMemo(
    () => ({
      drag() {
        const marker = markerRef.current;
        if (marker != null) {
          onDragging(marker.getLatLng());
        }
      },
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          onDragEnd(marker.getLatLng());
        }
      },
    }),
    [onDragEnd, onDragging]
  );

  return (
    <Marker
      draggable={draggable}
      eventHandlers={eventHandlers}
      position={center}
      ref={markerRef}
      icon={icon}
      title={title}
    />
  );
};

export default DraggableMarker;
