import { BaseService } from "src/services/baseService";
import UserModel from "src/services/user/user.model";

class UserService extends BaseService<UserModel> {
  constructor() {
    super(UserModel, "/user");
  }

  async findByAuthentication(authentication: string) {
    return this._post(`/authenticate?relations=account`, {
      authentication,
    });
  }
}

const userService = new UserService();

export default userService;
