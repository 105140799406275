import React from "react";
import { Breadcrumb, Card, Col, Form, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import { MediaFieldControl } from "src/components/mediaFieldControl";
import MediaFieldGallery from "src/components/mediaFieldGallery";
import Page from "src/components/page";
import tryAsyncToast from "src/hooks/tryAsyncToast";
import useAsyncRetry from "src/hooks/useAsyncRetry";
import { HubModel } from "src/services/hub/hub.model";
import hubService from "src/services/hub/hub.service";
import { partialRefresh } from "src/services/utils";
import HubForm, { InitialHubFormValues } from "./hubForm";
import { withAuthAndAuthRequired } from "../../hooks/withAuthorisationRequired";

const EditHubPage = () => {
  const { id } = useParams();

  const idValue = React.useMemo(() => {
    if (id === undefined) return undefined;
    const idValue = parseInt(id, 10);
    if (isNaN(idValue)) return undefined;
    return idValue;
  }, [id]);

  const { value: hub, setValue } = useAsyncRetry<
    HubModel | undefined
  >(async () => {
    if (idValue === undefined) return undefined;
    return hubService.findById(idValue);
  }, [idValue]);

  const onSubmit = React.useMemo(() => {
    if (idValue === undefined) return async () => {};

    return async (newValues: InitialHubFormValues) => {
      try {
        await tryAsyncToast(hubService.update(idValue, newValues), {
          success: "Hub updated",
        });

        setValue({
          ...(hub as HubModel),
          ...newValues,
        });
      } catch (e: any) {}
    };
  }, [hub, idValue, setValue]);

  const refreshFeaturedMedia = React.useCallback(async () => {
    const result = await partialRefresh(
      hub,
      async () => hub && hubService.findById(hub.id),
      ["featuredMedia", "featuredMediaId"]
    );
    setValue(result);
  }, [hub, setValue]);

  const refreshMedia = React.useCallback(async () => {
    const result = await partialRefresh(
      hub,
      async () => hub && hubService.findById(hub.id),
      ["media"]
    );
    setValue(result);
  }, [hub, setValue]);

  return (
    <Page title="Edit Hub">
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/hub" }}>
          Hubs
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{id}</Breadcrumb.Item>
      </Breadcrumb>
      {hub && (
        <>
          <Helmet title={`Edit ${hub.name} hub`} />
          <HubForm hub={hub} onSubmit={onSubmit} />

          <hr className="mt-5" />

          <Row className="mt-5">
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title>Featured Image or Media</Card.Title>
                  <MediaFieldControl
                    initialMediaField={hub.featuredMedia}
                    onSave={async (values) => {
                      if (hub.id === undefined) return;
                      await hubService.updateFeaturedMedia(hub.id, values);
                      await refreshFeaturedMedia();
                    }}
                    onRemove={async () => {
                      await hubService.updateFeaturedMedia(hub.id, null);
                      await refreshFeaturedMedia();
                    }}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title>Images &amp; Media Gallery</Card.Title>

                  <Form.Group>
                    <Row sm={2} md={4} lg={6} xl={8}>
                      <MediaFieldGallery
                        values={hub.media!}
                        onCreate={async (media) => {
                          await hubService.createMedia(hub.id, media);
                          await refreshMedia();
                        }}
                        onSave={async (media, idx) => {
                          await hubService.updateMedia(hub.id, idx, media);
                          await refreshMedia();
                        }}
                        onRemove={async (idx) => {
                          await hubService.deleteMedia(hub.id, idx);
                          await refreshMedia();
                        }}
                      />
                    </Row>
                  </Form.Group>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </Page>
  );
};

export default withAuthAndAuthRequired(EditHubPage);
