import React from "react";
import { Helmet } from "react-helmet-async";
import DateTime from "src/components/DateTime";
import Page from "src/components/page";
import ProfileImage from "src/components/profileImage";
import SearchTable from "src/components/searchTable";
import { AccountSearchDto } from "src/services/account/account.model";
import accountService from "src/services/account/account.service";
import ButtonLink from "../../components/ButtonLink";
import { withAuthAndAuthRequired } from "../../hooks/withAuthorisationRequired";
import { css, cx } from "@emotion/css";

const overFlow = css`
  text-overflow: ellipsis;
  max-width: 100px;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
`;

const AccountIndexPage = () => {
  return (
    <Page>
      <Helmet title="Administrators" />
      <SearchTable<AccountSearchDto>
        caption="Administrators"
        action={
          <div>
            <ButtonLink variant="success" to="/account/createAdmin">
              Add Admin user
            </ButtonLink>
          </div>
        }
        service={accountService}
        defaultFilters={["admin"]}
        mappers={{
          details: (account) => (
            <div className="d-flex">
              <ProfileImage url={account.profileImage?.url} />
              <div className="px-2">
                <strong>{account.displayName}</strong>
                <br />
                <span
                  className={cx(
                    "small",
                    account.type === "deleted" ? overFlow : undefined
                  )}
                >
                  {account.email}
                </span>
              </div>
            </div>
          ),
          type: ({ type }) => {
            switch (type) {
              case "admin":
                return "Administrator";
              case "guest":
                return "Guest";
              case "deleted":
                return "Deleted";
              default:
                return "User";
            }
          },
          orders: (account) => <>{account.orders.length}</>,
          bookings: (account) => <>{account.nextBookings?.length}</>,
          previous: (account) => <>{account.pastBookings?.length}</>,
          next: (account) => (
            <>
              {account.nextBookings && account.nextBookings.length > 0 && (
                <>
                  <DateTime date={account.nextBookings?.[0]?.startDateTime} />
                </>
              )}
            </>
          ),
          location: (account) => (
            <>
              {account.nextBookings && account.nextBookings.length > 0 && (
                <>
                  {account.nextBookings?.[0]?.hub?.site?.name} (
                  {account.nextBookings?.[0]?.hub?.name})
                </>
              )}
            </>
          ),
          controls: (account) => (
            <div style={{ textAlign: "right" }}>
              <ButtonLink to={`/account/${account.id}`}>View/Edit</ButtonLink>
            </div>
          ),
        }}
        titles={{
          details: "Account details",
          type: "Type",
          orders: "Number of orders made",
          bookings: "Current bookings",
          previous: "Previous bookings",
          next: "Next booking",
          location: "Location (hub)",
          controls: "",
        }}
      />
    </Page>
  );
};

export default withAuthAndAuthRequired(AccountIndexPage);
