import React from "react";

const OrderStatus = ({ status }: { status: number }) => {
  if (status === 0) return <span>IN PROGRESS</span>;
  if (status === 1) return <span>PAID</span>;
  if (status === 2) return <span>CANCELLED</span>;
  if (status === 3) return <span>REFUNDED</span>;
  if (status === 4) return <span>INCOMPLETE</span>;

  return <></>;
};

export default OrderStatus;
