import { parseInt } from "lodash";
import React from "react";
import { Breadcrumb, Card, Stack, Tab, Tabs } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate, useParams } from "react-router-dom";
import Page from "src/components/page";
import useAsyncRetry from "src/hooks/useAsyncRetry";
import hubService from "src/services/hub/hub.service";
import { HubModel } from "../../services/hub/hub.model";
import { HubAllocationsTab } from "./hubAllocationsTab";
import HubAvailabilityTab from "./hubAvailabilityTab";
import { HubBookingsTab } from "./hubBookingsTab";
import { HubDetailsTab } from "./hubDetailsTab";
import { HubServicesTab } from "./hubServicesTab";
import { withAuthAndAuthRequired } from "../../hooks/withAuthorisationRequired";
import ButtonLink from "src/components/ButtonLink";
import { MdOutlineWarning } from "react-icons/md";

const ViewHubPage = () => {
  const { id, tab } = useParams();

  const navigate = useNavigate();

  const { value: hub, setValue } = useAsyncRetry<HubModel | undefined>(
    async () => {
      if (id === undefined) return undefined;
      const idValue = parseInt(id, 10);
      if (isNaN(idValue)) return undefined;
      return hubService.findById(idValue, "site,featuredMedia,media");
    }
  );

  return (
    <Page>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/hub" }}>
          Hubs
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{id}</Breadcrumb.Item>
      </Breadcrumb>
      {hub ? (
        <>
          <Helmet title={`Hub - ${hub.name}`} />
          <Stack direction="horizontal" gap={3}>
            <div>
              <h2>{hub?.name}</h2>
            </div>
            <div className="ms-auto">
              <ButtonLink variant="primary" to={`/hub/${hub.id}/checkout`}>
                Make Booking
              </ButtonLink>
            </div>
          </Stack>
          <Tabs
            defaultActiveKey={tab || "details"}
            className="mt-4"
            mountOnEnter
            onSelect={(tab) => {
              navigate(`/hub/${id}/${tab}`);
            }}
          >
            <Tab eventKey="details" title="Details">
              <HubDetailsTab hub={hub} setValue={setValue} />
            </Tab>

            <Tab eventKey="allocation" title="Usage">
              <HubAllocationsTab hub={hub as HubModel} />
            </Tab>

            <Tab eventKey="availability" title="Availability">
              <HubAvailabilityTab hub={hub as HubModel} />
            </Tab>

            <Tab eventKey="bookings" title="Bookings">
              <HubBookingsTab hub={hub as HubModel} />
            </Tab>

            <Tab eventKey="services" title="Services">
              <HubServicesTab hub={hub as HubModel} setValue={setValue} />
            </Tab>
          </Tabs>
        </>
      ) : (
        <Card className="text-center mb-3">
          <Card.Body className="py-5">
            <MdOutlineWarning size="5em" className="mb-3" />
            <h3>There’s no hub at this address</h3>
            <p>
              Check the URL and try again, or use the hubs page to find what you
              need.
            </p>
          </Card.Body>
        </Card>
      )}
    </Page>
  );
};

export default withAuthAndAuthRequired(ViewHubPage);
