import { css } from "@emotion/css";
import { ErrorMessage, useFormikContext } from "formik";
import React, { ReactNode } from "react";
import { htmlTime } from "../utils/htmlTime";
import { Form, InputGroup } from "react-bootstrap";
import moment from "src/types/momentWithLocale";

const errorClass = css`
  border: 2px solid #ff6565;
`;

export const errorMessage = css`
  color: #ff6565;
  padding: 0.5em 0.2em;
`;

type Parser = {
  toValue: (source: string) => unknown;
  toString: (value: unknown) => string;
};

const parsers: { [type: string]: Parser } = {
  date: {
    toValue: (source: string) => moment(source, "yyyy-MM-DD").toDate(),
    toString: (value: unknown) => {
      return moment.isDate(value) && moment(value).isValid()
        ? moment(value as Date).format("yyyy-MM-DD")
        : (value as string);
    },
  },

  time: htmlTime,

  default: {
    toValue: (_: string) => _,
    toString: (_: unknown) => _ as string,
  },
};

export const FormFieldControl = ({
  fieldName,
  label,
  placeholder,
  as,
  type,
  min,
  max,
  step,
  showLabel = true,
  children,
  className,
  textBefore,
  textAfter,
  onChange,
  disabled = false,
}: {
  children?: ReactNode;
  fieldName: string;
  label?: string;
  placeholder?: string;
  as?: React.ElementType;
  type?: string;
  min?: number;
  max?: number;
  step?: number;
  showLabel?: boolean;
  className?: string;
  textBefore?: string;
  textAfter?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
}) => {
  const {
    handleChange,
    handleBlur,
    touched,
    errors,
    getFieldProps,
    getFieldHelpers,
  } = useFormikContext<any>();

  const helpers = getFieldHelpers(fieldName);

  const props = getFieldProps(fieldName);

  const parser = React.useMemo(() => {
    return parsers[type || "default"] || parsers["default"];
  }, [type]);

  return (
    <Form.Group controlId={fieldName} className={className}>
      {showLabel && (
        <Form.Label style={{ fontWeight: "bold" }}>
          {label || fieldName}
        </Form.Label>
      )}
      <InputGroup className="mb-3">
        {textBefore && <InputGroup.Text>{textBefore}</InputGroup.Text>}
        {type === "select" ? (
          <Form.Select
            name={fieldName}
            value={props.value}
            onChange={(e) => {
              handleChange(e);
              onChange?.(e.target.value);
            }}
            onBlur={handleBlur}
            disabled={disabled}
          >
            {children}
          </Form.Select>
        ) : (
          <Form.Control
            name={fieldName}
            onChange={async (e) => {
              // Note: While "setValue" is typed as "void" it's really returning a promise apparently!
              // related issues : https://github.com/sehyunchung/til/issues/60
              // https://github.com/jaredpalmer/formik/issues/2059
              await helpers.setValue(parser.toValue(e.target.value));
              onChange?.(e.target.value);
            }}
            as={as}
            type={type}
            min={min}
            max={max}
            step={step}
            onBlur={handleBlur}
            value={parser.toString(props.value)}
            placeholder={placeholder || fieldName}
            className={
              touched[fieldName] && errors[fieldName] ? errorClass : undefined
            }
            disabled={disabled}
          >
            {children}
          </Form.Control>
        )}
        {textAfter && <InputGroup.Text>{textAfter}</InputGroup.Text>}
      </InputGroup>
      {touched[fieldName] && errors[fieldName] ? (
        <div className={errorMessage}>
          <ErrorMessage name={fieldName} />
        </div>
      ) : null}
    </Form.Group>
  );
};
