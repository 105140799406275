import { BaseService } from "src/services/baseService";
import { MediaModel } from "src/services/media/media.model";

class MediaService extends BaseService<MediaModel> {
  constructor() {
    super(MediaModel, "/media");
  }

  async deleteById(id: number) {
    return await this._delete(`/${id}`);
  }

  async create(media: Partial<MediaModel>) {
    const response = await this._post("", media);
    return response.data;
  }

  async update(id: number, media: Partial<MediaModel>) {
    const response = await this._patch(`/${id}`, media);
    return response.data;
  }
}

const mediaService = new MediaService();

export default mediaService;
