import { array, model, property } from "decoverto";
import { PaginationResultDto } from "../searchableService";

@model()
export class PricingPlanModel {
  @property(() => String)
  displayName!: string;

  @property(() => Number)
  id!: number;

  @property(() => String)
  paymentProviderId!: string;

  @property(() => Number)
  slotLengthInMinutes!: number;

  @property(() => String)
  type!: string;

  @property(() => Number)
  maxSlotLengthInMinutes!: number;

  @property(() => Number)
  minSlotLengthInMinutes!: number;

  @property(() => Number)
  pricePerSlotLength!: number;

  @property(() => Number)
  usualPricePerSlotLength?: number;
}

@model()
export class PricingPlanSearchResults
  implements PaginationResultDto<PricingPlanModel>
{
  @property(array(() => PricingPlanModel))
  public rows!: PricingPlanModel[];

  @property(() => Number)
  public count!: number;
}
