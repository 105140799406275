import { AxiosError } from "axios";
import React from "react";
import { toast } from "react-toastify";

export type ToastOptions = {
  success: string | React.ReactNode;
  error?: string | ((error: Error) => string);
};

/**
 * Try to perform an asynchronous task and
 * show a toast describing the result if it
 * succeeds or fails, encapsulating a common
 * pattern that we are using in this application.
 */
export default async function tryAsyncToast<T>(
  task: Promise<T> | (() => Promise<T>),
  options: ToastOptions
): Promise<T> {
  try {
    const result = await (typeof task === "function" ? task() : task);

    toast.success(options.success);

    return result;
  } catch (err: any) {
    const error: AxiosError = err;

    let errorMessage = err.message || "Something went wrong!";

    if (options.error) {
      errorMessage =
        typeof options.error === "function"
          ? options.error(err)
          : options.error;
    } else if (error.response?.data?.message) {
      errorMessage = error.response.data.message;
    } else if (error.response?.data?.errmsg) {
      errorMessage = error.response.data.errmsg;
    }

    if (Array.isArray(errorMessage))
      errorMessage.forEach((m) => toast.error(m));
    else toast.error(errorMessage);

    // eslint-disable-next-line no-console
    console.error(err);
    throw err;
  }
}
