import { BaseService } from "src/services/baseService";
import {
  HubAllocationModel,
  HubAllocationSearchResults,
} from "src/services/hubAllocation/hubAllocation.model";
import {
  decodeSearch,
  PaginationResultDto,
  SearchableService,
  SearchProps,
} from "../searchableService";

class HubAllocationService
  extends BaseService<HubAllocationModel>
  implements SearchableService<HubAllocationModel>
{
  constructor() {
    super(HubAllocationModel, "/hubAllocation");
  }

  async findAll() {
    return (await this._getArray("?relations=hub")).data;
  }

  async delete(id: number) {
    return this._delete(`/${id}`);
  }

  async search(
    search: SearchProps
  ): Promise<PaginationResultDto<HubAllocationModel> | undefined> {
    const params = decodeSearch(search);

    return (
      await this._getParsed(
        `/search?relations=createdBy,hub,booking&${params}`,
        {},
        HubAllocationSearchResults
      )
    ).data;
  }

  searchFilters(): { [p: string]: string } | undefined {
    return {
      bookings: "Bookings",
      maintenance: "Maintenance",
      cleaning: "Cleaning",
      outOfOrder: "Out of Order",
      reserved: "Reserved",
      past: "Past",
      current: "Current",
      future: "Future",
    };
  }
}

const hubAllocationService = new HubAllocationService();

export default hubAllocationService;
/*
function jsonArray(arg0: () => typeof HubAllocationDto) {
    throw new Error("Function not implemented.");
}
 */
