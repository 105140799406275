import React from "react";
import UserModel from "../services/user/user.model";
import { useAuth0 } from "@auth0/auth0-react";
import userService from "../services/user/user.service";
import useAsyncRetry from "../hooks/useAsyncRetry";

export interface UserContextState {
  isLoading: boolean;
  error?: Error;
  user?: UserModel;
  setUser: (user: UserModel) => void;
  reload: () => Promise<void>;
}

export const UserContext = React.createContext<UserContextState | undefined>(
  undefined
);

export const UserContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { isAuthenticated, user } = useAuth0();

  const loadUser = React.useCallback(async () => {
    if (!isAuthenticated) {
      return undefined;
    }

    if (user?.sub) {
      const { data } = await userService.findByAuthentication(user.sub);
      if (data === undefined) throw new Error("User does not exist");

      return data;
    }

    throw new Error("Current user does not exist.");
  }, [isAuthenticated, user]);

  const { value, isLoading, error, setValue } = useAsyncRetry<
    UserModel | undefined
  >(loadUser, [user]);

  const reloadUser = React.useCallback(async () => {
    setValue(await loadUser());
  }, [loadUser, setValue]);

  const state: UserContextState = React.useMemo(
    () => ({
      isLoading,
      error,
      user: value,
      setUser: setValue,
      reload: reloadUser,
    }),
    [error, isLoading, reloadUser, setValue, value]
  );

  return (
    <UserContext.Provider value={state}>
      {!isLoading ? children : null}
    </UserContext.Provider>
  );
};

export function useUserContext() {
  const context = React.useContext(UserContext);

  if (!context) {
    throw new Error("Need to have a UserContextProvider as a parent component");
  }

  return context;
}
