import { useAuth0 } from "@auth0/auth0-react";
import React, { useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Page from "src/components/page";
import { withAuthAndAuthRequired } from "../../hooks/withAuthorisationRequired";

const ProfilePage = () => {
  const { user, isAuthenticated, isLoading, logout, getAccessTokenSilently } =
    useAuth0();
  let [accessToken, setAccessToken] = useState("");

  const getAccessToken = async () => {
    const token = await getAccessTokenSilently();

    setAccessToken(token);
  };

  return (
    <Page>
      <Helmet title={`${user?.name}'s profile`} />
      <Row>
        <Col>
          <h1>Profile</h1>
          {isLoading && <div>Loading auth0 ...</div>}

          {isAuthenticated && (
            <>
              {user && (
                <Row>
                  <Col xs={2}>
                    <Card>
                      <Card.Img
                        variant="top"
                        src={user.picture}
                        alt={user.name}
                      />
                      <Card.Body>
                        <Card.Title>{user.name}</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">
                          {user.email}
                        </Card.Subtitle>

                        <dl>
                          <dt>Auth0</dt>
                          <dd>{user.sub}</dd>
                        </dl>
                        <div className="d-grid gap-2">
                          <Button
                            variant="outline-danger"
                            onClick={() =>
                              logout({ returnTo: window.location.origin })
                            }
                          >
                            Sign out
                          </Button>
                        </div>
                      </Card.Body>
                    </Card>
                    <Card className="mt-4">
                      <Card.Body>
                        <dl>
                          <dt>Access Token</dt>
                          {accessToken && (
                            <dd>
                              <code>{accessToken}</code>
                            </dd>
                          )}
                        </dl>

                        <Button onClick={() => getAccessToken()}>
                          Get access token{" "}
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <dl>
                          <dt>address</dt>
                          <dd>{user.address}</dd>
                          <dt>birthdate</dt>
                          <dd>{user.birthdate}</dd>
                          <dt>email_verified</dt>
                          <dd>{user.email_verified}</dd>
                          <dt>family_name</dt>
                          <dd>{user.family_name}</dd>
                          <dt>gender</dt>
                          <dd>{user.gender}</dd>
                          <dt>given_name</dt>
                          <dd>{user.given_name}</dd>
                          <dt>locale</dt>
                          <dd>{user.locale}</dd>
                          <dt>middle_name</dt>
                          <dd>{user.middle_name}</dd>
                          <dt>name</dt>
                          <dd>{user.name}</dd>
                          <dt>nickname</dt>
                          <dd>{user.nickname}</dd>
                          <dt>phone_number</dt>
                          <dd>{user.phone_number}</dd>
                          <dt>phone_number_verified</dt>
                          <dd>{user.phone_number_verified}</dd>
                          <dt>picture</dt>
                          <dd>{user.picture}</dd>
                          <dt>preferred_username</dt>
                          <dd>{user.preferred_username}</dd>
                          <dt>profile</dt>
                          <dd>{user.profile}</dd>
                          <dt>sub</dt>
                          <dd>{user.sub}</dd>
                          <dt>updated_at</dt>
                          <dd>{user.updated_at}</dd>
                          <dt>website</dt>
                          <dd>{user.website}</dd>
                          <dt>zoneinfo</dt>
                          <dd>{user.zoneinfo}</dd>
                        </dl>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              )}
            </>
          )}
        </Col>
      </Row>
    </Page>
  );
};

export default withAuthAndAuthRequired(ProfilePage);
