import { parseInt } from "lodash";
import React from "react";
import { Button, Card, Col, FormSelect, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { HubAvailabilityDto } from "src/services/hubAvailability/hubAvailability.dto";
import Page from "src/components/page";
import useAsyncRetry from "src/hooks/useAsyncRetry";
import {
  AvailabilityForm,
  initialHubAvailability,
} from "src/pages/hubAvailability/availabilityForm";
import HubAvailabilityList from "src/pages/hubAvailability/hubAvailabilityList";
import hubService from "src/services/hub/hub.service";
import { HubAvailabilityModel } from "src/services/hubAvailability/hubAvailability.model";
import hubAvailabilityService from "src/services/hubAvailability/hubAvailability.service";
import { Helmet } from "react-helmet-async";
import { withAuthAndAuthRequired } from "../../hooks/withAuthorisationRequired";

const EditHubAvailabilityPage = () => {
  const { id: idString } = useParams();

  const id = React.useMemo(() => parseInt(idString || "", 10), [idString]);

  const [selectedAvailability, setSelectedAvailability] = React.useState<
    HubAvailabilityDto | undefined
  >();

  const { value: allAvailabilities } = useAsyncRetry(async () => {
    return hubAvailabilityService.findAll();
  }, []);

  const { value: hub } = useAsyncRetry(async () => {
    if (isNaN(id)) return undefined;

    const hub = await hubService.findById(
      id,
      "hubAvailabilities,hubAvailabilities.pricingPlans"
    );

    return hub;
  }, [id]);

  const unusedAvailabilities = React.useMemo(() => {
    if (hub === undefined || allAvailabilities === undefined) return undefined;

    const selectedIds = hub.hubAvailabilities.map(({ id }) => id);
    return allAvailabilities.filter(({ id }) => !selectedIds.includes(id));
  }, [allAvailabilities, hub]);

  const onSubmit = React.useCallback(
    async (resource: HubAvailabilityDto) => {
      if (hub === undefined || selectedAvailability === undefined) return;

      if (resource.id === undefined) {
        await hubService.createAvailability(
          hub.id,
          resource as HubAvailabilityModel
        );
      } else {
        await hubService.updateAvailability(
          hub.id,
          selectedAvailability.id,
          resource as HubAvailabilityModel
        );
      }
    },
    [hub, selectedAvailability]
  );

  return (
    <Page
      title={
        <>
          Availability plans for <em>{hub?.name}</em>
        </>
      }
    >
      {hub && (
        <>
          <Helmet title={`Edit ${hub.name} Availabilities`} />
          {hub.hubAvailabilities.length === 0 ? (
            <Card>There is no availability set for this hub</Card>
          ) : (
            <Row>
              <Col>
                <HubAvailabilityList
                  availabilities={hub.hubAvailabilities}
                  setSelectedAvailability={setSelectedAvailability}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <Card className="m-3">
                <p>Existing plans that can be assigned to this hub</p>
                {unusedAvailabilities && (
                  <Row>
                    {unusedAvailabilities.length === 0 ? (
                      <Col>
                        <em>
                          All Availability plans have been assigned to this hub
                        </em>
                      </Col>
                    ) : (
                      <>
                        <Col>
                          <FormSelect>
                            {unusedAvailabilities?.map((availability) => (
                              <option
                                key={availability.id}
                                value={availability.id}
                              >
                                {availability.name}
                              </option>
                            ))}
                          </FormSelect>
                        </Col>
                        <Col>
                          <Button>Add existing availability plan</Button>
                        </Col>
                      </>
                    )}
                  </Row>
                )}
              </Card>
            </Col>
            <Col>
              <Card className="m-3">
                {selectedAvailability === undefined && (
                  <>
                    <p>
                      A new plan can be created if nothing matches requirements
                    </p>
                    <Row>
                      <Col>
                        <Button
                          onClick={() =>
                            setSelectedAvailability({
                              ...initialHubAvailability,
                            } as HubAvailabilityDto)
                          }
                        >
                          Add new availability plan
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </>
      )}

      {selectedAvailability !== undefined && (
        <>
          <p>
            Note: Editing an availability plan will effect all hubs that use
            this plan
          </p>
          <AvailabilityForm
            availability={selectedAvailability}
            onSubmit={onSubmit}
            onCancel={async () => setSelectedAvailability(undefined)}
          />
        </>
      )}
    </Page>
  );
};

export default withAuthAndAuthRequired(EditHubAvailabilityPage);
