import React, { ReactNode } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { SideNav } from "./sideNav";
// import { TopNav } from "./topNav";

const Page = ({
  children,
  title = "",
}: {
  children: ReactNode | ReactNode[];
  title?: string | ReactNode;
}) => {
  return (
    <Container fluid className="p-0">
      <Row className="m-0 flex-nowrap min-vh-100">
        <Col sm="auto" className="p-0 d-flex">
          <SideNav />
        </Col>

        <Col sm="10" className="flex-grow-1 p-0 bg-light">
          <Container className="p-0">
            <Row className="m-0 p-0">
              <Col className="p-4">
                <h2>{title}</h2>
                {children}
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>

    // <Container fluid className="p-0">
    //   <TopNav />

    //   <Container className="pt-3">
    //     {title && <h2>{title}</h2>}
    //     {children}
    //   </Container>
    // </Container>
  );
};

export default Page;
