import { model, property } from "decoverto";

@model()
export class MediaModel {
  @property(() => Number)
  id!: number;

  @property(() => String)
  type!: "image" | "video";

  @property(() => String)
  title!: string;

  @property(() => String)
  url!: string;
}
