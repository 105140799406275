import React, { ComponentType, FC, ReactNode } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import LoadingPage from "../pages/loading.page";
import { useUserContext } from "../context/UserAccountContext";
import NotAuthorisedPage from "../pages/notAuthorised.page";

interface WithAuthorisationRequiredOptions {
  unauthorised?: ReactNode;
}

const withAuthorisationRequired = <P extends object>(
  Component: ComponentType<P>,
  options: WithAuthorisationRequiredOptions = {}
): FC<P> => {
  return function WithAuthorisationRequired(props: P) {
    const { error } = useUserContext();
    if (error) {
      return <>{options.unauthorised || <>Error</>}</>;
    }

    return <Component {...props} />;
  };
};

export default withAuthorisationRequired;

export const withAuthAndAuthRequired = <P extends object>(
  Component: ComponentType<P>
): FC<P> => {
  return withAuthenticationRequired(
    withAuthorisationRequired(Component, {
      unauthorised: <NotAuthorisedPage />,
    }),
    {
      onRedirecting: () => <LoadingPage />,
    }
  );
};
