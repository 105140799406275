import React from "react";
import { Button } from "react-bootstrap";
import { HubAvailabilityDto } from "src/services/hubAvailability/hubAvailability.dto";
import { DayOfWeek } from "src/components/DayOfWeek";
import DynamicTable from "src/components/dynamicTable";
import ModalButton from "src/components/modalButton";
import { TimeOfDay } from "src/components/TimeOfDay";

const HubAvailabilityList = ({
  availabilities,
  setSelectedAvailability,
  onDelete,
  isLoading,
}: {
  availabilities?: HubAvailabilityDto[];
  setSelectedAvailability?: (resource: HubAvailabilityDto) => void;
  onDelete?: (resource: HubAvailabilityDto) => void;
  isLoading?: boolean;
}) => {
  return (
    <>
      <DynamicTable<HubAvailabilityDto>
        isLoading={isLoading}
        titles={{
          name: "Name",
          dayOfWeek: "Day of Week",
          startTime: "Start time",
          endTime: "End time",
          pricingPlans: "Pricing plans",
          controls: "",
        }}
        mappers={{
          dayOfWeek: ({ dayOfWeek }) => <DayOfWeek dayOfWeek={dayOfWeek} />,
          startTime: ({ startTime }) => <TimeOfDay timeInMinutes={startTime} />,
          endTime: ({ endTime }) => <TimeOfDay timeInMinutes={endTime} />,
          pricingPlans: (availability) => (
            <>
              {availability.pricingPlans
                ?.map((plan) => plan.displayName)
                ?.join(",")}
            </>
          ),
          controls: (availability) => (
            <div style={{ textAlign: "right" }}>
              <ModalButton
                title="Remove availability plan"
                text="Remove"
                variant="danger"
                footer={({ hideModal }) => (
                  <>
                    <Button variant="secondary" onClick={() => hideModal()}>
                      Cancel
                    </Button>
                    <Button
                      variant="danger"
                      onClick={async () => {
                        await onDelete?.(availability);
                      }}
                    >
                      Remove
                    </Button>
                  </>
                )}
              >
                Remove this availability from the hub?
              </ModalButton>
            </div>
          ),
        }}
        rows={availabilities}
        onSelectRow={(availability) => setSelectedAvailability?.(availability)}
      />
    </>
  );
};

export default HubAvailabilityList;
