import { Optional } from "src/types/properties";
import { HubDto } from "src/services/hub/hub.dto";
import { MediaDto } from "src/services/media/media.dto";
import { SiteCreateResource } from "src/services/site/site.dto";
import { HubModel } from "src/services/hub/hub.model";
import { MediaModel } from "src/services/media/media.model";
import { array, model, property } from "decoverto";
import { PaginationResultDto } from "../searchableService";

@model()
export class SiteModel {
  @property(() => Number)
  id!: number;

  @property(() => String)
  name!: string;

  @property(() => String)
  summary!: string;

  @property(() => String)
  description!: string;

  @property(() => String)
  notes!: string;

  @property(() => String)
  addressLine0!: string;

  @property(() => String)
  addressLine1!: string;

  @property(() => MediaModel)
  featuredMedia?: MediaDto;

  @property(() => Number)
  featuredMediaId?: number | null;

  @property(array(() => HubModel)) //(HubDTO)
  hubs?: HubDto[];

  @property(() => Boolean)
  isActive!: boolean;

  @property(() => Number)
  latitude!: number;

  @property(() => Number)
  longitude!: number;

  @property(array(() => MediaModel))
  media?: MediaDto[];
}

@model()
export class SiteModelSearchResults implements PaginationResultDto<SiteModel> {
  @property(array(() => SiteModel))
  public rows!: SiteModel[];

  @property(() => Number)
  public count!: number;
}

export type SiteCreateDTO = SiteCreateResource;

export type SiteCreateOrUpdateDTO = Optional<
  SiteModel,
  "id" | "hubs" | "featuredMedia" | "media"
>;

//console.log(ArrayTypeDescriptor);
// @ts-ignore
//console.log(SiteResponseDTO.prototype.__typedJsonmodelMetadataInformation__.dataMembers.get("hubs").type);
