import React from "react";
import Page from "src/components/page";
import { Link, useNavigate, useParams } from "react-router-dom";
import accountService from "../../services/account/account.service";
import useAsyncRetry from "../../hooks/useAsyncRetry";
import {
  Badge,
  Breadcrumb,
  Button,
  Card,
  Col,
  Dropdown,
  Row,
  Stack,
} from "react-bootstrap";
import { BookingModel } from "../../services/booking/booking.model";
import bookingService from "../../services/booking/booking.service";
import {
  PaginationResultDto,
  SearchableService,
  SearchProps,
} from "../../services/searchableService";
import BookingsTable from "../../components/BookingsTable";
import DateTime from "../../components/DateTime";
import { Helmet } from "react-helmet-async";
import { withAuthAndAuthRequired } from "../../hooks/withAuthorisationRequired";
import ModalButton from "../../components/modalButton";
import tryAsyncToast from "../../hooks/tryAsyncToast";
import { MdOutlineWarning } from "react-icons/md";
import CustomerSubscriptions from "./customer.subscriptions.component";

const CustomerPage = () => {
  const { id } = useParams();

  const { value: account } = useAsyncRetry(async () => {
    if (id === undefined) return undefined;
    const idVal = parseInt(id, 10);
    if (isNaN(idVal)) return undefined;
    const { data } = await accountService.findAccountDetails(idVal);
    return data;
  });

  const bookingForCustomerSearchService: SearchableService<BookingModel> =
    React.useMemo(
      () => ({
        async search(
          search: SearchProps
        ): Promise<PaginationResultDto<BookingModel> | undefined> {
          return bookingService.search({
            ...search,
            filter: [
              ...(search.filter || "").split(","),
              `accountId=${account?.id}`,
            ].join(","),
          });
        },
        searchFilters(): { [p: string]: string } | undefined {
          return bookingService.searchFilters();
        },
      }),
      [account?.id]
    );

  const navigate = useNavigate();

  const deleteAccount = React.useCallback(async () => {
    if (!account) return;

    await tryAsyncToast(accountService.deleteAccount(account.id), {
      success: "Account was deleted",
    });

    navigate("/account");
  }, [account, navigate]);

  return (
    <Page>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/customer" }}>
          Customers
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{id}</Breadcrumb.Item>
      </Breadcrumb>
      {account ? (
        <>
          <Helmet title={`Customer - ${account.displayName}`} />
          <Stack direction="horizontal" gap={3} className="mb-3">
            <div>
              <h2>{account.displayName}</h2>
            </div>

            {account.type !== "deleted" ? (
              <>
                <div className="ms-auto">
                  <ModalButton
                    variant="outline-danger"
                    title="Confirm account deletion"
                    text="Delete Account"
                    footer={({ hideModal }) => (
                      <>
                        <Button onClick={hideModal} variant="secondary">
                          Cancel
                        </Button>
                        <Button onClick={deleteAccount} variant="danger">
                          Confirm
                        </Button>
                      </>
                    )}
                  >
                    <p>Are you sure you want to delete this Account.</p>
                    <p>The user will no longer be able to log in.</p>
                    <p>
                      The account will be obfuscated and encrypted, and
                      re-registration will create a totally new account.
                    </p>
                  </ModalButton>
                </div>
                <div>
                  <Dropdown>
                    <Dropdown.Toggle variant="primary" id="dropdown-actions">
                      Actions
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        as={Link}
                        to={`/account/${account.id}/makeBooking`}
                      >
                        Make Booking
                      </Dropdown.Item>
                      <Dropdown.Item
                        tag={"a"}
                        target="_blank"
                        href={`https://dashboard.stripe.com/${
                          process.env.REACT_APP_API_BASE !==
                          "https://api.workfromhub.co.uk"
                            ? "test/"
                            : ""
                        }subscriptions?create=subscription${
                          account.stripeCustomerId
                            ? `&subscription_default_customer=${account.stripeCustomerId}`
                            : ""
                        }`}
                        rel="noreferrer"
                      >
                        Add Subscription
                      </Dropdown.Item>
                      {/* <Dropdown.Divider />
                      <Dropdown.Item
                        as={ModalButton}
                        variant="outline-danger"
                        title="Confirm account deletion"
                        text="Delete Account"
                        footer={({ hideModal }) => (
                          <>
                            <Button onClick={hideModal} variant="secondary">
                              Cancel
                            </Button>
                            <Button onClick={deleteAccount} variant="danger">
                              Confirm
                            </Button>
                          </>
                        )}
                      >
                        <p>Are you sure you want to delete this Account.</p>
                        <p>The user will no longer be able to log in.</p>
                        <p>
                          The account will be obfuscated and encrypted, and
                          re-registration will create a totally new account.
                        </p>
                      </Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </>
            ) : (
              <></>
            )}
          </Stack>

          <Row>
            <Col lg={9} md={12}>
              <CustomerSubscriptions
                customerId={id}
                stripeCustomerId={account.stripeCustomerId}
              />
              <Card className="mb-3">
                <Card.Body>
                  <Card.Title>Bookings</Card.Title>
                  <BookingsTable
                    useWrapper={false}
                    canSearch={false}
                    service={bookingForCustomerSearchService}
                  />
                </Card.Body>
              </Card>
            </Col>

            <Col lg={3} md={12}>
              <Card className="mb-3">
                <Card.Body className="pb-0">
                  <Card.Title>Customer</Card.Title>
                  <p>
                    <strong>Email</strong>
                    <br />
                    <a href={`mailto:${account.email}`}>{account.email}</a>
                  </p>
                  <p>
                    <strong>Type</strong>
                    <br />
                    <Badge bg="primary">{account.type}</Badge>
                  </p>
                </Card.Body>
                <hr />
                <Card.Body className="pt-0">
                  <h6>Address</h6>
                  {account.addressLine0 ? (
                    <>
                      <p>{account.addressLine0}</p>
                      {account.addressLine1 && <p>{account.addressLine1}</p>}
                      {account.postcode && <p>{account.postcode}</p>}
                    </>
                  ) : (
                    <p>
                      <Badge bg="dark">Not Specified</Badge>
                    </p>
                  )}
                </Card.Body>
              </Card>

              <Card className="mb-3">
                <Card.Body>
                  <Card.Title>Stripe</Card.Title>
                  {account.stripeCustomerId ? (
                    <>
                      {/* <p>
                        <strong>Payment methods</strong>
                        <br />
                        <FaCcVisa /> **** 4242
                        <br />
                        <FaCcMastercard /> **** 4242
                        <br />
                        <FaCcAmex /> **** 4242
                      </p> */}
                      <a
                        target="_blank"
                        href={`https://dashboard.stripe.com/${
                          process.env.REACT_APP_API_BASE !==
                          "https://api.workfromhub.co.uk"
                            ? "test/"
                            : ""
                        }customers/${account.stripeCustomerId}`}
                        rel="noreferrer"
                      >
                        <Button
                          variant="primary"
                          style={{
                            width: "100%",
                            backgroundColor: "#625afa",
                            borderColor: "#625afa",
                          }}
                        >
                          View in Stripe
                        </Button>
                      </a>
                    </>
                  ) : (
                    <Badge bg="dark">No payments</Badge>
                  )}
                </Card.Body>
              </Card>

              <Card className="mb-3">
                <Card.Body>
                  <Card.Title>Marketing</Card.Title>
                  <p>
                    <strong>Communication</strong>
                    <br />
                    <Badge
                      bg={account.marketingEmailConfirm ? "success" : "danger"}
                    >
                      {account.marketingEmailConfirm
                        ? "signed up"
                        : "opted out"}
                    </Badge>
                  </p>
                  {account.marketingEmailConfirm && (
                    <a
                      target="_blank"
                      href={`https://app.hubspot.com/`}
                      rel="noreferrer"
                    >
                      <Button
                        variant="secondary"
                        style={{ width: "100%" }}
                        disabled
                      >
                        View in Hubspot
                      </Button>
                    </a>
                  )}
                </Card.Body>
              </Card>

              <Card className="mb-3">
                <Card.Body>
                  <Card.Title>Login</Card.Title>
                  <p>
                    <strong>Last login</strong>
                    <br />
                    {account.user?.lastLogin ? (
                      <DateTime date={account.user?.lastLogin} />
                    ) : (
                      <Badge bg="dark">Never</Badge>
                    )}
                  </p>
                  <p>
                    <strong>Identity</strong>
                    <br />
                    {account.user?.authentication}
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      ) : (
        <Card className="text-center mb-3">
          <Card.Body className="py-5">
            <MdOutlineWarning size="5em" className="mb-3" />
            <h3>There’s no customer at this address</h3>
            <p>
              Check the URL and try again, or use the customers page to find
              what you need.
            </p>
          </Card.Body>
        </Card>
      )}
    </Page>
  );
};

export default withAuthAndAuthRequired(CustomerPage);
