import { DependencyList, useCallback, useEffect } from "react";
import useAsyncCallback, { AsyncCallbackState } from "./useAsyncCallback";

export interface AsyncRetryState<T, Args extends unknown[] = []>
  extends AsyncCallbackState<T> {
  retry: (...args: Args) => void;
}

/**
 * Hook that encapsulates state around an asynchronous
 * task such as loading, error or success states.
 * In the event of an error, we also provide a retry
 * method to retry the originally provided task.
 */
export default function useAsyncRetry<Value, Args extends unknown[] = []>(
  task: (...args: Args) => Promise<Value>,
  deps: DependencyList = []
): AsyncRetryState<Value, Args> {
  const [state, run] = useAsyncCallback<Value, Args>(task, deps);
  const { isLoading } = state;

  useEffect(() => {
    // @ts-ignore
    run();
  }, [run]);

  const retry = useCallback(
    async (...params: Args) => {
      if (!isLoading) {
        await run(...params);
      }
    },
    [isLoading, run]
  );

  return {
    ...state,
    retry,
  };
}
