import React from "react";
import { Col, Container, Image, Row, Spinner, Stack } from "react-bootstrap";
import Logo from "src/images/wfh-logo-text-white.png";

const LoadingPage = () => {
  return (
    <Container fluid className="bg-dark vh-100">
      <Row className="justify-content-md-center">
        <Col md="auto" className="">
          <Stack gap={3}>
            <Image src={Logo} width="160px" className="ms-3  p-4" />
          </Stack>
          <Stack gap={3} className="mx-auto">
            <Spinner animation="border" variant="light" />
            <p className="text-white" data-testid="loading">
              Loading...
            </p>
          </Stack>
        </Col>
      </Row>
    </Container>
  );
};

export default LoadingPage;
