import React from "react";
import { Button, Stack } from "react-bootstrap";
import Page from "src/components/page";
import ButtonLink from "../../components/ButtonLink";
import { Helmet } from "react-helmet-async";
import { withAuthAndAuthRequired } from "../../hooks/withAuthorisationRequired";

const ViewBookingsPage = () => {
  return (
    <Page>
      <Helmet title="Bookings" />
      <Stack direction="horizontal" gap={3}>
        <div>
          <h2>Bookings</h2>
        </div>
        <div className="ms-auto">
          <Button variant="outline-secondary" disabled>
            Export
          </Button>
        </div>
        <div>
          <ButtonLink variant="primary" size="md" to="/checkout">
            Add Booking
          </ButtonLink>
        </div>
      </Stack>
    </Page>
  );
};

export default withAuthAndAuthRequired(ViewBookingsPage);
