import React from "react";
import { Col, Row } from "react-bootstrap";
import { MediaFieldControl } from "src/components/mediaFieldControl";
import MediaFieldGallery from "src/components/mediaFieldGallery";
import { HubModel } from "src/services/hub/hub.model";
import { MediaModel } from "src/services/media/media.model";
import { Optional } from "../../types/properties";
import hubService from "../../services/hub/hub.service";

export const HubImages = ({
  hub,
  onSave,
}: {
  hub: Optional<HubModel, "id">;
  onSave: (
    partialMedia: Partial<Pick<HubModel, "featuredMedia" | "media">>
  ) => Promise<void>;
}) => {
  const saveFeaturedMedia = React.useCallback(
    async (media: MediaModel) => {
      if (hub.id !== undefined) {
        await hubService.updateFeaturedMedia(hub.id, media);
      }
      await onSave({ featuredMedia: media });
    },
    [onSave, hub.id]
  );

  const removeFeaturedMedia = React.useCallback(async () => {
    if (hub.id !== undefined) {
      await hubService.updateFeaturedMedia(hub.id, null);
    }
    await onSave({ featuredMedia: undefined });
  }, [onSave, hub.id]);

  const updateMediaGalleryItem = React.useCallback(
    async (medium: MediaModel, index: number) => {
      if (index < 0 || index >= hub.media.length)
        throw new Error("Invalid media index deleted");

      if (hub.id !== undefined) {
        await hubService.updateMedia(hub.id, hub.media[index].id, medium);
      }

      const media = [...hub.media];
      media[index] = medium;

      await onSave({ media });
    },
    [onSave, hub.id, hub.media]
  );

  const removeMediaGalleryItem = React.useCallback(
    async (index: number) => {
      if (index < 0 || index >= hub.media.length)
        throw new Error("Invalid media index deleted");

      if (hub.id !== undefined) {
        await hubService.deleteMedia(hub.id, hub.media[index].id);
      }

      const media = [...hub.media];
      media.splice(index, 1);

      await onSave({ media });
    },
    [onSave, hub.id, hub.media]
  );

  const createMediaGalleryItem = React.useCallback(
    async (medium: MediaModel) => {
      if (hub.id !== undefined) {
        await hubService.createMedia(hub.id, medium);
      }
      const media = [...hub.media, medium];
      await onSave({ media });
    },
    [onSave, hub.id, hub.media]
  );

  return (
    <>
      <h2 className="mt-3 h4">Hub images</h2>
      <Row sm={2} md={3} lg={3} xl={3} className="gx-3">
        <Col>
          <MediaFieldControl
            label="Featured Image"
            initialMediaField={hub.featuredMedia}
            onSave={saveFeaturedMedia}
            onRemove={removeFeaturedMedia}
            autoUpdateMedia={hub.id !== undefined}
          />
        </Col>

        <MediaFieldGallery
          onSave={updateMediaGalleryItem}
          onRemove={removeMediaGalleryItem}
          onCreate={createMediaGalleryItem}
          values={hub.media}
          autoUpdateMedia={hub.id !== undefined}
        />
      </Row>
    </>
  );
};
