import { css, cx } from "@emotion/css";
import { Formik, FormikHelpers } from "formik";
import L, { LatLngExpression } from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import "leaflet/dist/leaflet.css";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { MdOutlineCancel, MdOutlineEdit, MdOutlineSave } from "react-icons/md";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import DraggableMarker from "src/components/draggableMarker";
import { SiteCreateOrUpdateDTO } from "src/services/site/site.model";
import * as Yup from "yup";
import { FormFieldControl } from "../../components/formFieldControl";

const markerIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconAnchor: [12.5, 41],
});

const markerIcon2 = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconAnchor: [12.5, 41],
  className: css`
    filter: hue-rotate(60deg);
  `,
});

export interface SiteLocationForm {
  addressLine0: string;
  addressLine1: string;
  latitude: number;
  longitude: number;
}

const SiteLocationFormSchema = Yup.object().shape({
  addressLine0: Yup.string().required("First line of address is required"),
  addressLine1: Yup.string(),
  latitude: Yup.number().required("A latitude is required"),
  longitude: Yup.number().required("A longitude is required"),
});

export const SiteLocation = ({
  site,
  onSubmit,
}: {
  site: SiteCreateOrUpdateDTO;
  onSubmit: (values: SiteLocationForm) => Promise<void>;
}) => {
  const location = React.useMemo<SiteLocationForm>(() => {
    return {
      addressLine0: site.addressLine0,
      addressLine1: site.addressLine1,
      latitude: site.latitude,
      longitude: site.longitude,
    };
  }, [site.addressLine0, site.addressLine1, site.latitude, site.longitude]);

  const [isEditing, setIsEditing] = React.useState(false);

  const mapRef = React.useRef<L.Map>();

  const updateMapCenter = React.useCallback((latlng: LatLngExpression) => {
    if (mapRef.current) {
      mapRef.current.flyTo(latlng);
    }
  }, []);

  const _onSubmit = async (
    values: SiteLocationForm,
    { setSubmitting }: FormikHelpers<SiteLocationForm>
  ) => {
    setSubmitting(true);
    await onSubmit(values);
    await updateMapCenter([values.latitude, values.longitude]);
    setSubmitting(false);
    setIsEditing(false);
  };

  const cancel = (values: SiteLocationForm) => {
    updateMapCenter([values.latitude, values.longitude]);
    setIsEditing(false);
  };

  return (
    <>
      <Formik<SiteLocationForm>
        validationSchema={SiteLocationFormSchema}
        enableReinitialize
        onSubmit={_onSubmit}
        initialValues={{ ...location }}
      >
        {({
          handleSubmit,
          values,
          setFieldValue,
          handleReset,
          resetForm,
          submitForm,
          isSubmitting,
        }) => (
          <Card className="mb-3">
            <Card.Body>
              <Row>
                <Col sm="auto">
                  <Card.Title>Location</Card.Title>
                </Col>
                <Col>
                  {isEditing ? (
                    <>
                      <MdOutlineSave
                        data-testid="save-location"
                        onClick={async () => {
                          if (!isSubmitting) await submitForm();
                        }}
                        title="Save"
                        role="button"
                        className={cx("h4", isSubmitting ? "text-muted" : "")}
                      />
                      <MdOutlineCancel
                        onClick={() => {
                          if (!isSubmitting) {
                            resetForm();
                            cancel(location);
                          }
                        }}
                        title="Cancel"
                        role="button"
                        className={cx("h4", isSubmitting ? "text-muted" : "")}
                      />
                    </>
                  ) : (
                    <>
                      <MdOutlineEdit
                        data-testid="edit-location"
                        role="button"
                        onClick={() => setIsEditing(true)}
                        className="h4"
                      />
                    </>
                  )}
                </Col>
              </Row>

              <Row
                className={css`
                  height: 400px;
                `}
              >
                <Col
                  className={css`
                    display: flex;
                  `}
                >
                  <MapContainer
                    whenCreated={(map) => (mapRef.current = map)}
                    center={[location.latitude, location.longitude]}
                    zoom={16}
                    placeholder={
                      <img
                        src="https://via.placeholder.com/728x400.png?text=Location map"
                        alt="Location map"
                        className="w-100"
                      />
                    }
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />

                    <DraggableMarker
                      center={[values.latitude, values.longitude]}
                      onDragEnd={(latlng) => {
                        setFieldValue("latitude", latlng.lat);
                        setFieldValue("longitude", latlng.lng);
                      }}
                      onDragging={(latlng) => {
                        setFieldValue("latitude", latlng.lat);
                        setFieldValue("longitude", latlng.lng);
                      }}
                      icon={markerIcon}
                      draggable={isEditing}
                      title={site.name}
                    />

                    {site.hubs?.map((hub) => (
                      <Marker
                        key={hub.id}
                        title={hub.name}
                        position={[hub.latitude, hub.longitude]}
                        icon={markerIcon2}
                      />
                    ))}
                  </MapContainer>
                </Col>
              </Row>
              {isEditing ? (
                <Row className="my-3">
                  <Col>
                    <h3 className="h5">Address</h3>
                    <FormFieldControl
                      fieldName="addressLine0"
                      label="Address line 1"
                      placeholder="First line"
                      showLabel
                      className="mb-4"
                    />
                    <FormFieldControl
                      fieldName="addressLine1"
                      label="Address line 2"
                      placeholder="Second line"
                      showLabel
                    />
                  </Col>
                  <Col>
                    <h3 className="h5">Map coordinates</h3>
                    <FormFieldControl
                      fieldName="latitude"
                      label="Latitude"
                      placeholder="Latitude"
                      showLabel
                      type="number"
                      className="mb-4"
                    />
                    <FormFieldControl
                      fieldName="longitude"
                      label="Longitude"
                      placeholder="Longitude"
                      showLabel
                      type="number"
                    />
                  </Col>
                </Row>
              ) : (
                <>
                  <Row className="my-3">
                    <Col>
                      <h3 className="h5">Address</h3>
                      {site.addressLine0 || <em>Not set</em>}
                      {site.addressLine1}
                    </Col>
                    <Col>
                      <h3 className="h5">Map coordinates</h3>
                      <dl>
                        <dt>Latitude</dt>
                        <dd>{site.latitude}</dd>
                        <dt>Longitude</dt>
                        <dd>{site.longitude}</dd>
                      </dl>
                    </Col>
                  </Row>
                </>
              )}
            </Card.Body>
          </Card>
        )}
      </Formik>
    </>
  );
};
