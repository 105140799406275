import { BaseService } from "src/services/baseService";
import {
  PricingPlanModel,
  PricingPlanSearchResults,
} from "src/services/pricingPlan/pricingPlan.model";
import {
  decodeSearch,
  SearchableService,
  SearchProps,
} from "src/services/searchableService";

class PricingPlanService
  extends BaseService<PricingPlanModel>
  implements SearchableService<PricingPlanModel>
{
  constructor() {
    super(PricingPlanModel, "/pricingPlan");
  }

  async findAll() {
    return (await this._getArray()).data;
  }

  async findById(id: string) {
    const result = await this._getParsed(`/${id}`);
    return result.data;
  }

  async search(search: SearchProps) {
    const params = decodeSearch(search);

    return (
      await this._getParsed(`/search?${params}`, {}, PricingPlanSearchResults)
    ).data;
  }

  async create(pricingPlan: PricingPlanModel) {
    const result = await this._post("", pricingPlan);
    return result.data;
  }

  async update(id: number, pricingPlan: PricingPlanModel) {
    const result = await this._patch(`/${id}`, pricingPlan);
    return result.data;
  }

  async deleteById(id: number) {
    const result = await this._delete(`/${id}`);
    return result.data;
  }

  searchFilters(): { [p: string]: string } | undefined {
    return undefined;
  }
}

const pricingPlanService = new PricingPlanService();

export default pricingPlanService;
