import React from "react";
import { Badge } from "react-bootstrap";
import { MembershipAccountDto } from "src/services/membershipAccount/membershipAccount.dto";
import DateFormat, { DateFormats, UTC } from "../DateFormat";

export const SubscriptionStatusBadge = ({
  subscription,
}: {
  subscription: MembershipAccountDto;
}) => {
  switch (subscription.status) {
    case "active":
      return (
        <>
          <Badge bg="success">Active</Badge>{" "}
          {subscription.cancelOn && (
            <Badge bg="secondary">
              Cancels{" "}
              <UTC>
                <DateFormat
                  format={DateFormats.dayMonth}
                  date={subscription.cancelOn}
                />
              </UTC>
            </Badge>
          )}
        </>
      );
    case "canceled":
      return <Badge bg="secondary">Canceled</Badge>;
    case "trialing":
      return (
        <Badge bg="info">
          Trial ends{" "}
          <UTC>
            <DateFormat
              format={DateFormats.dayMonth}
              date={subscription.currentPeriodEnd}
            />
          </UTC>
        </Badge>
      );
    default:
      return <Badge bg="warning">{subscription.status}</Badge>;
  }
};
