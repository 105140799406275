import { BaseService } from "../baseService";
import {
  decodeSearch,
  PaginationResultDto,
  SearchableService,
  SearchProps,
} from "../searchableService";
import {
  MembershipAccount,
  MembershipAccountSearchResults,
} from "./membershipAccount.model";

class MembershipAccountService
  extends BaseService<MembershipAccount>
  implements SearchableService<MembershipAccount>
{
  constructor() {
    super(MembershipAccount, "/membershipAccount");
  }
  async search(
    search: SearchProps
  ): Promise<PaginationResultDto<MembershipAccount> | undefined> {
    const params = decodeSearch(search);

    return (
      await this._getParsed(
        `/search?${params}`,
        {},
        MembershipAccountSearchResults
      )
    ).data;
  }

  searchFilters(): { [p: string]: string } | undefined {
    return undefined;
  }
}

const membershipAccountService = new MembershipAccountService();

export default membershipAccountService;
