import { HubAllocationModel } from "../hubAllocation/hubAllocation.model";
import { BaseService } from "../baseService";
import { HubAvailabilityModel } from "../hubAvailability/hubAvailability.model";
import { PricingPlanModel } from "../pricingPlan/pricingPlan.model";
import { CreateBookingData, CreateBookingDataModel } from "./checkout.dto";
import { CheckoutModel } from "./checkout.model";
import moment from "src/types/momentWithLocale";

class CheckoutService extends BaseService<CheckoutModel> {
  constructor() {
    super(undefined, "/checkout");
  }

  async getHubAllocations(id: number, date: Date) {
    const date_day = moment(date).utc(true).format("yyyy-MM-DD");

    return (
      await this._getArray(
        `/hub/${id}/${date_day}/allocations`,
        undefined,
        HubAllocationModel
      )
    ).data;
  }

  async getHubAvailabilities(id: number) {
    return (
      await this._getArray(
        `/hub/${id}/availabilities`,
        undefined,
        HubAvailabilityModel
      )
    ).data;
  }

  async getPricingPlans(hubId: number, startDate: Date, endDate: Date) {
    const start = moment(startDate).utc(true).toISOString();
    const end = moment(endDate).utc(true).toISOString();

    const result = await this._getArray<PricingPlanModel>(
      `/hub/${hubId}/availabilities/${start}/${end}/pricing`,
      undefined,
      PricingPlanModel
    );

    return result.data;
  }

  async createBooking(bookingData: CreateBookingData): Promise<CheckoutModel> {
    // quick sanity check on things that can be tested in the client

    if (
      bookingData.accountId === undefined &&
      bookingData.guestAccountEmailAddress === undefined
    ) {
      throw new Error("Account and Email not set, invalid Booking");
    }

    if (bookingData.hubId === undefined) {
      throw new Error("Hub Id has not been set");
    }

    if (bookingData.startDateTime === undefined) {
      throw new Error("Start date time not set");
    }

    if (bookingData.endDateTime === undefined) {
      throw new Error("End date time not set");
    }

    if (bookingData.totalCost === 0 && bookingData.zeroCostConfirmed !== true) {
      throw new Error("Booking marked as FREE but not confirmed to be allowed");
    }

    return (
      await this._post<CreateBookingDataModel, CheckoutModel>(
        `/`,
        bookingData,
        undefined,
        CheckoutModel
      )
    ).data;
  }

  async extendBooking(bookingId: number): Promise<CheckoutModel> {
    return (
      await this._post<any, CheckoutModel>(
        `/order/${bookingId}/extend`,
        {
          extensionTime: 60,
        },
        undefined,
        CheckoutModel
      )
    ).data;
  }
}

const checkoutService = new CheckoutService();

export default checkoutService;
