import React, { useMemo } from "react";
import ButtonLink from "../../components/ButtonLink";
import Page from "../../components/page";
import SearchTable from "../../components/searchTable";
import { OrderModel } from "../../services/order/order.model";
import orderService from "../../services/order/order.service";
import OrderStatus from "./orderStatus";
import DateFormat, {
  DateFormats,
  DateUTCInfo,
  UTC,
} from "../../components/DateFormat";
import { Helmet } from "react-helmet-async";
import { withAuthAndAuthRequired } from "../../hooks/withAuthorisationRequired";
import { Badge, Spinner } from "react-bootstrap";
import { orderStatusBadge } from "../bookings/view";
import { Link, useSearchParams } from "react-router-dom";
import MoneyFormat from "src/components/MoneyFormat";
import useAsyncRetry from "../../hooks/useAsyncRetry";
import discountService from "../../services/discount/discount.service";
import { overrideSearchService } from "../../services/searchableService";

const OrdersPage = () => {
  const [searchParams] = useSearchParams();

  const discountId = useMemo(() => {
    const id = searchParams.get("discountId");
    if (id === null) return undefined;
    const asInt = Number.parseInt(id);
    if (Number.isNaN(asInt)) return undefined;
    return asInt;
  }, [searchParams]);

  const { value: discount, isLoading: isLoadingDiscount } = useAsyncRetry(
    async () => {
      if (discountId === undefined) return undefined;

      return discountService.findById(discountId);
    }
  );

  const newService = useMemo(() => {
    if (discount === undefined) return orderService;

    return overrideSearchService(orderService, {
      search: (service, search) => {
        search.filter = [
          ...(search.filter || "").split(","),
          `discountId=${discountId}`,
        ]
          .filter((_) => _ !== "")
          .join(",");

        return service.search(search);
      },
      searchFilters: (service) => {
        return service.searchFilters();
      },
    });
  }, [discount, discountId]);

  return (
    <Page>
      <Helmet title="Orders" />

      {isLoadingDiscount ? (
        <Spinner animation="border" variant="light" />
      ) : (
        <SearchTable<OrderModel>
          caption={
            discount ? <>Orders for discount {discount.code}</> : <>Orders</>
          }
          action={
            <div>
              <ButtonLink variant="success" to="/checkout">
                Make Booking
              </ButtonLink>
            </div>
          }
          numberPerPage={10}
          sortableColumns={[
            "id",
            "amount",
            "account",
            "createdAt",
            "status",
            "latestBookingStartDate",
          ]}
          extraKeys={["rowIdx"]}
          rowIndex="rowIdx"
          defaultSortColumn="createdAt"
          defaultSortAscending={false}
          service={newService}
          titles={{
            id: "id",
            amount: "Amount",
            status: "Status",
            createdAt: (
              <>
                Placed <DateUTCInfo />
              </>
            ),
            account: "Customer",
            latestBookingStartDate: (
              <>
                Booking Starts <DateUTCInfo />
              </>
            ),
            controls: "",
          }}
          mappers={{
            rowIdx: (row) => row["id"],
            id: (row) => <Link to={`/order/${row.id}`}>{row.id}</Link>,
            account: (row) => (
              <Link to={`/customer/${row.accountId}`}>
                {row.account.displayName}{" "}
              </Link>
            ),
            createdAt: (row) => (
              <UTC>
                <DateFormat
                  format={DateFormats.dateTimeSimple}
                  date={row.createdAt}
                />
              </UTC>
            ),

            latestBookingStartDate: (row) => (
              <>
                {row.latestBookingStartDate ? (
                  <DateFormat
                    format={DateFormats.dateTimeSimpleUTC}
                    date={row.latestBookingStartDate}
                  />
                ) : (
                  "n/a"
                )}
              </>
            ),
            amount: (row) => <MoneyFormat value={row.totalCost} />,
            status: (row) => (
              <Badge bg={orderStatusBadge(row.status)}>
                <OrderStatus status={row.status} />
              </Badge>
            ),
            controls: (row) => (
              <div style={{ textAlign: "right" }}>
                <ButtonLink
                  variant="outline-secondary"
                  size="sm"
                  to={`/order/${row.id}`}
                >
                  View
                </ButtonLink>
              </div>
            ),
          }}
        />
      )}
    </Page>
  );
};

export default withAuthAndAuthRequired(OrdersPage);
