import React from "react";
import { Alert, Button, Card, Form, Spinner, Stack } from "react-bootstrap";
import { HubAvailabilityDto } from "src/services/hubAvailability/hubAvailability.dto";
import Page from "src/components/page";
import tryAsyncToast from "src/hooks/tryAsyncToast";
import useAsyncRetry from "src/hooks/useAsyncRetry";
import {
  AvailabilityForm,
  initialHubAvailability,
} from "src/pages/hubAvailability/availabilityForm";
import HubAvailabilityList from "src/pages/hubAvailability/hubAvailabilityList";
import { HubAvailabilityModel } from "src/services/hubAvailability/hubAvailability.model";
import hubAvailabilityService from "src/services/hubAvailability/hubAvailability.service";
import { Helmet } from "react-helmet-async";
import { withAuthAndAuthRequired } from "../../hooks/withAuthorisationRequired";
import ModalButton from "src/components/modalButton";
import { CsvDataService } from "src/services/CsvDataService";
import { MdEvent } from "react-icons/md";

const HubAvailabilityPage = () => {
  const {
    value: availabilities,
    setValue,
    isLoading,
  } = useAsyncRetry(async () => {
    return hubAvailabilityService.findAllWithPricingPlans();
  }, []);

  const [selectedAvailability, setSelectedAvailability] = React.useState<
    HubAvailabilityDto | undefined
  >();

  const onSubmit = React.useCallback(
    async (resource: HubAvailabilityDto) => {
      if (selectedAvailability === undefined || availabilities === undefined)
        return;

      if (resource.id === undefined) {
        const result = await tryAsyncToast(
          hubAvailabilityService.create(resource as HubAvailabilityModel),
          {
            success: "Availability Plan updated",
          }
        );

        // add result to availabilities
        setValue([...availabilities, result]);
      } else {
        await tryAsyncToast(
          hubAvailabilityService.update(
            selectedAvailability.id,
            resource as HubAvailabilityModel
          ),
          {
            success: "Availability Plan created",
          }
        );

        // replace specific availability, at correct index, based on id with the new values
        setValue(
          availabilities.map((availability) => {
            return availability.id === selectedAvailability.id
              ? (resource as HubAvailabilityModel)
              : availability;
          })
        );
      }

      setSelectedAvailability(undefined);
    },
    [availabilities, selectedAvailability, setValue]
  );

  const onDelete = React.useCallback(
    async (availability: HubAvailabilityDto) =>
      tryAsyncToast(
        async () => {
          if (availabilities === undefined) return;
          await hubAvailabilityService.remove(availability.id);

          // remove deleted availability based on id
          setValue(availabilities.filter(({ id }) => id !== availability.id));
        },
        {
          success: "Availability Plan deleted",
        }
      ),
    [availabilities, setValue]
  );

  const NoResultsDisplay = () => {
    return (
      <Card className="text-center mb-3">
        <Card.Body className="py-5">
          <MdEvent size="5em" className="mb-3" />
          <h3>Manage availability plans</h3>
          <p>Create discount codes that can be applied at checkout.</p>
          <Button
            variant="success"
            disabled={selectedAvailability !== undefined}
            onClick={() =>
              setSelectedAvailability({
                ...initialHubAvailability,
              } as HubAvailabilityDto)
            }
          >
            Create availability plan
          </Button>
        </Card.Body>
      </Card>
    );
  };

  const exportToCsv = (event: any) => {
    if (availabilities) {
      CsvDataService.exportToCsv(
        "availability-plans-export.csv",
        availabilities
      );
    }
  };

  return (
    <Page>
      <Helmet title="Availability Plans" />

      <Stack direction="horizontal" gap={3} className={"mb-3"}>
        <div>
          <h2>Availability Plans</h2>
        </div>

        <div className="ms-auto">
          <ModalButton
            variant="outline-secondary"
            title="Export Discounts"
            text="Export"
            footer={({ hideModal }) => (
              <>
                <Button onClick={hideModal} variant="outline-secondary">
                  Cancel
                </Button>
                <Button variant="success" onClick={exportToCsv}>
                  Export availability plans
                </Button>
              </>
            )}
          >
            <h6>Export</h6>
            <Form.Group key="export" className="mb-3">
              <Form.Check
                defaultChecked
                type="radio"
                id="export-all"
                name="export"
                label={`All availability plans`}
              />
            </Form.Group>

            <h6>Export as</h6>
            <Form.Group key="export-type" className="mb-3">
              <Form.Check
                defaultChecked
                type="radio"
                id="export-type-csv"
                name="export-type"
                label={`Plain CSV file`}
              />
            </Form.Group>
          </ModalButton>
        </div>
        <div className="vr" />
        <div>
          <Button
            variant="success"
            disabled={selectedAvailability !== undefined}
            onClick={() =>
              setSelectedAvailability({
                ...initialHubAvailability,
              } as HubAvailabilityDto)
            }
          >
            Create availability plan
          </Button>
        </div>
      </Stack>

      {isLoading && (
        <Alert variant="info" className="text-center mb-3">
          <Spinner animation="border" />
        </Alert>
      )}
      {!isLoading && (
        <>
          {availabilities === undefined ? (
            <NoResultsDisplay />
          ) : (
            <>
              <HubAvailabilityList
                availabilities={availabilities}
                setSelectedAvailability={setSelectedAvailability}
                onDelete={onDelete}
                isLoading={isLoading}
              />
            </>
          )}
        </>
      )}

      {selectedAvailability !== undefined && (
        <>
          <Alert variant="warning">
            <strong>Note:</strong> Editing an availability plan will effect all
            hubs that use this plan
          </Alert>
          <AvailabilityForm
            availability={selectedAvailability}
            onSubmit={onSubmit}
            onCancel={async () => setSelectedAvailability(undefined)}
          />
        </>
      )}
    </Page>
  );
};

export default withAuthAndAuthRequired(HubAvailabilityPage);
