import { model, property } from "decoverto";
import { DiscountModel } from "./discount.model";
import { MembershipModel } from "../membership/membership.model";
import { DiscountUsageDto } from "./discount.dto";

@model()
export class DiscountUsage implements DiscountUsageDto {
  @property(() => Number)
  id!: number;

  @property(() => Date)
  dateUsed!: Date;

  @property(() => DiscountModel)
  discount?: DiscountModel;

  @property(() => MembershipModel)
  membership?: MembershipModel;

  @property(() => Number)
  discountAmountApplied!: number;

  @property(() => String)
  type!: string;
}
