import React from "react";
import { Badge, Button, Card, Form, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { MdOutlinePin } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import ButtonLink from "src/components/ButtonLink";
import Page from "src/components/page";
import discountService from "src/services/discount/discount.service";
import { withAuthAndAuthRequired } from "../../hooks/withAuthorisationRequired";
import useAsyncRetry from "src/hooks/useAsyncRetry";
import { DiscountDto } from "src/services/discount/discount.dto";
import ModalButton from "src/components/modalButton";
import { CsvDataService } from "src/services/CsvDataService";
import SearchTable from "../../components/searchTable";
import { SearchProps } from "../../services/searchableService";
import { DiscountStatisticsModel } from "../../services/discount/discountStatistics.model";
import { DiscountModel } from "../../services/discount/discount.model";
import FilterCard from "../../components/filterCard";

export const discountStatusBadge = (discountStatus: string) => {
  switch (discountStatus) {
    case "active":
      return "success";
    case "expired":
      return "secondary";
    case "scheduled":
      return "warning";
    default:
      return "info";
  }
};

const DiscountTableFilters = ({
  statistics,
  searchParams,
  setSearchParams,
}: {
  statistics?: DiscountStatisticsModel;
  searchParams: SearchProps;
  setSearchParams: React.Dispatch<React.SetStateAction<SearchProps>>;
}) => {
  return (
    <Row>
      <FilterCard
        filterName="active"
        filterTitle="Active discounts"
        filterValue={statistics?.activeDiscounts}
        setSearchParams={setSearchParams}
        searchParams={searchParams}
      />
      <FilterCard
        filterName="expired"
        filterTitle="Expired discounts"
        filterValue={statistics?.expiredDiscounts}
        setSearchParams={setSearchParams}
        searchParams={searchParams}
      />
      <FilterCard
        filterName="scheduled"
        filterTitle="Scheduled discounts"
        filterValue={statistics?.scheduledDiscounts}
        setSearchParams={setSearchParams}
        searchParams={searchParams}
      />
      <FilterCard
        filterName="used"
        filterTitle="Used discounts"
        filterValue={statistics?.monthlyUses}
        setSearchParams={setSearchParams}
        searchParams={searchParams}
      />
    </Row>
  );
};

const ExportToCsvModal = ({ discounts }: { discounts?: DiscountModel[] }) => {
  const exportToCsv = (event: any) => {
    if (discounts) {
      CsvDataService.exportToCsv("discounts-export.csv", discounts);
    }
  };

  return (
    <ModalButton
      variant="outline-secondary"
      title="Export Discounts"
      text="Export"
      footer={({ hideModal }) => (
        <>
          <Button onClick={hideModal} variant="outline-secondary">
            Cancel
          </Button>
          <Button variant="success" onClick={exportToCsv}>
            Export discounts
          </Button>
        </>
      )}
    >
      <h6>Export</h6>
      <Form.Group key="export" className="mb-3">
        <Form.Check
          defaultChecked
          type="radio"
          id="export-all"
          name="export"
          label={`All discounts`}
        />
      </Form.Group>

      <h6>Export as</h6>
      <Form.Group key="export-type" className="mb-3">
        <Form.Check
          defaultChecked
          type="radio"
          id="export-type-csv"
          name="export-type"
          label={`Plain CSV file`}
        />
      </Form.Group>
    </ModalButton>
  );
};

const DiscountIndexPage = () => {
  const navigate = useNavigate();

  const { value: discounts } = useAsyncRetry(async () => {
    return discountService.findAll();
  }, []);

  const { value: statistics } = useAsyncRetry(async () => {
    return discountService.statistics();
  }, []);

  const NoResultsDisplay = () => {
    return (
      <Card className="text-center mb-3">
        <Card.Body className="py-5">
          <MdOutlinePin size="5em" className="mb-3" />
          <h3>Manage discount and promotions</h3>
          <p>Create discount codes that can be applied at checkout.</p>
          <ButtonLink variant="success" to="/discount/create">
            Create discount
          </ButtonLink>
        </Card.Body>
      </Card>
    );
  };

  return (
    <Page>
      <Helmet title="Discounts" />

      <SearchTable<DiscountDto>
        caption="Discounts"
        service={discountService}
        useWrapper={true}
        action={
          <>
            <div>
              <ExportToCsvModal discounts={discounts} />
            </div>
            <div className="vr" />
            <div>
              <ButtonLink variant="success" to="/discount/create">
                Create discount
              </ButtonLink>
            </div>
          </>
        }
        beforeTableRender={({ searchParams, setSearchParams }) => (
          <DiscountTableFilters
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            statistics={statistics}
          />
        )}
        numberPerPage={10}
        sortableColumns={["id", "code", "numberOfUses"]}
        showFilters={false}
        titles={{
          id: "#",
          code: "Title",
          status: "Status",
          numberOfUses: "Used",
        }}
        mappers={{
          name: (discount) => <>{discount.id}</>,
          code: (discount) => (
            <>
              <b>{discount.code}</b>
              <br />
              <span>
                {discount.type === "percent" ? (
                  <>{discount.value}% off</>
                ) : (
                  <>£{discount.value} off</>
                )}

                {!!discount.minimumAmount && (
                  <> - £{discount.minimumAmount} min spend</>
                )}

                {discount.appliesOncePerCustomer && (
                  <> - one use per customer</>
                )}
              </span>
            </>
          ),
          status: (discount) => (
            <>
              <Badge bg={discountStatusBadge(discount.status)}>
                {discount.status}
              </Badge>
            </>
          ),
          numberOfUses: (discount) => (
            <>
              {discount.numberOfUses ? <>{discount.numberOfUses}</> : <>0</>}

              {discount.maximumNumberOfUses && (
                <> / {discount.maximumNumberOfUses}</>
              )}
            </>
          ),
        }}
        onSelectRow={(discount) => navigate(`/discount/${discount.id}`)}
        noResults={discounts?.length ? undefined : <NoResultsDisplay />}
      />
    </Page>
  );
};

export default withAuthAndAuthRequired(DiscountIndexPage);
