import { Formik } from "formik";
import React from "react";
import { TabProps } from "./index";
import useAsyncRetry from "../../hooks/useAsyncRetry";
import checkoutService from "../../services/checkout/checkout.service";
import DynamicTable from "../../components/dynamicTable";
import { PricingPlanModel } from "../../services/pricingPlan/pricingPlan.model";
import { Button, Col, Container, Row } from "react-bootstrap";
import { FormFieldControl } from "../../components/formFieldControl";
import { FormikProps } from "formik/dist/types";
import usePrevious from "../../hooks/usePrevious";
import * as Yup from "yup";
import moment from "src/types/momentWithLocale";
import { isDateEqualSafe } from "../../utils/dateFnsExtra";

export interface CheckoutPaymentTabValues {
  pricingPlanId?: number;
  totalCost: number;
  paymentType: number;
  zeroCostConfirmed?: boolean;
}

interface CheckoutPaymentFormValues {
  pricingPlanId: number;
  totalCost: string;
  paymentType: number;
  pricingPlanName: string;
  _force?: boolean;
}

const PaymentTypes = {
  1: "Use Stripe to send email",
  2: "Manually Send Stripe Link to User",
  3: "Invoice directly outside of system",
};

const CheckoutPaymentFormSchema = Yup.object({
  totalCost: Yup.number()
    .required("A cost is required")
    .min(0, "Cost cannot be negative"),
  paymentType: Yup.number().test(
    "payment-type",
    "Payment type must be provided if cost is not zero",
    (value, context) => context.parent.totalCost === 0 || value !== -1
  ),
});

const CheckoutPaymentForm = ({ context, updateContext }: TabProps) => {
  const onSubmit = React.useCallback(
    (values: CheckoutPaymentFormValues) => {
      updateContext({
        ...context,
        paymentFormTabValues: {
          pricingPlanId:
            values.pricingPlanId === -1 ? undefined : values.pricingPlanId,
          totalCost: parseFloat(values.totalCost),
          zeroCostConfirmed: values._force,
          paymentType: parseInt(`${values.paymentType}`, 10),
        },
      });
    },
    [context, updateContext]
  );

  const { value: pricingPlans, isLoading } = useAsyncRetry(async () => {
    if (
      context.hubTabValues === undefined ||
      context.selectTimeTabValues === undefined
    )
      return undefined;

    return checkoutService.getPricingPlans(
      context.hubTabValues?.hub.id,
      context.selectTimeTabValues?.startDateTime!,
      context.selectTimeTabValues?.endDateTime!
    );
  }, [context.selectTimeTabValues]);

  const formikRef = React.createRef<FormikProps<CheckoutPaymentFormValues>>();

  const previousTimes = usePrevious<{
    startDateTime?: Date;
    endDateTime?: Date;
  }>(context.selectTimeTabValues || {});

  React.useEffect(() => {
    if (
      !isDateEqualSafe(
        previousTimes?.startDateTime,
        context.selectTimeTabValues?.startDateTime
      ) ||
      !isDateEqualSafe(
        previousTimes?.endDateTime,
        context.selectTimeTabValues?.endDateTime
      )
    ) {
      formikRef.current?.resetForm();
    }
  }, [
    context.selectTimeTabValues?.startDateTime,
    context.selectTimeTabValues?.endDateTime,
    previousTimes?.startDateTime,
    previousTimes?.endDateTime,
    formikRef,
  ]);

  const length = React.useMemo(() => {
    if (context.selectTimeTabValues === undefined) return undefined;

    return moment(context.selectTimeTabValues.endDateTime!).diff(
      context.selectTimeTabValues.startDateTime!,
      "minutes"
    );
  }, [context.selectTimeTabValues]);

  const numberOfSlotsCharged = React.useCallback(
    (pricingPlanModel: PricingPlanModel) => {
      return Math.max(
        1,
        Math.ceil(length! / pricingPlanModel.slotLengthInMinutes)
      );
    },
    [length]
  );

  const calculateTotalCostForRow = React.useCallback(
    (pricingPlanModel: PricingPlanModel) =>
      (
        numberOfSlotsCharged(pricingPlanModel) *
        pricingPlanModel.pricePerSlotLength
      ).toFixed(2),
    [numberOfSlotsCharged]
  );

  return (
    <Formik<CheckoutPaymentFormValues>
      onSubmit={onSubmit}
      initialValues={{
        totalCost: "0",
        pricingPlanName: "---",
        pricingPlanId: -1,
        paymentType: -1,
      }}
      validationSchema={CheckoutPaymentFormSchema}
      enableReinitialize={true}
      innerRef={formikRef}
    >
      {({ values, setFieldValue, submitForm, isValid }) => (
        <>
          {isLoading || pricingPlans === undefined ? (
            <h3>Loading pricing plans</h3>
          ) : pricingPlans!.length === 0 ? (
            <Container fluid className="m-0 p-0">
              <Row className="bg-white">
                <Col className="p-4">
                  <h4>
                    There are no pricing plans, for this hub, that match the
                    selected times
                  </h4>
                  <p>Please enter the payment information below as required</p>
                </Col>
              </Row>
            </Container>
          ) : (
            <>
              <Container fluid className="m-0 p-0">
                <Row className="bg-white">
                  <Col className="p-4 pb-0">
                    <h4>
                      The following pricing plan(s) are available for this hub
                    </h4>
                    <p>
                      You can either use an existing one and modify the values
                      it suggests, or directly enter payment information below
                    </p>
                  </Col>
                </Row>

                <Row className="bg-white">
                  <Col md="auto" style={{ minWidth: "670px" }}>
                    <DynamicTable<PricingPlanModel>
                      titles={{
                        displayName: "Plan name",
                        costPerSlotLength: "Cost",
                        numberOfSlotsCharged: "Number of slots",
                        total: "Total cost using this plan",
                      }}
                      mappers={{
                        costPerSlotLength: (row) => (
                          <>
                            £{row.pricePerSlotLength.toFixed(2)} per{" "}
                            {row.slotLengthInMinutes} min
                          </>
                        ),
                        numberOfSlotsCharged: (row) =>
                          numberOfSlotsCharged(row),
                        total: (row) => <>£{calculateTotalCostForRow(row)}</>,
                      }}
                      rows={pricingPlans}
                      onSelectRow={(row) => {
                        setFieldValue(
                          "totalCost",
                          calculateTotalCostForRow(row)
                        );
                        setFieldValue("pricingPlanId", row.id);
                        setFieldValue("pricingPlanName", row.displayName);
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </>
          )}

          <Container fluid className="m-0 p-0">
            <Row className="bg-white p-4">
              <Col md="auto">
                {pricingPlans && pricingPlans.length > 0 && (
                  <FormFieldControl
                    className="mb-4"
                    fieldName="pricingPlanName"
                    disabled
                    label="Selected Pricing Plan"
                  />
                )}
                <FormFieldControl
                  fieldName="totalCost"
                  type="number"
                  placeholder="Enter cost for booking"
                  label="Cost for booking (£)"
                  className="mb-4"
                  onChange={() => {
                    setFieldValue("_force", undefined);
                    setFieldValue("pricingPlanName", "---");
                    setFieldValue("pricingPlanId", undefined);
                  }}
                />
                <FormFieldControl
                  fieldName="paymentType"
                  label="Payment type to use"
                  type="select"
                >
                  <option value="-1">Select a payment type if required</option>
                  {Object.entries(PaymentTypes).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </FormFieldControl>
              </Col>
            </Row>
            <Row className="bg-white p-4">
              <Col>
                {parseInt(values.totalCost, 10) === 0 &&
                values._force !== true ? (
                  <Button
                    variant="success"
                    size="lg"
                    onClick={() => setFieldValue("_force", true)}
                  >
                    Confirm zero cost booking
                  </Button>
                ) : (
                  <Button
                    variant="success"
                    size="lg"
                    disabled={!isValid}
                    onClick={submitForm}
                  >
                    Next
                  </Button>
                )}
              </Col>
            </Row>
          </Container>
        </>
      )}
    </Formik>
  );
};

export default CheckoutPaymentForm;
