import React from "react";
import { Helmet } from "react-helmet-async";
import Page from "src/components/page";
import useAsyncRetry from "src/hooks/useAsyncRetry";
import hubAllocationService from "src/services/hubAllocation/hubAllocation.service";
import { withAuthAndAuthRequired } from "../../hooks/withAuthorisationRequired";

const HubAllocationsPage = () => {
  const { value: allocations } = useAsyncRetry(async () => {
    const result = await hubAllocationService.findAll();

    return result;
  });

  return (
    <>
      <Helmet title="Hub Allocations" />
      <Page>Allocations {allocations?.length}</Page>
    </>
  );
};

export default withAuthAndAuthRequired(HubAllocationsPage);
