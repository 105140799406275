import {
  BookingModel,
  BookingStatusText,
} from "../services/booking/booking.model";
import DateFormat, { DateFormats } from "./DateFormat";
import ButtonLink from "./ButtonLink";
import SearchTable, { SearchTableProps } from "./searchTable";
import React from "react";
import { SearchableService } from "../services/searchableService";
import bookingService from "../services/booking/booking.service";
import { Link } from "react-router-dom";
import { Badge } from "react-bootstrap";
import { bookingStatusBadge } from "src/pages/bookings/view";

type Props = Partial<SearchTableProps<BookingModel>> & {
  service?: SearchableService<BookingModel>;
};

const BookingsTable = ({ service = bookingService, ...props }: Props) => {
  return (
    <SearchTable<BookingModel>
      {...props}
      service={service}
      titles={{
        bookingId: "Id",
        bookedFor: "Booked for",
        // orderId: "Order Id",
        startDate: "Date",
        times: "Time booked",
        status: "Status",
        controls: "",
      }}
      defaultFilters={["past", "future", "current"]}
      mappers={{
        bookingId: ({ id }) => <Link to={`/booking/${id}`}>{id}</Link>,
        hub: ({ hub }) => <>{hub.name}</>,
        startDate: ({ startDateTime }) => (
          <DateFormat date={startDateTime} format={DateFormats.date} />
        ),
        times: ({ startDateTime, endDateTime }) => (
          <>
            <DateFormat date={startDateTime} format={DateFormats.time} />
            -
            <DateFormat date={endDateTime} format={DateFormats.time} />
          </>
        ),
        bookedFor: ({ order }) => <>{order?.account?.displayName}</>,
        status: ({ status }) => (
          <Badge bg={bookingStatusBadge(status)}>
            {BookingStatusText[status]}
          </Badge>
        ),
        controls: ({ id, order }) => (
          <ButtonLink
            variant="outline-secondary"
            size="sm"
            to={`/booking/${id}`}
          >
            View
          </ButtonLink>
        ),
      }}
    />
  );
};

export default BookingsTable;
