import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Optional } from "../../types/properties";
import InlineTextEdit from "../../components/inlineTextEdit";
import tryAsyncToast from "../../hooks/tryAsyncToast";
import useAsyncRetry from "../../hooks/useAsyncRetry";
import useToggle from "../../hooks/useToggle";
import { HubModel } from "../../services/hub/hub.model";
import hubService from "../../services/hub/hub.service";
import siteService from "../../services/site/site.service";
import { HubImages } from "./hubImages";
import { HubLocation } from "./hubLocation";

export const HubDetailsTab = ({
  hub,
  setValue,
}: {
  hub: Optional<HubModel, "id"> | HubModel;
  setValue: (value: (HubModel & Optional<HubModel, "id">) | undefined) => void;
}) => {
  const { value: allSites } = useAsyncRetry(
    async () => await siteService.findAll(),
    []
  );

  const updateHub = React.useCallback(
    (partialHub: Partial<HubModel>) => {
      if (partialHub === undefined) return;

      setValue({
        ...hub,
        ...partialHub,
      } as HubModel);
    },
    [setValue, hub]
  );

  const patchHub = React.useCallback(
    async (partialHub: Partial<HubModel>, success: string) => {
      if (hub.id !== undefined) {
        await tryAsyncToast(hubService.update(hub.id, partialHub), {
          success,
        });
      }

      updateHub(partialHub);
    },
    [hub, updateHub]
  );

  const [showIsActiveModal, toggleIsActiveModal] = useToggle(false);

  return (
    <Row className="tabContainer">
      <Col>
        <h2 className="h4 mt-3">Control hub activity</h2>
        <div className="d-flex mt-2">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="isActive"
              id="hubToggleOnline"
              value="online"
              checked={hub.isActive}
              onChange={toggleIsActiveModal.on}
            />
            <label className="form-check-label" htmlFor="hubToggleOnline">
              Hub online (is active)
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="isActive"
              id="hubToggleOffline"
              value="offline"
              checked={!hub.isActive}
              onChange={toggleIsActiveModal.on}
            />
            <label className="form-check-label" htmlFor="hubToggleOffline">
              Hub offline (inactive, unavailable for booking)
            </label>
          </div>
        </div>

        <Modal show={showIsActiveModal} onHide={toggleIsActiveModal.off}>
          <Modal.Header closeButton>
            <Modal.Title>
              {hub.name}:{" "}
              {hub.isActive
                ? "Take offline (inactive)"
                : "Make online (active)"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {hub.isActive
              ? `Please confirm that you wish to take this hub offline (inactive). 
              Doing so will mean that this hub will be immediately unavailable for bookings`
              : `Please confirm that you wish to make this hub online (active). 
              Doing so will mean that this hub will be immediately made available for bookings`}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={toggleIsActiveModal.off}>
              Close
            </Button>
            <Button
              variant="danger"
              onClick={async () => {
                await patchHub(
                  { isActive: !hub.isActive },
                  "Hub active status updated"
                );
                toggleIsActiveModal.off();
              }}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>

        <hr />

        <InlineTextEdit
          fieldTitle="Name"
          value={hub.name}
          onSubmit={(name) => patchHub({ name }, "Name updated")}
          required
          placeHolder="Name of Hub"
        />

        <InlineTextEdit
          fieldTitle="Location"
          value={`${hub.siteId}`}
          displayValue={hub.site?.name || "Not set"}
          inputType="select"
          onSubmit={async (_siteId) => {
            const siteId = parseInt(_siteId, 10);

            const site = allSites?.find(({ id }) => id === siteId);

            await patchHub(
              {
                siteId,
                site,
              },
              "Site updated"
            );
          }}
        >
          {allSites?.map((site) => (
            <option key={site.id} value={site.id}>
              {site.name}
            </option>
          ))}
        </InlineTextEdit>

        <InlineTextEdit
          fieldTitle="Overview"
          value={hub.overview}
          onSubmit={(overview) => patchHub({ overview }, "Overview updated")}
          required
          placeHolder="Short, quick, overview of Hub"
        />

        <InlineTextEdit
          fieldTitle="Description"
          value={hub.description}
          onSubmit={(description) =>
            patchHub({ description }, "Description updated")
          }
          required
          placeHolder="Detailed description for Hub"
          inputAs="textarea"
        />

        <InlineTextEdit
          fieldTitle="Amenities"
          value={hub.amenities || ""}
          placeHolder="Details of hub amenities"
          inputAs="textarea"
          onSubmit={(amenities) => patchHub({ amenities }, "Amenities updated")}
        />

        <div className="mt-5">
          <HubImages hub={hub} onSave={async (changes) => updateHub(changes)} />
        </div>
      </Col>
      <Col>
        <div className="mb-5">
          <HubLocation
            hub={hub}
            onSubmit={(location) => patchHub(location, "Location updated")}
          />
        </div>
        <InlineTextEdit
          fieldTitle="Location directions"
          value={hub.locationDirections || ""}
          placeHolder="Details on how to locate hub"
          inputAs="textarea"
          onSubmit={(locationDirections) =>
            patchHub({ locationDirections }, "Location directions updated")
          }
        />
      </Col>
    </Row>
  );
};
