import { HubAllocationDto } from "../hubAllocation/hubAllocation.dto";
import { OrderDto } from "../order/order.dto";
import { PricingPlanDto } from "../pricingPlan/pricingPlan.dto";
import { HubDto } from "../hub/hub.dto";

export enum BookingStatus {
  InProgress,
  Ordered,
  Cancelled,
  Completed,
  Incomplete,
}

export interface BookingDto {
  id: number;

  status: BookingStatus;

  costWhenOrdered: number;

  listCostWhenOrdered?: number;

  order: OrderDto;

  orderId: number;

  pricingPlan: PricingPlanDto;

  pricingPlanId: number;

  startDateTime: Date;

  endDateTime: Date;

  hubAllocation: HubAllocationDto;

  hubAllocationId: number;

  hub: HubDto;

  hubId: number;

  lockCode?: string | null;
}
