import { array, model, property } from "decoverto";
import { MembershipDto } from "src/services/membership/membership.dto";
import { MediaModel } from "../media/media.model";
import { MembershipAccount } from "../membershipAccount/membershipAccount.model";
import { PaginationResultDto } from "../searchableService";

@model()
export class MembershipModel implements MembershipDto {
  public constructor(init?: Partial<MembershipModel>) {
    Object.assign(this, init);
  }

  @property(() => Number)
  id!: number;

  @property(() => String)
  name!: string;

  @property(() => String)
  description?: string;

  @property(() => Number)
  cost!: number;

  @property(() => String)
  type!: string;

  @property(() => Number)
  value!: number;

  @property(() => String)
  benefits!: string;

  @property(() => String)
  stripeProductId?: string;

  @property(() => Number)
  mediaId?: number | null;

  @property(() => MediaModel)
  media?: MediaModel | null;

  @property(array(() => MembershipAccount))
  membershipAccounts?: MembershipAccount[];
  @property(() => Number)
  numberOfAccounts?: number;

  @property(() => Number)
  cancelledMembers?: number;

  @property(() => Number)
  revenue?: number;

  @property(() => Number)
  activeMembershipPlans?: number;

  @property(() => Boolean)
  isPublic?: boolean;

  @property(() => String)
  paymentUrl?: string;
}

@model()
export class MembershipModelSearchResults
  implements PaginationResultDto<MembershipModel>
{
  @property(array(() => MembershipModel))
  public rows!: MembershipModel[];

  @property(() => Number)
  public count!: number;
}
