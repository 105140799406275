import React from "react";
import { Breadcrumb, Stack } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Page from "src/components/page";
import { withAuthAndAuthRequired } from "src/hooks/withAuthorisationRequired";
import { MembershipModel } from "src/services/membership/membership.model";
import membershipService from "src/services/membership/membership.service";
import tryAsyncToast from "src/hooks/tryAsyncToast";
import { MembershipForm } from "src/pages/membership/membership.form";
import { Link, useNavigate } from "react-router-dom";

const MembershipCreatePage = () => {
  const nav = useNavigate();
  const handleSubmit = async (membership: MembershipModel) => {
    await tryAsyncToast(
      async () => {
        await membershipService.create(membership);

        nav("/membership");
      },
      {
        success: "Membership plan was created",
      }
    );
  };

  return (
    <Page>
      <Helmet title="Create Membership Plan" />
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/membership" }}>
          Membership
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Create</Breadcrumb.Item>
      </Breadcrumb>
      <Stack direction="horizontal" gap={3} className={"mb-3"}>
        <div>
          <h2>Create Membership Plan</h2>
        </div>
      </Stack>

      <MembershipForm onSubmit={handleSubmit} />
    </Page>
  );
};

export default withAuthAndAuthRequired(MembershipCreatePage);
