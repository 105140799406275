import { useAuth0 } from "@auth0/auth0-react";
import React, { ReactNode } from "react";
import LoadingPage from "../pages/loading.page";
import { authorizeApiClient } from "../services/apiClient";

const AxiosProvider = ({ children }: { children: ReactNode }) => {
  const auth0 = useAuth0();

  const [hasToken, setHasToken] = React.useState(false);

  auth0
    .getAccessTokenSilently()
    .then((result) => {
      authorizeApiClient(result);
      setHasToken(true);
    })
    .catch((notLoggedIn) => {
      setHasToken(true);
    });

  return <>{hasToken ? children : <LoadingPage />}</>;
};

export default AxiosProvider;
