export const partialRefresh = async function <T>(
  localValues: T | undefined,
  findFunction: () => Promise<T | undefined>,
  fields: (keyof T)[]
) {
  if (localValues === undefined) return localValues;

  const updatedValues = await findFunction();
  if (updatedValues === undefined) return localValues;

  const entries = Object.entries(updatedValues) as [keyof T, any][];
  const updatedPartialValues = Object.fromEntries(
    entries.filter(([key]) => fields.includes(key))
  ) as Partial<T>;

  return {
    ...localValues,
    ...updatedPartialValues,
  } as T;
};
