import React, { FunctionComponent } from "react";
import Page from "../../components/page";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import CheckoutAccountForm, { AccountFormTabValues } from "./account.form";
import CheckoutSelectTimeForm, {
  CheckoutTimeTabValues,
} from "./selectTime.form";
import CheckoutHubForm, { CheckoutHubTabValues } from "./hub.form";
import { useParams, useSearchParams } from "react-router-dom";
import useAsyncRetry from "../../hooks/useAsyncRetry";
import hubService from "../../services/hub/hub.service";
import CheckoutPaymentForm, { CheckoutPaymentTabValues } from "./payment.form";
import CheckoutConfirmation, {
  CheckoutConfirmationForm,
} from "./confirmation.form";
import accountService from "../../services/account/account.service";
import ResultForm from "./result.form";
import { Helmet } from "react-helmet-async";
import { withAuthAndAuthRequired } from "../../hooks/withAuthorisationRequired";

type TabType = {
  title: string;
  component: FunctionComponent<TabProps>;
  stateDepends: (keyof CheckoutPageDetails)[];
};

const TabDefinitions: { [k: string]: TabType } = {
  account: {
    title: "Select Account",
    component: CheckoutAccountForm,
    stateDepends: [],
  },
  hub: {
    title: "Select Hub",
    component: CheckoutHubForm,
    stateDepends: ["accountTabValues"],
  },
  selectTime: {
    title: "Select Time",
    component: CheckoutSelectTimeForm,
    stateDepends: ["accountTabValues", "hubTabValues"],
  },
  payment: {
    title: "Payment",
    component: CheckoutPaymentForm,
    stateDepends: ["selectTimeTabValues"],
  },
  confirmation: {
    title: "Confirmation",
    component: CheckoutConfirmation,
    stateDepends: ["paymentFormTabValues"],
  },
  result: {
    title: "Result",
    component: ResultForm,
    stateDepends: ["confirmationForm"],
  },
};

export interface TabProps {
  context: CheckoutPageDetails;
  updateContext: (context: CheckoutPageDetails) => void;
}

export interface CheckoutPageDetails {
  accountTabValues?: AccountFormTabValues;
  hubTabValues?: CheckoutHubTabValues;
  selectTimeTabValues?: CheckoutTimeTabValues;
  paymentFormTabValues?: CheckoutPaymentTabValues;
  confirmationForm?: CheckoutConfirmationForm;
}

const initialState = {
  hubTabValues: undefined,
  accountTabValues: undefined,
};

const CheckoutPage = () => {
  const { hubId: hubIdPathParam, accountId: accountIdPathParam } = useParams();
  const [searchParams] = useSearchParams();
  const [hubIdSearchParam, accountIdSearchParam] = React.useMemo(
    () => [searchParams.get("hubId"), searchParams.get("accountId")],
    [searchParams]
  );

  const hubId = React.useMemo(() => {
    if (hubIdPathParam) return hubIdPathParam;
    if (hubIdSearchParam) return hubIdSearchParam;
    return undefined;
  }, [hubIdPathParam, hubIdSearchParam]);

  const accountId = React.useMemo(() => {
    if (accountIdPathParam) return accountIdPathParam;
    if (accountIdSearchParam) return accountIdSearchParam;
  }, [accountIdPathParam, accountIdSearchParam]);

  const [state, setState] = React.useState<CheckoutPageDetails>(initialState);

  const [currentPage, setCurrentPage] = React.useState<string>();
  const stateRef = React.useRef(state);

  const { value: hub, isLoading: hubLoading } = useAsyncRetry(async () => {
    if (hubId) {
      const id = parseInt(hubId, 10);
      if (!isNaN(id)) {
        return await hubService.findById(id, "site");
      }
    }
    return undefined;
  }, [hubId]);

  const { value: account, isLoading: accountLoading } =
    useAsyncRetry(async () => {
      if (accountId) {
        const id = parseInt(accountId, 10);
        if (!isNaN(id)) {
          return (await accountService.findById(id)).data;
        }
      }
    }, [accountId]);

  // allows us to override the hub and account if they are set in the url/params
  React.useEffect(() => {
    setState({
      ...stateRef.current,
      ...(account ? { accountTabValues: { account } } : {}),
      ...(hub ? { hubTabValues: { hub } } : {}),
    });
  }, [hub, account, setState]);

  const visibleTabs = React.useMemo(() => {
    return [
      account ? undefined : "account",
      hub ? undefined : "hub",
      "selectTime",
      "payment",
      "confirmation",
      "result",
    ].filter(Boolean) as string[];
  }, [account, hub]);

  const getNextPage = React.useCallback(
    (currentPage?: string): string | undefined => {
      const currentIdx =
        currentPage === undefined ? -1 : visibleTabs.indexOf(currentPage);

      const nextIdx = currentIdx + 1;

      return visibleTabs[nextIdx];
    },
    [visibleTabs]
  );

  // set the initial page otherwise the code thinks it's undefined and the Tabs and code get out-of-sync
  React.useEffect(() => {
    setCurrentPage(getNextPage(undefined));
  }, [getNextPage]);

  const title = React.useMemo(() => {
    if (hub !== undefined && account !== undefined) {
      return (
        <>
          Creating a booking for <strong>{account.displayName}</strong> in{" "}
          <strong>{hub.name}</strong>
        </>
      );
    } else if (hub) {
      return (
        <>
          Creating a new booking in <strong>{hub.name}</strong>
        </>
      );
    } else if (account) {
      return (
        <>
          Create a new booking for user <strong>{account.displayName}</strong>
        </>
      );
    }

    return "Creating a new booking";
  }, [account, hub]);

  const isTabDisabled = React.useCallback(
    (keys?: (keyof CheckoutPageDetails)[]) => {
      if (keys === undefined || keys.length === 0) return false;

      let disabled = false;

      for (let i = 0; i < keys.length && !disabled; ++i) {
        disabled = disabled || state[keys[i]] === undefined;
      }

      return disabled;
    },
    [state]
  );

  return hubLoading || accountLoading ? (
    <> </>
  ) : (
    <Page title={title}>
      <Helmet title={`Checkout - ${hub?.name}`} />
      <Container fluid>
        <Row>
          <Col>
            <Tabs
              className="mt-5"
              mountOnEnter
              defaultActiveKey={visibleTabs[0]}
              activeKey={currentPage}
              onSelect={(eventKey) => setCurrentPage(eventKey as string)}
            >
              {visibleTabs
                .map((tabName) => ({ tabName, ...TabDefinitions[tabName] }))
                .map(
                  ({
                    tabName: eventKey,
                    component: TabContent,
                    title,
                    stateDepends,
                  }) => (
                    <Tab
                      title={title}
                      eventKey={eventKey}
                      disabled={
                        (state.confirmationForm && eventKey !== "result") ||
                        isTabDisabled(stateDepends)
                      }
                      key={eventKey}
                    >
                      <TabContent
                        context={state}
                        updateContext={(newState) => {
                          setState(newState);
                          setCurrentPage(getNextPage(currentPage));
                        }}
                      />
                    </Tab>
                  )
                )}
            </Tabs>
          </Col>
        </Row>
      </Container>
    </Page>
  );
};

export default withAuthAndAuthRequired(CheckoutPage);
