import { BaseService } from "src/services/baseService";
import { HubAvailabilityModel } from "src/services/hubAvailability/hubAvailability.model";

class HubAvailabilityService extends BaseService<HubAvailabilityModel> {
  constructor() {
    super(HubAvailabilityModel, "/hubAvailability");
  }

  async findAll(): Promise<HubAvailabilityModel[]> {
    return (await this._getArray("/")).data;
  }

  async findAllWithPricingPlans(): Promise<HubAvailabilityModel[]> {
    return (await this._getArray("/?relations=pricingPlans")).data;
  }

  async create(
    availability: HubAvailabilityModel
  ): Promise<HubAvailabilityModel> {
    return (await this._post("", availability)).data;
  }

  async update(
    id: number,
    availability: HubAvailabilityModel
  ): Promise<HubAvailabilityModel> {
    return (await this._patch(`/${id}`, availability)).data;
  }

  async remove(id: number): Promise<HubAvailabilityModel> {
    return (await this._delete(`/${id}`)).data;
  }
}

const hubAvailabilityService = new HubAvailabilityService();

export default hubAvailabilityService;
