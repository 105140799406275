import { AccountDto } from "src/services/account/account.dto";
import { model, property } from "decoverto";
import { AccountModel } from "../account/account.model";

@model()
export default class UserModel {
  @property(() => AccountModel)
  account?: AccountDto;

  @property(() => String)
  authentication!: string;

  @property(() => String)
  email!: string;

  @property(() => Number)
  id!: number;

  @property(() => String)
  name!: string;

  @property(() => Date)
  lastLogin!: Date;
}
